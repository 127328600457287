import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IPassenger } from 'src/types/passanger.type';
import { ITravelSchedule } from 'src/types/travel.type';
import {
  IAddTripWaybillParams,
  INewHandoverParams,
  INewTripEventParams,
  IRemoveWaybillData,
  ITransferPassengerParams,
  ITrip,
  ITripEvent,
  ITripEventsSearchQuery,
  ITripEventType,
  ITripHandOver,
  ITripSeatMap,
} from 'src/types/trip.type';
import { IWaybill } from 'src/types/waybill.type';

export class TripService {
  public static async findTrip(id: number): Promise<ITrip> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getSchedules(id: number): Promise<ITravelSchedule[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/schedules`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getPassengers(id: number): Promise<IPassenger[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/passengers`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getTripEventTypes(): Promise<ITripEventType[]> {
    const { data } = await api.get(`${resources.trip.getTripEventTypesUrl}`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getTripEvents(id: number): Promise<ITripEvent[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/events`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getEvents(params: ITripEventsSearchQuery): Promise<ITripEvent[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/events`, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async getTripWaybills(id: number): Promise<IWaybill[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/waybills`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async createTripEvent(id: number, params: INewTripEventParams): Promise<ITripEvent> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/events`, params);
    return camelcaseKeys(data, { deep: true });
  }

  public static async addTripHandOvers(id: number, params: INewHandoverParams[]): Promise<ITripHandOver[]> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/handovers`, params);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getTripHandOvers(id: number): Promise<ITripHandOver[]> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/handovers`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async addTripWaybills(id: number, params: IAddTripWaybillParams): Promise<IWaybill[]> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/waybills`, params);
    return camelcaseKeys(data, { deep: true });
  }

  public static async getTripSeatmap(id: number): Promise<ITripSeatMap> {
    const { data } = await api.get(`${resources.trip.getTripsUrl}/${id}/seat-map`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async closeTrip(id: number): Promise<ITrip> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/close`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async transferPassenger(id: number, params: ITransferPassengerParams): Promise<IPassenger> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/transfer`, params);
    return camelcaseKeys(data, { deep: true });
  }

  public static async removeWaybill(id: number, params: IRemoveWaybillData): Promise<IWaybill> {
    const { data } = await api.post(`${resources.trip.getTripsUrl}/${id}/remove-waybill`, {
      ...params,
      noTransform: true,
    });
    return camelcaseKeys(data, { deep: true });
  }
}
