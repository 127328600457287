import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { Result } from 'antd';

import { BaseRoutes } from 'src/routes';
import { createApolloClient } from 'src/graphql/client';
import { MessageProvider } from 'src/providers/message-provider/MessageProvider';
import { PermifyContextProvider } from 'src/providers/auth-provider/PermifyProvider';

Sentry.init({
  dsn: 'https://bcce97c6576638144979e90a99b73f19@o269765.ingest.sentry.io/4506264343937024',
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

const ErrorFallbackComponent: React.FC = () => (
  <Result status="500" title="500" subTitle="Sorry, something went wrong." />
);

export const App = () => {
  const fallbackScreen = <ErrorFallbackComponent />;

  const client = createApolloClient();

  return (
    <Sentry.ErrorBoundary fallback={fallbackScreen} showDialog>
      <ApolloProvider client={client}>
        <PermifyContextProvider>
          <MessageProvider>
            <BrowserRouter>
              <BaseRoutes />
            </BrowserRouter>
          </MessageProvider>
        </PermifyContextProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};
