import { Card, Empty } from 'antd';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { UseGetTripsQueryParams, useGetTrips } from 'src/hooks/useGetTrips';
import { CalendarIcon } from 'src/icons';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import 'src/screens/dashboard/styles/dashboard.styles.less';
// import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';

export const UPCOMING_TRIPS_CARD_TEST_ID = 'UPCOMING_TRIPS_CARD_TEST_ID';

interface UpcomingTripsCardProps {
  icon?: ReactNode;
  schedule?: string;
  route?: string;
}

export const Item = (props: UpcomingTripsCardProps) => {
  const { icon, schedule, route } = props;
  return (
    <div className="tripscard-item">
      <div className="tripscard-item-left">
        <p>{route}</p>
        <span>{schedule}</span>
      </div>
      <div className="tripscard-item-right">{icon}</div>
    </div>
  );
};

export const UpcomingTripsCard = () => {
  const { activeBranch } = useBranch();

  const [query] = useState<UseGetTripsQueryParams>({
    branchId: activeBranch?.id,
    offset: 4,
    status: 'open_trips',
    startDate: moment().toDate().toDateString(),
  });
  const [{ data, loading }] = useGetTrips({ params: query });

  return (
    <Card data-testid={UPCOMING_TRIPS_CARD_TEST_ID} className="waybill-card" loading={loading}>
      <div className="title">
        <p>Upcoming Trips</p>
      </div>
      {data && data?.items?.length < 1 && <Empty />}
      {data?.items.map((trip) => (
        <Item
          key={trip.id}
          icon={<CalendarIcon />}
          schedule={moment(`${trip.date} ${trip.time}`).format('ddd DD MMM YYYY - hh:30 a')}
          route={`${trip.branch?.name} → ${trip.destination?.name}`}
        />
      ))}
    </Card>
  );
};
