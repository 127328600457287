import { Button, Modal, Form, Row, Col, Alert, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { RoutesService } from 'src/services/routes.service';

import 'src/screens/routes-management/styles/routesManagement.styles.less';
import { IRoute, NewRoutePayload } from 'src/types/route.type';
import { IBranch, IDestination } from 'src/types/branch.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { onDestinationFilter } from 'src/utilities/helpers.utils';
import { UseUpdateRouteParams, useUpdateRoute } from 'src/hooks/useUpdateRoute';

const { Option } = Select;

export interface AddRoutesProps {
  show: boolean;
  onClose: () => void;
  onRouteCreated: (route: IRoute) => void;
  defaultRoute?: IRoute;
}

export const RouteModal: React.FC<AddRoutesProps> = ({ show, onClose, onRouteCreated, defaultRoute }) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();

  const [departure, setDeparture] = useState<IBranch[]>([]);
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [updateRoute] = useUpdateRoute();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [from, to] = await Promise.all([RoutesService.getBranches(), RoutesService.getDestinations()]);
      setDeparture(from);
      setDestinations(to);
      setLoading(false);
    };

    getData();
  }, []);

  const showError = (message: string) => {
    setError(message);
    setTimeout(() => {
      setError('');
    }, 3000);
  };

  const handleSave = async (values: NewRoutePayload | UseUpdateRouteParams) => {
    setLoading(true);
    let updatedRoute: IRoute | null | undefined;
    if (defaultRoute) {
      const payload: UseUpdateRouteParams = { ...values, routeId: defaultRoute.id };
      try {
        updatedRoute = await updateRoute({ params: { ...payload } });
        onRouteCreated(updatedRoute!);
      } catch (newError) {
        showError('An error occured in trying to update route!');
      }
      setLoading(false);

      return;
    }

    const payload: NewRoutePayload = { ...values };

    try {
      const newRoute = await RoutesService.addNewRoute(payload);
      onRouteCreated(newRoute);
      form.resetFields();
    } catch (e) {
      showError('Wrong parameters entered!');
    }
    setLoading(false);
  };

  const onFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      handleSave(values);
    } catch (e) {
      showError('Invalid data entered');
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const onDestinationSearch = (input: string, option?: { value: number }) => {
    return onDestinationFilter(destinations, input, option);
  };

  return (
    <Modal
      title={defaultRoute ? 'Edit Route' : 'New Route'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={onFormSubmit}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form autoComplete="on" form={form} initialValues={{ ...defaultRoute, branchId: activeBranch?.id }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="branchId" rules={[{ required: true, message: 'Enter departure location' }]}>
              <Select showSearch placeholder="Departure" disabled>
                {departure.map((branch: IBranch) => {
                  return (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="destinationId" rules={[{ required: true, message: 'Please select destination state' }]}>
              <Select showSearch placeholder="Destination" filterOption={onDestinationSearch}>
                {destinations.map((destination: IDestination) => {
                  return (
                    <Option value={destination.id} key={destination.id}>
                      {destination.name} ({destination.code})
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="status" rules={[{ required: true, message: 'please enter route status' }]}>
              <Select placeholder="Status">
                <Option value="active"> Active </Option>
                <Option value="inactive"> Not active </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} />
        </Row>
      </Form>
    </Modal>
  );
};
