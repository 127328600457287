/* eslint-disable max-len */
import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const BankSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons viewBoxHeight="20" viewBoxWidth="16" height="20" width="16">
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#E4F6FA'} />
      <path
        fill={pathFill ?? '#55BCD9'}
        d="M36.191 24.036c.906 0 1.283-1.163.548-1.7l-11.188-8.138a.932.932 0 00-1.102 0l-11.188 8.138c-.736.534-.358 1.7.55 1.7h1.814v10.957h-2.11a.235.235 0 00-.234.234v1.524c0 .128.106.234.235.234h22.968a.235.235 0 00.235-.234v-1.524a.235.235 0 00-.235-.234h-2.11V24.036h1.817zM21.162 34.993h-3.428V24.036h3.428v10.957zm5.537 0h-3.428V24.036H26.7v10.957zm5.567 0h-3.457V24.036h3.457v10.957z"
      />
    </BaseSVGIcons>
  );
};
