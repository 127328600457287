import { IVehicleClass } from 'src/types/vehicle.type';
import { api } from 'src/services/api';
import { useGet } from 'src/hooks/shared/useGet';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';

export const useGetVehicleClasses = () =>
  useGet<IVehicleClass[]>(async () => {
    const { data } = await api.get<IVehicleClass[]>(resources.vehicleClassUrl);
    return camelcaseKeys(data, { deep: true });
  });
