import camelcaseKeys from 'camelcase-keys';
import { UseGetWaybillParams } from 'src/hooks/useGetWaybills';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IWaybill } from 'src/types/waybill.type';

export class WaybillService {
  public static async createWaybill(waybill: IWaybill): Promise<IWaybill> {
    const { data } = await api.post<IWaybill>(resources.waybillUrl, waybill);
    return camelcaseKeys<IWaybill>(data, { deep: true });
  }

  public static async getWaybills(params: UseGetWaybillParams): Promise<IWaybill[]> {
    const { data } = await api.get(resources.waybillUrl, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async getWaybillById(id: number): Promise<IWaybill> {
    const { data } = await api.get<IWaybill>(`${resources.waybillUrl}/${id}`);
    return camelcaseKeys<IWaybill>(data, { deep: true });
  }
}
