import { Button, Modal, Form, Input, Row, Col, Alert, Select } from 'antd';
import React, { useEffect } from 'react';

import 'src/screens/routes-fare/route-fares-modal/styles/routeFaresModal.style.less';
import { IRoute } from 'src/types/route.type';
import { useGetRoutes } from 'src/hooks/useGetRoutes';
import { RouteFareOverride } from 'src/graphql/queries/find-route-fare-overrides';
import { useCreateRouteFareOverrideMutation } from 'src/graphql/mutations/create-route-fare-override';
import { useUpdateRouteFareOverrideMutation } from 'src/graphql/mutations/update-route-fare-override';
// eslint-disable-next-line max-len
import { UpdateRouteFareOverrideMutationVariables } from 'src/graphql/mutations/__generated__/update-route-fare-override.generated';
import { useGetVehicleClasses } from 'src/hooks/useGetVehicleClasses';

const { Option } = Select;

export interface RouteFaresOverrideProps {
  show: boolean;
  onClose: () => void;
  onRouteFareChange: () => void;
  defaultRouteFare?: RouteFareOverride;
}

export const RouteFareOverrideModal: React.FC<RouteFaresOverrideProps> = ({
  show,
  onClose,
  onRouteFareChange,
  defaultRouteFare,
}) => {
  const [{ data: routes }] = useGetRoutes();
  const [{ data: vehicleClasses }] = useGetVehicleClasses();
  const [createRouteFare, { loading: createRouteFareLoading, error: createError }] =
    useCreateRouteFareOverrideMutation();
  const [updateRouteFare, { loading: updateRouteFareLoading, error: updateError }] =
    useUpdateRouteFareOverrideMutation();
  const [form] = Form.useForm();

  const loading = createRouteFareLoading || updateRouteFareLoading;
  const error = createError || updateError;

  useEffect(() => {
    if (defaultRouteFare && vehicleClasses && routes) {
      form.setFieldsValue({
        routeId: Number(defaultRouteFare.route.id),
        fare: defaultRouteFare.fare,
        currency: defaultRouteFare.currency,
        vehicleClassId: Number(defaultRouteFare.vehicleClass.id),
      });
    }
  }, [defaultRouteFare, vehicleClasses, routes]);
  const handleSave = async ({
    routeId,
    fare,
    currency,
    vehicleClassId,
  }: {
    routeId: string;
    fare: string;
    currency: string;
    vehicleClassId: string;
  }) => {
    let result;
    if (defaultRouteFare) {
      const variables: UpdateRouteFareOverrideMutationVariables = {
        fareOverrideId: defaultRouteFare.id,
        input: { vehicleClassId: String(vehicleClassId), fare: Number(fare), routeId: String(routeId), currency },
      };
      result = await updateRouteFare({ variables });
    } else {
      result = await createRouteFare({
        variables: {
          input: { vehicleClassId: String(vehicleClassId), fare: Number(fare), routeId: String(routeId), currency },
        },
      });
    }

    if (result.data && onRouteFareChange) {
      onRouteFareChange();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const routeOptions = routes?.map((route: IRoute) => (
    <Option key={route.id} value={route.id}>
      {`${route.branch?.name}  → ${route.destination?.name}`}
    </Option>
  ));

  const vehicleClassesItem = (
    <Form.Item name="vehicleClassId" rules={[{ required: true, message: 'Please select a vehicle class' }]}>
      <Select showSearch placeholder="Select Vehicle Class">
        {vehicleClasses?.map((vc) => (
          <Option key={vc.id} value={vc.id}>
            {vc.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      title={defaultRouteFare ? 'Edit Route Fare' : 'Add Route Fare'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error.message} type="error" className="error-alert" />}

      <Form autoComplete="on" form={form} onFinish={handleSave}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="routeId" rules={[{ required: true, message: 'Please select a route' }]}>
              <Select showSearch placeholder="Choose a route">
                {routeOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>{vehicleClassesItem}</Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="currency" rules={[{ required: true, message: 'please select currency' }]}>
              <Select showSearch placeholder="Currency">
                <Option value="NGN">NGN</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="fare" rules={[{ required: true, message: 'Enter fare' }]}>
              <Input placeholder="Amount" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
