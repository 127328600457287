import { gql } from '@apollo/client';

export const VEHICLE_CLASS_CONFIG_FRAGMENT = gql`
  fragment VehicleClassConfigFragment on VehicleClassConfig {
    id
    charge
    vehicleClass {
      id
      name
    }
  }
`;
