import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { api } from 'src/services/api';
import { IRoute, NewRoutePayload } from 'src/types/route.type';

export interface UseUpdateRouteParams extends NewRoutePayload {
  routeId: number;
}

export const useUpdateRoute = () =>
  useUpdate<IRoute, UseUpdateRouteParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.patch<IRoute>(`${resources.routesUrl}/${options?.params?.routeId}`, payload);
    return data;
  });
