import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Col, Row, Statistic } from 'antd';

import { IExpense, IExpenseSummary } from 'src/types/expense.type';
import { PaginatedResponse } from 'src/services/api';

import { FlexBox } from 'src/components/flex-box/FlexBox';
import { ExpenseSVGIcon, RevenueSVGIcon, TripSVGIcon } from 'src/icons';

interface ExpenseSummaryCardProps {
  title: string;
  figure: number;
  icon?: ReactNode;
}

const ExpenseSummaryCard: React.FC<ExpenseSummaryCardProps> = ({ title, figure, icon }) => {
  return (
    <Card>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Statistic title={title} value={figure} precision={2} prefix="₦" />
        {icon}
      </FlexBox>
    </Card>
  );
};

export const ExpenseSummary: React.FC<{ expenses: PaginatedResponse<IExpense> }> = ({ expenses }) => {
  const [data, setData] = useState<IExpenseSummary[]>([]);

  useEffect(() => {
    const expenseData = expenses.items.reduce(
      (previousValue: IExpenseSummary, currentValue: IExpense) => {
        if (currentValue.trip) {
          return {
            ...previousValue,
            totalTrips: previousValue.totalTrips + currentValue.amount,
            totalExpenses: previousValue.totalExpenses + currentValue.amount,
          };
        }

        if (currentValue.trip === null) {
          return {
            ...previousValue,
            totalMiscellaneous: previousValue.totalMiscellaneous + currentValue.amount,
            totalExpenses: previousValue.totalExpenses + currentValue.amount,
          };
        }

        return previousValue;
      },
      {
        totalTrips: 0,
        totalMiscellaneous: 0,
        totalExpenses: 0,
      },
    );
    setData([expenseData]);
  }, [expenses]);

  return (
    <Card bordered={false}>
      <Row gutter={[16, 16]}>
        {data.map((summaryInstance: IExpenseSummary) => (
          <>
            <Col span={8}>
              <ExpenseSummaryCard
                title="Trip Exenses"
                figure={summaryInstance?.totalTrips ?? 0}
                icon={<TripSVGIcon />}
              />
            </Col>
            <Col span={8}>
              <ExpenseSummaryCard
                title="Miscellaneous Exenses"
                figure={summaryInstance?.totalMiscellaneous ?? 0}
                icon={<RevenueSVGIcon />}
              />
            </Col>
            <Col span={8}>
              <ExpenseSummaryCard
                title="Total Exenses"
                figure={summaryInstance?.totalExpenses ?? 0}
                icon={<ExpenseSVGIcon />}
              />
            </Col>
          </>
        ))}
      </Row>
    </Card>
  );
};
