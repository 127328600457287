import { Button, Col, Collapse, DatePicker, Form, Row, Select, Space } from 'antd';
import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import moment, { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { IExpenseSearchQuery } from 'src/types/expense.type';
import { useGetFilterExpenseType } from 'src/hooks/useGetFilterExpenseType';
import { useGetFilterPaymentMethod } from 'src/hooks/useGetFilterPaymentMethod';

export interface ScheduleFilterprops {
  onApply?: (filter: IExpenseSearchQuery) => void;
}
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const panelStyle = {
  marginBottom: 24,
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 'none',
};

const formSpan = 5;

export const ExpenseFilter = ({ onApply }: ScheduleFilterprops) => {
  const { activeBranch } = useBranch();
  const [{ data: expenseTypeData = [], loading: expenseTypeLoading }] = useGetFilterExpenseType();
  const [{ data: paymentMethodData = [], loading: paymentMethodLoading }] = useGetFilterPaymentMethod();

  const [form] = Form.useForm();

  const disabledDate = (current: Moment) => {
    // Disable dates after today
    if (current && current > moment().endOf('day')) {
      return true;
    }

    // Disable dates before 5 years ago
    return current && current < moment().subtract(5, 'years');
  };

  const onApplyFilter = async () => {
    const { dateRange, paymentMethod, expenseType, ...values } = await form.validateFields();

    const filter: IExpenseSearchQuery = { branchId: activeBranch?.id!, page: 1, offset: 100, ...values };

    if (dateRange) {
      filter.startDate = (dateRange[0] as Moment).format(DEFAULT_DATE_FORMAT);
      filter.endDate = (dateRange[1] as Moment).format(DEFAULT_DATE_FORMAT);
    }

    if (expenseType) {
      filter.expenseType = expenseType;
    }

    if (paymentMethod) {
      filter.paymentMethod = paymentMethod;
    }

    if (onApply) {
      onApply(filter);
    }
  };

  const onClear = () => {
    form.resetFields();

    if (onApply) {
      onApply({
        branchId: activeBranch?.id!,
        page: 1,
        offset: 100,
      });
    }
  };

  const caret = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={caret}
      style={{ background: 'rgba(0, 0, 0, 0.02)' }}
    >
      <Panel header="Filter" key="1" style={panelStyle}>
        <Form form={form} layout="vertical">
          <Row align="middle" gutter={[16, 16]}>
            <Col span={formSpan}>
              <Form.Item name="expenseType" label="Expense Type">
                <Select showSearch placeholder="Expense Type" loading={expenseTypeLoading}>
                  {expenseTypeData.map((expense: string) => {
                    return (
                      <Option key={expense} value={expense}>
                        {expense}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="paymentMethod" label="Payment Method">
                <Select showSearch placeholder="Payment Method" loading={paymentMethodLoading}>
                  {paymentMethodData.map((paymentMethod: string) => {
                    return (
                      <Option key={paymentMethod} value={paymentMethod}>
                        {paymentMethod}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="dateRange" label="Date Range">
                <RangePicker disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item label=" ">
                <Space split="|">
                  <Button type="primary" icon={<FilterFilled />} onClick={onApplyFilter}>
                    Apply Filters
                  </Button>
                  <Button onClick={onClear}>Clear filters</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};
