import { MutationTuple, gql } from '@apollo/client';
import { PRICE_BOOKING_FRAGMENT } from 'src/graphql/fragments/price-booking.fragment';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  PriceBookingMutation,
  PriceBookingMutationVariables,
} from 'src/graphql/mutations/__generated__/price-booking.generated';

export type PriceBooking = PriceBookingMutation['priceBooking'];

const PRICE_BOOKING_MUTATION = gql`
  mutation PriceBooking($input: PriceBookingInput!) {
    priceBooking(input: $input) {
      departure {
        ...PriceBookingFragment
      }
      arrival {
        ...PriceBookingFragment
      }
    }
  }
  ${PRICE_BOOKING_FRAGMENT}
`;

export const usePriceBookingnMutation = (): MutationTuple<PriceBookingMutation, PriceBookingMutationVariables> =>
  useMutationErrorNotification<PriceBookingMutation, PriceBookingMutationVariables>(PRICE_BOOKING_MUTATION);
