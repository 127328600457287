import { Spin } from 'antd';

import 'src/components/loading-view/styles/loadingViewStyles.less';

export const LoadingView = () => {
  return (
    <div className="loading-view">
      <Spin />
    </div>
  );
};
