import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { ITripConfiguration } from 'src/types/trip.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';

export interface ICreateTripConfigParams {
  routeGroupId: number;
  time: string;
  vehicleClassId: number;
  days: string[];
  branchId: number;
  seatMapId: number;
}

export const usePostCreateTripConfig = () =>
  useUpdate<ITripConfiguration, ICreateTripConfigParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<ITripConfiguration>(resources.tripConfigurationUrl, payload);
    return camelcaseKeys(data, { deep: true });
  });
