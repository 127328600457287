import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CreateVehicleClassConfigMutation,
  CreateVehicleClassConfigMutationVariables,
} from 'src/graphql/mutations/__generated__/create-vehicle-class-config.generated';
import { VEHICLE_CLASS_CONFIG_FRAGMENT } from 'src/graphql/fragments/vehicle-class-config.fragment';

const CREATE_VEHICLE_CLASS_CONFIG_QUERY = gql`
  mutation CreateVehicleClassConfig($charge: Float!, $vehicleClassId: ID!) {
    createVehicleClassConfig(charge: $charge, vehicleClassId: $vehicleClassId) {
      ...VehicleClassConfigFragment
    }
  }

  ${VEHICLE_CLASS_CONFIG_FRAGMENT}
`;

export const useCreateVehicleClassConfig = (): MutationTuple<
  CreateVehicleClassConfigMutation,
  CreateVehicleClassConfigMutationVariables
> =>
  useMutationErrorNotification<CreateVehicleClassConfigMutation, CreateVehicleClassConfigMutationVariables>(
    CREATE_VEHICLE_CLASS_CONFIG_QUERY,
  );
