import { useCallback, useEffect, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { ITravelSchedule } from 'src/types/travel.type';

export const useGetTripSchedules = (
  tripId: number,
): [{ data: ITravelSchedule[] | undefined; loading: boolean; error: any }, (id: number) => Promise<void>] => {
  const [data, setData] = useState<ITravelSchedule[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        const schedules = await TripService.getSchedules(id);
        setData(schedules);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError, tripId],
  );

  useEffect(() => {
    const getTripSchedules = async () => {
      fetch(tripId);
    };
    getTripSchedules();
  }, [tripId, fetch]);

  return [{ data, loading, error }, fetch];
};
