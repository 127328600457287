import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { ROUTE_FARE_OVERRIDE_FRAGMENT } from 'src/graphql/fragments/route-fare-override.fragment';
import {
  FindRouteFareOverridesQuery,
  FindRouteFareOverridesQueryVariables,
} from 'src/graphql/queries/__generated__/find-route-fare-overrides.generated';

export type RouteFareOverride = FindRouteFareOverridesQuery['findRouteFareOverrides'][number];

const FIND_ROUTE_FARE_OVERRIDE_QUERY = gql`
  query FindRouteFareOverrides {
    findRouteFareOverrides {
      ...RouteFareOverrideFragment
    }
  }
  ${ROUTE_FARE_OVERRIDE_FRAGMENT}
`;

export const useFindRouteFareOverrideQuery = (): QueryResult<
  FindRouteFareOverridesQuery,
  FindRouteFareOverridesQueryVariables
> =>
  useQueryErrorNotification<FindRouteFareOverridesQuery, FindRouteFareOverridesQueryVariables>(
    FIND_ROUTE_FARE_OVERRIDE_QUERY,
  );
