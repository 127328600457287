import { useCallback, useEffect, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { IPassenger } from 'src/types/passanger.type';

export const useGetTripPassengers = (
  id: number,
): [{ data: IPassenger[] | undefined; loading: boolean; error: any }, (id: number) => Promise<void>] => {
  const [data, setData] = useState<IPassenger[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (tripId: number) => {
      setLoading(true);
      try {
        const result = await TripService.getPassengers(tripId);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError],
  );

  useEffect(() => {
    const getTripPassengers = async () => {
      fetch(id);
    };
    getTripPassengers();
  }, [fetch, id]);

  return [{ data, loading, error }, fetch];
};
