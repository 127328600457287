import camelcaseKeys from 'camelcase-keys';

import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IVehicleClass } from 'src/types/vehicle.type';
import { ICreateVehicleClassParams } from 'src/hooks/useCreateVehicleClass';

export type IUpdateVehicleClassParams = ICreateVehicleClassParams & { vehicleClassId: number };

export const useUpdateVehicleClass = () =>
  useUpdate<IVehicleClass, IUpdateVehicleClassParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<IVehicleClass>(`${resources.vehicleClassUrl}/${payload?.vehicleClassId}`, payload);
    return camelcaseKeys(data, { deep: true });
  });
