import React, { useState } from 'react';
import { Button, Col, Collapse, Space, Form, Row, Select, DatePicker } from 'antd';
import moment from 'moment';
import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { useGetDestinations } from 'src/hooks/useGetDestinations';
import { IDestination } from 'src/types/branch.type';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { UseGetTripsQueryParams } from 'src/hooks/useGetTrips';
import { onDestinationFilter } from 'src/utilities/helpers.utils';
import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { TRIP_STATUS } from 'src/types/trip.type';

interface Props {
  onApply: (query: UseGetTripsQueryParams) => void;
}

const { Panel } = Collapse;
const { Option } = Select;

const panelStyle = {
  marginBottom: 24,
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 'none',
};

const formSpan = 5;

export const TripSearch: React.FC<Props> = ({ onApply }) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();

  const [filterQuery] = useState<UseGetTripsQueryParams>({
    branchId: activeBranch?.id,
    status: TRIP_STATUS.OPEN,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
    endDate: moment().format(DEFAULT_DATE_FORMAT),
  });

  const [{ data: destinationData }] = useGetDestinations();

  const onDestinationSearch = (input: string, option?: { value: number }) => {
    return onDestinationFilter(destinationData!, input, option);
  };

  const destinationOptions = destinationData?.map((destination: IDestination) => {
    return (
      <Option key={destination.id} value={destination.id}>
        {destination.name}
      </Option>
    );
  });

  const onApplyFilter = async () => {
    const values = await form.validateFields();

    if (values.date && values.date.length) {
      values.startDate = values.date[0]?.format('YYYY-MM-DD');
      values.endDate = values.date[1]?.format('YYYY-MM-DD');
      delete values.date;
    }

    if (!values.status) {
      values.status = TRIP_STATUS.OPEN;
    }

    if (onApply) {
      onApply({ ...filterQuery, ...values });
    }
  };

  const onClearFilter = () => {
    form.resetFields();
    if (onApply) {
      onApply(filterQuery);
    }
  };

  const caret = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={caret}
      style={{ background: 'rgba(0, 0, 0, 0.02)' }}
    >
      <Panel header="Filter" key="1" style={panelStyle}>
        <Form form={form} layout="vertical">
          <Row align="middle" gutter={[16, 16]}>
            <Col span={formSpan}>
              <Form.Item name="destinationId" label="Final destination">
                <Select showSearch placeholder="Final Destination" filterOption={onDestinationSearch}>
                  {destinationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="date" label="Date range">
                <DatePicker.RangePicker bordered format="YYYY-MM-DD" defaultValue={[moment(), moment()]} />
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="status" label="Trip type">
                <Select placeholder="Show Closed Trips" defaultValue={TRIP_STATUS.OPEN}>
                  <Option value={TRIP_STATUS.ALL}>All trips</Option>
                  <Option value={TRIP_STATUS.OPEN}>Open trips</Option>
                  <Option value={TRIP_STATUS.CLOSED}>Closed trips</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item label=" ">
                <Space split="|">
                  <Button type="primary" htmlType="submit" icon={<FilterFilled />} onClick={onApplyFilter}>
                    Apply Filters
                  </Button>
                  <Button type="primary" htmlType="reset" onClick={onClearFilter}>
                    Clear filters
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};
