import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const ExpenseSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#EEE9FD'} />
      <path
        fill={pathFill ?? '#6E4CF5'}
        d="M38.9286 13.5H11.0714C10.4788 13.5 10 13.9788 10 14.5714V36C10 36.5926 10.4788 37.0714
        11.0714 37.0714H38.9286C39.5212 37.0714 40 36.5926 40 36V14.5714C40 13.9788 39.5212 13.5
        38.9286 13.5ZM37.5893 34.6607H12.4107V15.9107H37.5893V34.6607ZM15.5279
        31.9051C15.6317 32.0089 15.8025 32.0089 15.9062 31.9051L21.6819
        26.1295L25.5123 29.9632C25.6161 30.067 25.7868 30.067 25.8906 29.9632L35.8348
        20.0123C35.9386 19.9085 35.9386 19.7377 35.8348 19.6339L34.6027 18.4018C34.5523 18.3519 34.4844
        18.324 34.4135 18.324C34.3427 18.324 34.2747 18.3519 34.2243 18.4018L25.7031 26.9263L21.8728
        23.0926C21.8224 23.0428 21.7544 23.0148 21.6836 23.0148C21.6127 23.0148 21.5448 23.0428 21.4944 23.0926L14.2991
        30.2913C14.2493 30.3416 14.2213 30.4096 14.2213 30.4805C14.2213 30.5513 14.2493 30.6193 14.2991 30.6696L15.5279
        31.9051Z"
      />
    </BaseSVGIcons>
  );
};
