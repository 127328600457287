import { useEffect, useState } from 'react';
import { Button, Table, Card } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { usePage } from 'src/components/page/Page';
import { routeFareOverrideColumns } from 'src/screens/routes-fare/RouteFareUtils';
import { RouteFareOverride, useFindRouteFareOverrideQuery } from 'src/graphql/queries/find-route-fare-overrides';
import { RouteFareOverrideModal } from 'src/screens/routes-fare/route-fares-override-modal/RouteFaresOverrideModal';

export const RoutesFareOverride = () => {
  const { setSubTitle, setTitle } = usePage();
  const { data, loading, refetch } = useFindRouteFareOverrideQuery();
  const [showRouteFareModal, setShowRouteFareModal] = useState<boolean>(false);
  const [selectedRouteFare, setSelectedRouteFare] = useState<RouteFareOverride>();

  useEffect(() => {
    setTitle('Route Fare');
    setSubTitle('Fare Overrides');
  }, []);

  const closeRouteFareModal = () => {
    setSelectedRouteFare(undefined);
    setShowRouteFareModal(false);
  };

  const showEditRouteFareModal = (id: string) => {
    const routeFare = data?.findRouteFareOverrides.find((rf) => rf.id === id);
    setSelectedRouteFare(routeFare);
    setShowRouteFareModal(true);
  };

  const onRouteFareActionComplete = async () => {
    await refetch();
    closeRouteFareModal();
  };

  const columns = [...routeFareOverrideColumns];
  columns.push({
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (id: string) => {
      return <EditOutlined onClick={() => showEditRouteFareModal(id)} style={{ fontSize: '16px', color: '#08c' }} />;
    },
    fixed: 'right',
    width: 100,
  });

  return (
    <div className="routes-fare-page-container">
      <Card
        bordered={false}
        extra={[
          <Button
            key="add-route-fare-key"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowRouteFareModal(true)}
          >
            Add New
          </Button>,
        ]}
      >
        <Table
          loading={loading}
          rowKey={(routeFare: RouteFareOverride) => routeFare.id}
          columns={columns}
          dataSource={data?.findRouteFareOverrides}
        />
      </Card>
      {showRouteFareModal && (
        <RouteFareOverrideModal
          show={showRouteFareModal}
          onClose={closeRouteFareModal}
          onRouteFareChange={onRouteFareActionComplete}
          defaultRouteFare={selectedRouteFare}
        />
      )}
    </div>
  );
};
