import { useState } from 'react';
import { Button, Card, Form, message, Select, Tag } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { TripService } from 'src/services/trip.service';
import { IAddTripWaybillParams, ITrip } from 'src/types/trip.type';
import { useGetWaybills } from 'src/hooks/useGetWaybills';
import { IWaybill } from 'src/types/waybill.type';

import { MoneyText } from 'src/components/topography/MoneyText';

export const TripWaybillForm = ({
  trip,
  waybills,
  onWaybillAdded,
}: {
  trip: ITrip;
  waybills: IWaybill[];
  onWaybillAdded: (waybills: IWaybill[]) => void;
}) => {
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>();
  const [{ data: allWayBills }] = useGetWaybills({ params: { assigned: 'false' } });

  const saveWaybillRecord = async (params: IAddTripWaybillParams) => {
    setLoading(true);

    try {
      const newTripWaybills = await TripService.addTripWaybills(trip.id, params);
      if (onWaybillAdded) {
        onWaybillAdded(newTripWaybills);
      }
      form.resetFields();
    } catch (e) {
      message.error('Error saving waybill record');
    }

    setLoading(false);
  };

  const onFormSubmited = ({ waybillIds }: { waybillIds: number[] }) => {
    saveWaybillRecord({ waybillIds });
  };

  const filteredWaybills = allWayBills?.filter(
    (waybill) => !waybills.some((wb) => wb.id === waybill.id) && waybill.tripId === null,
  );

  const onWayBillFilter = (input: string, option?: { value: number }): boolean => {
    const waybill = allWayBills?.find((wb) => wb.id === option?.value);
    return waybill?.sendersName.toLowerCase().includes(input) ?? false;
  };

  return (
    <Card>
      <Form
        size="large"
        wrapperCol={{
          xs: { span: 8 },
          sm: { span: 8 },
        }}
        onFinish={onFormSubmited}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Waybills"
          name="waybillIds"
          rules={[
            {
              required: true,
              message: 'Please select at least one waybill',
            },
          ]}
        >
          <Select
            filterOption={onWayBillFilter}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select waybills to be added"
          >
            {filteredWaybills?.map((waybill) => (
              <Select.Option key={waybill.id} value={waybill.id}>
                {waybill.reference} | {waybill.destination?.name} | {waybill.sendersName} |
                <Tag style={{ marginLeft: 5 }}>
                  <MoneyText value={waybill.amount} />
                </Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
