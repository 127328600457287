import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Empty } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import 'src/screens/route-group/styles/routeGroup.styles.less';
import { RouteGroupModal } from 'src/screens/route-group/route-group-modal/RouteGroupModal';
import { usePage } from 'src/components/page/Page';
import { IRoute, IRoutesGroup } from 'src/types/route.type';
import { IBranch } from 'src/types/branch.type';
import { useGetRouteGroups } from 'src/hooks/useGetRouteGroups';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

export const SCHEDULE_ITEM_TEST_ID = 'SCHEDULE_ITEM_TEST_ID';
export const ADD_NEW_BUTTON_TEST_ID = 'ADD_NEW_BUTTON_TEST_ID';

const columns: ColumnsType<IRoute> = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 50,
    key: 'id',
    render: (id, _, idx) => <span>{idx + 1}</span>,
    fixed: 'left',
  },
  {
    title: 'Route',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: IBranch, { destination }) => <span>{`${branch.name}  →  ${destination?.name}`}</span>,
  },
];

export const RouteGroup: React.FC = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const [{ data, loading }, fetchRouteGroups] = useGetRouteGroups({ params: { branchId: activeBranch?.id! } });
  const { successMessage } = useMessage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<IRoutesGroup>();

  useEffect(() => {
    setTitle('Routes');
    setSubTitle('Route Groups');
  }, []);

  const toggleRouteGroupModal = () => {
    setInitialData(undefined);
    setShowModal(!showModal);
  };

  const onRouteGroupActionCompleted = () => {
    fetchRouteGroups({ params: { branchId: activeBranch?.id! } });
    toggleRouteGroupModal();
    successMessage({ content: 'Request completed successfully' });
  };

  const onEditRouteGroup = (routeGroup: IRoutesGroup) => {
    setInitialData(routeGroup);
    setShowModal(true);
  };

  if (!data || loading) return <LoadingView />;

  return (
    <div className="routes-group-page-container">
      <Card
        bordered={false}
        extra={[
          <Button
            data-testid="ADD_NEW_BUTTON_TEST_ID"
            type="primary"
            key="add-route-group"
            icon={<PlusOutlined />}
            onClick={toggleRouteGroupModal}
          >
            Add New
          </Button>,
        ]}
      >
        {data.map((group: IRoutesGroup) => (
          <Card
            title={group.name}
            bordered
            style={{ width: 550 }}
            className="route-group-card"
            key={group.groupId}
            data-testid="SCHEDULE_ITEM_TEST_ID"
            extra={[
              <Button
                key="edit"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEditRouteGroup(group)}
              />,
            ]}
          >
            <Table
              rowKey={(_ata: any) => _ata.id}
              size="small"
              bordered
              dataSource={group.routes}
              columns={columns}
              pagination={false}
              scroll={{ x: 100 }}
            />
          </Card>
        ))}
        {data.length < 1 && <Empty />}
      </Card>

      {showModal && (
        <RouteGroupModal
          show={showModal}
          onClose={toggleRouteGroupModal}
          onRouteGroupActionCompleted={onRouteGroupActionCompleted}
          initialData={initialData}
        />
      )}
    </div>
  );
};
