import { ITrip } from 'src/types/trip.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';

export interface UseGetTripParams {
  tripId: number;
}

export const useGetTrip = ({ params }: UseGetType<UseGetTripParams>) =>
  useGet<ITrip, UseGetTripParams>(
    async (options) => {
      const payload = { ...options?.params };
      const { data } = await api.get<ITrip>(`${resources.trip.getTripsUrl}/${payload.tripId}`, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
