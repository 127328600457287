import { useCallback, useEffect, useState } from 'react';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { RoutesService } from 'src/services/routes.service';
import { IDestination } from 'src/types/branch.type';

export const useGetDestinations = (): [
  {
    data: IDestination[] | undefined;
    loading: boolean;
    error: any;
  },
  () => Promise<void>,
] => {
  const { activeBranch } = useBranch();

  const [data, setData] = useState<IDestination[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetchDestinations = useCallback(async () => {
    setLoading(true);
    try {
      const result = await RoutesService.getDestinations();
      setData(result);
    } catch (err: any) {
      setError(err);
    }
    setLoading(false);
  }, [setData, setError, setLoading, activeBranch]);

  useEffect(() => {
    const getData = async () => {
      await fetchDestinations();
    };
    getData();
  }, [fetchDestinations]);

  return [{ data, loading, error }, fetchDestinations];
};
