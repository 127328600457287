import { Select, DatePicker, Space, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import 'src/screens/dashboard/styles/dashboard.styles.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const FilterOptions = ({
  onRangeSelected,
}: {
  onRangeSelected?: (start: string | null, end: string | null) => void;
}) => {
  const [dateType, setDateType] = useState('today');

  const onOptionSelected = (selected: any) => {
    let startDate = null;
    let endDate = null;

    if (selected === 'today') {
      startDate = moment().format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else if (selected === 'yesterday') {
      startDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
      endDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
    } else if (selected === 'one_week') {
      startDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else if (selected === 'one_month') {
      startDate = moment().subtract(30, 'd').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else if (selected === 'three_month') {
      startDate = moment().subtract(3, 'M').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    } else if (selected === 'one_year') {
      startDate = moment().subtract(1, 'y').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    }

    setDateType(selected);

    if (onRangeSelected && selected !== 'custom') onRangeSelected(startDate, endDate);
  };

  const onClear = () => {
    setDateType('today');
    if (onRangeSelected) onRangeSelected(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
  };

  const disabledDate = (current: Moment) => {
    // Disable dates after today
    if (current && current > moment().endOf('day')) {
      return true;
    }

    // Disable dates before 5 years ago
    return current && current < moment().subtract(5, 'years');
  };

  const onDateRangeChange = (
    _: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    if (onRangeSelected) onRangeSelected(dateString[0], dateString[1]);
  };

  return (
    <Space>
      <Select placeholder="Today" allowClear onSelect={onOptionSelected} style={{ minWidth: 162 }} onClear={onClear}>
        <Option key="today" value="today">
          Today
        </Option>
        <Option key="yesterday" value="yesterday">
          Yesterday
        </Option>
        <Option key="one_week" value="one_week">
          Last 7 days
        </Option>
        <Option key="one_month" value="one_month">
          Last 30 days
        </Option>
        <Option key="three_month" value="three_month">
          3 Months
        </Option>
        <Option key="one_year" value="one_year">
          1 Year
        </Option>
        <Option key="custom" value="custom">
          Custom date
        </Option>
      </Select>
      {dateType === 'custom' && <RangePicker disabledDate={disabledDate} onChange={onDateRangeChange} />}
    </Space>
  );
};
