import moment from 'moment';

export const getTripExpectedCheckinTime = (date: string, time: string, value: string) => {
  const givenDate = moment.utc(`${date} ${time}`, 'YYYY-MM-DD HH:mm');

  if (value === '3 hours') {
    const threeHoursBefore = givenDate.subtract(3, 'hours');
    return threeHoursBefore.format('YYYY-MM-DD HH:mm');
  }

  if (value === '6 hours') {
    const sixHoursBefore = givenDate.subtract(6, 'hours');
    return sixHoursBefore.format('YYYY-MM-DD HH:mm');
  }

  if (value === '9 hours') {
    const nineHoursBefore = givenDate.subtract(9, 'hours');
    return nineHoursBefore.format('YYYY-MM-DD HH:mm');
  }

  if (value === '12 hours') {
    const twelveHoursBefore = givenDate.subtract(12, 'hours');
    return twelveHoursBefore.format('YYYY-MM-DD HH:mm');
  }
  if (value === '24 hours') {
    const fullDayBefore = givenDate.subtract(24, 'hours');
    return fullDayBefore.format('YYYY-MM-DD HH:mm');
  }

  return givenDate;
};
