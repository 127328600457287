/* eslint-disable max-len */
import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const RouteSVGIcon = ({ rectFill, gFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <rect width="50" height="50" fill={rectFill ?? '#E4F6FA'} rx="4" />
      <g fill={gFill ?? '#55BCD9'} clipPath="url(#clip0_161932_102649)">
        <path d="M39.732 23.728h-4.091a10.717 10.717 0 00-9.369-9.369v-4.091a.269.269 0 00-.267-.268h-2.01a.269.269 0 00-.267.268v4.091a10.717 10.717 0 00-9.369 9.369h-4.091a.269.269 0 00-.268.267v2.01c0 .147.12.267.268.267h4.091a10.717 10.717 0 009.369 9.369v4.091c0 .148.12.268.267.268h2.01c.147 0 .267-.12.267-.268v-4.091a10.717 10.717 0 009.369-9.369h4.091c.148 0 .268-.12.268-.267v-2.01a.269.269 0 00-.268-.267zM25 33.17A8.168 8.168 0 0116.83 25 8.168 8.168 0 0125 16.83 8.168 8.168 0 0133.17 25 8.168 8.168 0 0125 33.17z" />
        <path d="M25 20.986a3.97 3.97 0 00-2.839 1.179 3.984 3.984 0 00-1.178 2.84c0 1.074.418 2.078 1.178 2.838a4.003 4.003 0 002.84 1.179 3.99 3.99 0 002.839-1.179 4.003 4.003 0 001.178-2.839 3.99 3.99 0 00-1.178-2.84A3.97 3.97 0 0025 20.987z" />
      </g>
      <defs>
        <clipPath id="clip0_161932_102649">
          <path fill="#fff" d="M0 0H30V30H0z" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </BaseSVGIcons>
  );
};
