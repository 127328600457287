import { Popconfirm, Space, Table, Typography } from 'antd';

import { MoneyText } from 'src/components/topography/MoneyText';
import { ITrip } from 'src/types/trip.type';
import { IWaybill } from 'src/types/waybill.type';
import { TripWaybillForm } from 'src/screens/trips/components/TripWaybillForm';
import { isClosed } from 'src/screens/trips/utils/trip-utils';
import { tripWaybillColumns } from 'src/screens/trips/TripUtils';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { useGetTripWaybills } from 'src/hooks/useGetTripWaybills';
import { usePostRemoveWaybillFromTrip } from 'src/hooks/usePostRemoveWaybillFromTrip';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

export const TripWaybills = ({ trip }: { trip: ITrip }) => {
  const [{ data: waybills = [], loading }, refetch] = useGetTripWaybills(trip.id);
  const [removeWaybill, { loading: removeWaybillLoading }] = usePostRemoveWaybillFromTrip();
  const { successMessage } = useMessage();

  const onWaybillAdded = async () => {
    await refetch(trip.id);
  };

  const isClosedTrip = isClosed(trip);

  const waybillSummary = () => {
    const total = waybills.reduce((sum: number, waybill: IWaybill) => sum + waybill.amount, 0);
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={total} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const removeWaybillFromTrip = async (id: number) => {
    await removeWaybill(trip.id, id);
    await refetch(trip.id);
    successMessage({ content: 'Waybill successfuly removed', duration: 2.3 });
  };

  const isLoading = loading || removeWaybillLoading;

  const columns = [...tripWaybillColumns];
  if (!isClosedTrip) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      dataIndex: 'id',
      width: 100,
      render: (id: number) => (
        <Space split="|">
          <Link to={`/waybills/${id}`}>
            <EyeOutlined />
          </Link>

          <Popconfirm
            title="Are you sure you want to remove this assigned waybill?"
            onConfirm={() => removeWaybillFromTrip(id)}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleOutlined style={{ color: '#c0392b' }} />
          </Popconfirm>
        </Space>
      ),
    });
  }

  return (
    <>
      {!isClosedTrip && <TripWaybillForm trip={trip} waybills={waybills} onWaybillAdded={onWaybillAdded} />}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={waybills}
        loading={isLoading}
        pagination={false}
        summary={waybillSummary}
        scroll={{ x: 1100 }}
      />
    </>
  );
};
