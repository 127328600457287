/* eslint-disable no-plusplus */
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { MoneyText } from 'src/components/topography/MoneyText';
import { TripSeatMapQuery } from 'src/graphql/queries/__generated__/get-trip-seatMap.generated';
import { TripSeatMapSeat } from 'src/graphql/queries/get-trip-seatMap';
import { IDestination } from 'src/types/branch.type';
import { IExpense } from 'src/types/expense.type';
import { IIncome } from 'src/types/income.type';
import { IPassenger } from 'src/types/passanger.type';
import { TICKET_STATUS } from 'src/types/ticket.type';
import { ITrip, ITripHandOver } from 'src/types/trip.type';
import { IUser } from 'src/types/user.type';
import { IWaybill } from 'src/types/waybill.type';

export interface AllDestination {
  destinationId: number;
  destination: string;
  noOfPassengers: number;
  subTotal: number;
}
export interface AllWaybills {
  destinationId: number;
  destination: string;
  noOfWaybills: number;
  subTotal: number;
}

type AllHandovers = AllDestination;

export const tripExpenceColumn: ColumnsType<IExpense> = [
  {
    title: 'Expense',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => name,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
  {
    title: 'Desc.',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Logged By',
    dataIndex: 'loggedBy',
    key: 'loggedBy',
    render: (loggedBy: IUser) => `${loggedBy.firstName} ${loggedBy.lastName}`,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => moment(createdAt).format('DD-MM-YYYY hh:mm A'),
  },
];

export const destinationColumn: ColumnsType<AllDestination> = [
  {
    title: 'Destinations',
    dataIndex: 'destination', // Check out these indexes
    key: 'destination',
    render: (destination: string) => destination,
  },
  {
    title: 'No of Passengers',
    dataIndex: 'noOfPassengers',
    key: 'noOfPassengers',
    render: (noOfPassengers: number) => noOfPassengers,
  },
  {
    title: 'Subtotal',
    dataIndex: 'subTotal',
    key: 'subTotal',
    render: (subTotal: string) => subTotal,
  },
];

export const waybillColumn: ColumnsType<AllWaybills> = [
  {
    title: 'Destinations',
    dataIndex: 'destination', // Check out these indexes
    key: 'destination',
    render: (destination: string) => destination,
  },
  {
    title: 'No of Items',
    dataIndex: 'noOfWaybills',
    key: 'noOfItems',
    render: (noOfItems: number) => noOfItems,
  },
  {
    title: 'Subtotal',
    dataIndex: 'subTotal',
    key: 'subTotal',
    render: (subTotal: string) => subTotal,
  },
];

export const handoverColumn: ColumnsType<AllDestination> = [
  {
    title: 'Destinations',
    dataIndex: 'destination', // Check out these indexes
    key: 'destination',
    render: (destination: string) => destination,
  },
  {
    title: 'No of Passengers',
    dataIndex: 'noOfPassengers',
    key: 'noOfPassengers',
    render: (noOfPassengers: number) => noOfPassengers,
  },
  {
    title: 'Subtotal',
    dataIndex: 'subTotal',
    key: 'subTotal',
    render: (subTotal: string) => subTotal,
  },
];

export const tripIncomeColumn: ColumnsType<IIncome> = [
  {
    title: 'Key',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => `#${id}`,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

export const tripWaybillColumns: ColumnsType<IWaybill> = [
  {
    title: 'Waybill type',
    dataIndex: 'type',
    key: 'type',
    render: (type) => <span>{type}</span>,
  },
  {
    title: 'Senders Name',
    dataIndex: 'sendersName',
    key: 'sendersName',
    render: (name) => <span>{name}</span>,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date) => <span>{moment(date).format('ddd Do MMM, YYYY')}</span>,
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (destination: IDestination) => <span>{destination.name}</span>,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (quantity) => <span>{quantity}</span>,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value: number) => <MoneyText value={value} />,
  },
  {
    title: 'Authorised Clerk',
    dataIndex: 'ticketer',
    key: 'ticketer',
    render: (ticketer: IUser) => `${ticketer.firstName} ${ticketer.lastName}`,
  },
];

export const tripHandOvercolumns: ColumnsType<ITripHandOver> = [
  {
    title: 'Fullname',
    dataIndex: 'passenger',
    key: 'passenger',
    render: (passanger: IPassenger) => passanger.fullName,
  },
  {
    title: 'Destination',
    dataIndex: 'passenger',
    key: 'passenger',
    render: (passanger: IPassenger) => passanger.travelSchedule?.route?.destination?.name,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
];

export const getTripDateTime = (trip: ITrip) => {
  const momentInstance = moment(`${trip?.date} ${trip?.time}`);
  return {
    date: momentInstance.format('DD-MM-YYYY'),
    time: momentInstance.format('hh:mm A'),
    dateTime: momentInstance.format('DD-MM-YYYY hh:mm A'),
  };
};

export const groupWaybills = (waybills: IWaybill[]) => {
  return (
    waybills.reduce((initial: any, waybill: IWaybill) => {
      const { destination } = waybill;
      const waybillGroup = initial ? { ...initial } : {};
      const desitnationName = destination!.name;
      waybillGroup[desitnationName] = waybillGroup[desitnationName] ?? [];
      waybillGroup[desitnationName].push(waybill);
      return waybillGroup;
    }, null) ?? {}
  );
};

export const groupPassengers = (passengers: IPassenger[]) => {
  return (
    passengers.reduce((initial: any, passenger: IPassenger) => {
      const { travelSchedule } = passenger;
      const group = initial ? { ...initial } : {};
      const desitnationName = travelSchedule!.route!.destination!.name;
      group[desitnationName] = group[desitnationName] ?? [];
      group[desitnationName].push(passenger);
      return group;
    }, null) ?? {}
  );
};

export const groupPassengersByDestination = (passengers: IPassenger[]) => {
  return passengers.reduce((initial: AllDestination[], passenger: IPassenger) => {
    const { travelSchedule, ticket } = passenger;
    if (ticket?.status === TICKET_STATUS.CANCELLED) {
      return initial;
    }

    const passengerFare = (ticket?.charge ?? 0) + (ticket?.serviceCharge ?? 0);
    const groupList: AllDestination[] = initial ? [...initial] : [];
    const existingDestinationMapIndex = groupList.findIndex(
      (destination) => destination.destinationId === travelSchedule!.route!.destination?.id,
    );

    if (existingDestinationMapIndex > -1) {
      const existingDestinationMap = groupList[existingDestinationMapIndex];
      groupList[existingDestinationMapIndex] = {
        ...existingDestinationMap,
        noOfPassengers: existingDestinationMap.noOfPassengers + 1,
        subTotal: passengerFare + existingDestinationMap.subTotal,
      };
    } else {
      groupList.push({
        destinationId: travelSchedule!.route!.destination!.id,
        destination: travelSchedule!.route!.destination!.name,
        noOfPassengers: 1,
        subTotal: passengerFare,
      });
    }
    return groupList;
  }, []);
};

export const groupWaybillsByDestination = (waybills: IWaybill[]) => {
  return waybills.reduce((allWaybillsAccumulator: AllWaybills[], waybill: IWaybill) => {
    const { destination, amount } = waybill;
    const groupList: AllWaybills[] = allWaybillsAccumulator ? [...allWaybillsAccumulator] : [];
    const existingWaybillDestinationMapIndex = groupList.findIndex(
      (waybillDestination) => waybillDestination.destinationId === destination?.id,
    );

    if (existingWaybillDestinationMapIndex > -1) {
      const existingDestinationMap = groupList[existingWaybillDestinationMapIndex];
      groupList[existingWaybillDestinationMapIndex] = {
        ...existingDestinationMap,
        noOfWaybills: existingDestinationMap.noOfWaybills + 1,
        subTotal: amount + existingDestinationMap.subTotal,
      };
    } else {
      groupList.push({
        destinationId: destination!.id,
        destination: destination!.name,
        noOfWaybills: 1,
        subTotal: amount,
      });
    }
    return groupList;
  }, []);
};

export const groupHandoversByDestination = (handovers: ITripHandOver[]) => {
  return handovers.reduce((allHandoverAccumulator: AllHandovers[], tripHandover: ITripHandOver) => {
    const { passenger, amount } = tripHandover;
    const groupList: AllHandovers[] = allHandoverAccumulator ? [...allHandoverAccumulator] : [];
    const existingHandoverMapIndex = groupList.findIndex(
      (handover) => handover.destinationId === passenger!.travelSchedule!.route!.destination!.id,
    );

    if (existingHandoverMapIndex > -1) {
      const existingHandoverMap = groupList[existingHandoverMapIndex];
      groupList[existingHandoverMapIndex] = {
        ...existingHandoverMap,
        noOfPassengers: existingHandoverMap.noOfPassengers + 1,
        subTotal: amount + existingHandoverMap.subTotal,
      };
    } else {
      groupList.push({
        destinationId: passenger!.travelSchedule!.route!.destination!.id,
        destination: passenger!.travelSchedule!.route!.destination!.name,
        noOfPassengers: 1,
        subTotal: amount,
      });
    }
    return groupList;
  }, []);
};

export const generateSeatingGrid = (seatData: TripSeatMapQuery): (TripSeatMapSeat | undefined)[][] => {
  const seatingGrid: (TripSeatMapSeat | undefined)[][] = [];

  for (let rowIndex = 1; rowIndex <= seatData.tripSeatMap.length; rowIndex++) {
    const seatRow: (TripSeatMapSeat | undefined)[] = [];

    for (let colIndex = 1; colIndex <= seatData.tripSeatMap.width; colIndex++) {
      const currentSeat = seatData.tripSeatMap.seats.find((seat) => seat.row === rowIndex && seat.column === colIndex);

      seatRow.push(currentSeat);
    }

    seatingGrid.push([...seatRow]);
  }

  return seatingGrid;
};
