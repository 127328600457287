import { Button, Col, Collapse, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import moment, { Moment } from 'moment';
import { DATE_FORMATS, DEFAULT_DATE_FORMAT, HUMAN_READABLE_WITH_TIME_FORMAT_SHORT } from 'src/utilities/date.utils';
import { useGetTrips } from 'src/hooks/useGetTrips';
import { ITrip } from 'src/types/trip.type';
import { getTripDateTime } from 'src/screens/trips/TripUtils';
import { WAYBILL_STATUS } from 'src/types/waybill.type';
import { onTripFilter } from 'src/utilities/helpers.utils';
import { UseGetTravelScheduleParams } from 'src/hooks/useGetTravelSchedule';
import { useGetRoutes } from 'src/hooks/useGetRoutes';

export interface WaybillFilterprops {
  onApply?: (filter: UseGetTravelScheduleParams) => void;
}
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const panelStyle = {
  marginBottom: 24,
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 'none',
};

const formStyle = {
  maxWidth: 'none',
  padding: 24,
};

const formSpan = 6;

export const WaybillFilter = ({ onApply }: WaybillFilterprops) => {
  const { activeBranch } = useBranch();
  const [{ data: routes }] = useGetRoutes();

  const [form] = Form.useForm();

  const [{ data: trips }] = useGetTrips({
    params: {
      branchId: activeBranch?.id,
      status: 'open_trips',
      startDate: moment().format(DEFAULT_DATE_FORMAT),
    },
  });

  const tripOptions = trips?.items?.map((trip: ITrip) => {
    const { dateTime } = getTripDateTime(trip);
    return (
      <Option key={trip.id} value={trip.id}>
        {`${trip.destination?.name.toUpperCase()} | ${moment(dateTime, DATE_FORMATS.DD_MM_YYYY__hh_mm_A).format(
          HUMAN_READABLE_WITH_TIME_FORMAT_SHORT,
        )}`}
      </Option>
    );
  });

  const onTripSearch = (input: string, option?: { value: number }) => {
    return onTripFilter(trips?.items!, input, option);
  };

  const onApplyFilter = async () => {
    const { dateRange, destinationId, ...values } = await form.validateFields();
    const filter: UseGetTravelScheduleParams = { branchId: activeBranch?.id!, ...values };
    if (dateRange) {
      filter.startDate = (dateRange[0] as Moment).format(DEFAULT_DATE_FORMAT);
      filter.endDate = (dateRange[1] as Moment).format(DEFAULT_DATE_FORMAT);
    }

    if (destinationId) {
      const route = routes?.find((r) => r.destination?.id === destinationId);
      filter.routeId = route?.id;
    }

    if (onApply) {
      onApply(filter);
    }
  };

  const onClear = () => {
    form.resetFields();
    if (onApply) {
      onApply({ branchId: activeBranch?.id!, startDate: moment().format(DEFAULT_DATE_FORMAT) });
    }
  };

  const caret = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={caret}
      style={{ background: 'rgba(0, 0, 0, 0.02)' }}
    >
      <Panel header="Filter" key="1" style={panelStyle}>
        <Form form={form} layout="vertical" style={formStyle}>
          <Row align="middle" gutter={24}>
            <Col span={formSpan}>
              <Form.Item name="reference" label="Reference">
                <Input placeholder="Reference or Sender's Name" />
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="assigned" label="Assigned status">
                <Select placeholder="Assigned status">
                  <Select.Option key="assigned" value="true">
                    Assigned
                  </Select.Option>
                  <Select.Option key="unassigned" value="false">
                    Not assigned
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={formSpan}>
              <Form.Item name="status" label="Status">
                <Select placeholder="Status">
                  <Select.Option key={WAYBILL_STATUS.ISSUED} value={WAYBILL_STATUS.ISSUED}>
                    Issued
                  </Select.Option>
                  <Select.Option key={WAYBILL_STATUS.ON_HOLD} value={WAYBILL_STATUS.ON_HOLD}>
                    Pending
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="tripId" label="Trip">
                <Select showSearch placeholder="Select trip" filterOption={onTripSearch}>
                  {tripOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="dateRange" label="Date Range">
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'right' }}>
            <Space split="|">
              <Button type="primary" icon={<FilterFilled />} onClick={onApplyFilter}>
                Apply
              </Button>
              <Button onClick={onClear}>Clear filters</Button>
            </Space>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};
