import camelcaseKeys from 'camelcase-keys';

import { useUpdate } from 'src/hooks/shared/useUpdate';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IRoutesGroup } from 'src/types/route.type';
import { ICreateRouteGroupParams } from 'src/hooks/useCreateRouteGroup';

export type IUpdateRouteGroupParams = Omit<ICreateRouteGroupParams, 'branchId'> & { routeGroupId: number };

export const useUpdateRouteGroup = () =>
  useUpdate<IRoutesGroup, IUpdateRouteGroupParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<IRoutesGroup>(
      `${resources.routeGroupUrl}/${options?.params?.routeGroupId}`,
      payload,
    );
    return camelcaseKeys(data, { deep: true });
  });
