import { useCallback, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { IPassenger } from 'src/types/passanger.type';
import { ITransferPassengerParams } from 'src/types/trip.type';

export const usePostTransferPassenger = (
  id: number,
): [
  (params: ITransferPassengerParams) => Promise<IPassenger | undefined>,
  { data: IPassenger | undefined; loading: boolean; error: any },
] => {
  const [data, setData] = useState<IPassenger>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const update = useCallback(
    async (params: ITransferPassengerParams): Promise<IPassenger | undefined> => {
      setLoading(true);
      try {
        const trip = await TripService.transferPassenger(id, params);
        setData(trip);
        return trip;
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return undefined;
    },
    [setData, setError, setLoading, id],
  );

  return [update, { data, loading, error }];
};
