import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateTripMutation,
  UpdateTripMutationVariables,
} from 'src/graphql/mutations/__generated__/update-trip.generated';

export type IUpdateTrip = UpdateTripMutation['updateTrip'];

const UPDATE_TRIP_MUTATION = gql`
  mutation UpdateTrip($tripId: Float!, $input: UpdateTripInput!) {
    updateTrip(tripId: $tripId, input: $input) {
      id
      isCheckinAvailable
      maximumCapacity
      organizationId
      routeGroupId
      time
      expectedCheckinTime
      destinationId
      date
      createdAt
      closedAt
      branchId
    }
  }
`;

export const useUpdateTripMutation = (): MutationTuple<UpdateTripMutation, UpdateTripMutationVariables> =>
  useMutationErrorNotification<UpdateTripMutation, UpdateTripMutationVariables>(UPDATE_TRIP_MUTATION);
