import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  FindVehicleClassesQuery,
  FindVehicleClassesQueryVariables,
} from 'src/graphql/queries/__generated__/find-vehicle-classes.generated';

export type VehicleClass = FindVehicleClassesQuery['findVehicleClasses'][number];

const FIND_VEHICLE_CLASSES_QUERY = gql`
  query FindVehicleClasses {
    findVehicleClasses {
      id
      name
    }
  }
`;

export const useFindVehicleClassesQuery = (): QueryResult<FindVehicleClassesQuery, FindVehicleClassesQueryVariables> =>
  useQueryErrorNotification<FindVehicleClassesQuery, FindVehicleClassesQueryVariables>(FIND_VEHICLE_CLASSES_QUERY);
