import { ColumnsType } from 'antd/lib/table';
import { IVehicle, IVehicleClass } from 'src/types/vehicle.type';

export const vehicleColumns: ColumnsType<IVehicle> = [
  {
    title: 'Reg. Number',
    dataIndex: 'registeredNumber',
    key: 'registered_number',
  },
  {
    title: 'Vehicle Type',
    dataIndex: 'vehicleClass',
    key: 'vehicle_class',
    render: (vehicleClass: IVehicleClass) => vehicleClass?.name,
  },
  {
    title: 'Vehicle Manufacturer',
    dataIndex: 'make',
    key: 'make',
  },
  {
    title: 'Vehicle Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: 'Vehicle Capacity',
    dataIndex: 'seats',
    key: 'seats',
  },
];
