import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  GetTerminalsQuery,
  GetTerminalsQueryVariables,
} from 'src/graphql/queries/__generated__/get-terminals.generated';

export type GetTerminalsQuery_getTerminals_data = GetTerminalsQuery['getTerminals'][number];

const GET_TERMINALS_QUERY = gql`
  query GetTerminals {
    getTerminals {
      name
      id
      publicId
      createdAt
      addressId
      updatedAt
      organizationId
    }
  }
`;

export const useGetTerminalsQuery = (): QueryResult<GetTerminalsQuery, GetTerminalsQueryVariables> =>
  useQueryErrorNotification<GetTerminalsQuery, GetTerminalsQueryVariables>(GET_TERMINALS_QUERY);
