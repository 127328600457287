import { Button, Modal, Form, Input, Row, Col, Alert, Select } from 'antd';
import React from 'react';

import 'src/screens/vehicles/vehicle-modal/styles/addVehicleModal.style.less';
import { IUpdateVehicleParams, IVehicle, IVehicleClass } from 'src/types/vehicle.type';
import { onVehicleClassFilter } from 'src/utilities/helpers.utils';
import { UseCreateVehicleParams, useCreateVehicle } from 'src/hooks/useCreateVehicle';
import { useUpdateVehicle } from 'src/hooks/useUpdateVehicle';
import { useGetVehicleClasses } from 'src/hooks/useGetVehicleClasses';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

export interface AddVehicleProps {
  show: boolean;
  onClose: () => void;
  onComplete: (vehicle: IVehicle) => void;
  defaultVehicle?: IVehicle;
}

export const VehicleModal: React.FC<AddVehicleProps> = ({ show, onClose, onComplete, defaultVehicle }) => {
  const [{ data: vehicleClasses = [], loading: loadingVehicleClass }] = useGetVehicleClasses();
  const [createVehicle, { loading: createLoading, error: createError }] = useCreateVehicle();
  const [updateVehicle, { loading: updateLoading, error: updateError }] = useUpdateVehicle();

  const [form] = useForm();

  const handleSave = async (values: UseCreateVehicleParams | IUpdateVehicleParams) => {
    let vehicle: IVehicle | null | undefined;
    if (defaultVehicle) {
      vehicle = await updateVehicle({ params: { ...values, id: defaultVehicle.id } });
    } else {
      vehicle = await createVehicle({ params: values });
    }

    if (onComplete && vehicle) {
      onComplete(vehicle);
      form.resetFields();
    }
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    if (values) handleSave(values);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const onVehicleClassSearch = (input: string, option?: { value: number }) => {
    return onVehicleClassFilter(vehicleClasses, input, option);
  };

  const isLoading = createLoading || updateLoading || loadingVehicleClass;
  const error = createError || updateError;

  return (
    <Modal
      title={defaultVehicle ? 'Edit Vehicle' : 'Add Vehicle'}
      open={show}
      closable={!isLoading}
      maskClosable={isLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} disabled={isLoading} onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form autoComplete="on" form={form} initialValues={defaultVehicle}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="vehicleNumber" rules={[{ required: true, message: 'Vehicle must be given a number' }]}>
              <Input placeholder="Vehicle Number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="vehicleClassId" rules={[{ required: true, message: 'Please enter vehicle type' }]}>
              <Select showSearch placeholder="Vehicle Class" filterOption={onVehicleClassSearch}>
                {vehicleClasses.map((vehicleClass: IVehicleClass) => (
                  <Option key={vehicleClass.id} value={vehicleClass.id}>
                    {`${vehicleClass.name}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              name="registeredNumber"
              rules={[{ required: true, message: 'please enter vehicle plate number' }]}
            >
              <Input placeholder="Plate Number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="make" rules={[{ required: true, message: 'please specify vehicle manufacturer' }]}>
              <Input placeholder="Vehicle Manufacturer" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="model" rules={[{ required: true, message: 'please enter vehicle model' }]}>
              <Input placeholder="Vehicle Model" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="seats" rules={[{ required: true, message: 'please specify seater capacity' }]}>
              <Input placeholder="Seater" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
