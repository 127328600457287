import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  FindVehicleClassConfigsQuery,
  FindVehicleClassConfigsQueryVariables,
} from 'src/graphql/queries/__generated__/find-vehicle-class-configuration.generated';
import { VEHICLE_CLASS_CONFIG_FRAGMENT } from 'src/graphql/fragments/vehicle-class-config.fragment';

export type VehicleClassConfig = FindVehicleClassConfigsQuery['findVehicleClassConfigs'][number];

const FIND_VEHICLE_CLASS_CONFIG_QUERY = gql`
  query FindVehicleClassConfigs {
    findVehicleClassConfigs {
      ...VehicleClassConfigFragment
    }
  }
  ${VEHICLE_CLASS_CONFIG_FRAGMENT}
`;

export const useFindVehicleClassConfigQuery = (): QueryResult<
  FindVehicleClassConfigsQuery,
  FindVehicleClassConfigsQueryVariables
> =>
  useQueryErrorNotification<FindVehicleClassConfigsQuery, FindVehicleClassConfigsQueryVariables>(
    FIND_VEHICLE_CLASS_CONFIG_QUERY,
  );
