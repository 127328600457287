import { FC, ReactNode, useState } from 'react';
import { Layout, Button } from 'antd';
import { LoadingView } from 'src/components/loading-view/LoadingView';

const { Header, Content } = Layout;

interface PrintProps {
  children: ReactNode | ReactNode[];
  loading?: boolean;
  styles?: any;
}

export const Printable: FC<PrintProps> = ({ children, loading, styles }) => {
  const [showHeader, setShowHeader] = useState<boolean>(true);

  const onPrintLoaded = () => {
    window.print();
    setShowHeader(true);
  };

  const onPrint = () => {
    const divContents = document?.getElementById('printable-content')?.innerHTML;
    if (divContents) {
      setShowHeader(false);
      // this timer is a hack to get the header to be invisible in th print out
      // can be enhanced later on
      setTimeout(onPrintLoaded, 1000);
    }
  };

  return (
    <Layout className="layout">
      {showHeader && (
        <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
          <Button onClick={onPrint}>Print</Button>
        </Header>
      )}
      {loading && <LoadingView />}
      {!loading && (
        <Content style={styles} id="printable-content">
          {children}
        </Content>
      )}
    </Layout>
  );
};
