import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { getGraphqlUrl } from 'src/graphql/graphql-utils';
import { captureException } from 'src/utilities/senty.utils';
import { getSessionToken, getTerminalToken } from 'src/utilities/storage.utils';

export const onErrorLink = onError((errors: ErrorResponse) => {
  const { graphQLErrors, networkError } = errors;

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      captureException(error);
    });
  } else if (networkError) {
    captureException(networkError);
  }
});

export const createApolloClient = () => {
  const cache = new InMemoryCache();

  const httpLink = createHttpLink({
    uri: getGraphqlUrl(),
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getTerminalToken() ?? getSessionToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(onErrorLink).concat(httpLink),
    cache,
  });
};
