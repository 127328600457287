import { CSSProperties, ReactNode } from 'react';

type FlexBoxProps = Pick<CSSProperties, 'justifyContent' | 'alignItems' | 'flexDirection'> & {
  children: ReactNode | ReactNode[];
};

export const FlexBox = (props: FlexBoxProps) => {
  const { children, justifyContent, alignItems, flexDirection } = props;
  return <div style={{ display: 'flex', justifyContent, alignItems, flexDirection }}>{children}</div>;
};
