import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { usePage } from 'src/components/page/Page';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IExpense, IExpenseSearchQuery } from 'src/types/expense.type';
import { ExpenseModal } from 'src/screens/expenses/components/ExpenseModal';
import { useGetExpenses } from 'src/hooks/useGetExpenses';
import { expenseColumns } from 'src/screens/expenses/ExpenseUtils';
import { DeleteExpenseAction } from 'src/screens/expenses/components/DeleteExpenseAction';
import { ExpenseFilter } from 'src/screens/expenses/components/ExpenseFilter';
import { ExpenseSummary } from 'src/screens/expenses/components/ExpenseSummary';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';

const defaultDate = moment().format(DEFAULT_DATE_FORMAT);

export const Expenses = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialExpense, setInitialExpense] = useState<IExpense>();
  const [filterQuery, setFilterQuery] = useState<IExpenseSearchQuery>({
    branchId: activeBranch?.id!,
    startDate: defaultDate,
    endDate: defaultDate,
    page: 1,
    offset: 100,
  });
  const [{ data: expenses, loading }, fetchExpenses] = useGetExpenses({ params: filterQuery });

  useEffect(() => {
    setTitle('Finance');
    setSubTitle('Expense');
  }, [setSubTitle, setTitle]);

  const onExpenseActionCompleted = async () => {
    await fetchExpenses({ params: filterQuery });
    setShowModal(false);
  };

  const onExpenseDeleted = async () => {
    await fetchExpenses({ params: filterQuery });
  };

  const onExpenseEdited = (expense: IExpense) => {
    setInitialExpense(expense);
    setShowModal(true);
  };

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currenctPage, offset: newPageSize });
  };

  const onCancel = () => {
    setShowModal(false);
    setInitialExpense(undefined);
  };

  const dynamicColumn = [
    ...expenseColumns,
    {
      title: 'Action',
      render: (expense: IExpense) => (
        <Space split="|">
          <Button type="text" onClick={() => onExpenseEdited(expense)}>
            Edit
          </Button>
          <DeleteExpenseAction id={expense.id} onDelete={onExpenseDeleted} />
        </Space>
      ),
    },
  ];

  if (loading) return <LoadingView />;

  return (
    <>
      <ExpenseFilter onApply={setFilterQuery} />
      {expenses && <ExpenseSummary expenses={expenses} />}

      <Card
        bordered={false}
        extra={[
          <Button key="add-new-expense" type="primary" icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
            Add New
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          columns={dynamicColumn}
          dataSource={expenses?.items}
          bordered
          loading={loading}
          pagination={{
            pageSize: expenses?.meta.offset,
            current: expenses?.meta.page,
            onChange: onPageChange,
            showSizeChanger: true,
            total: expenses?.meta.totalItems,
            defaultPageSize: 100,
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
      {showModal && (
        <ExpenseModal
          show={showModal}
          onExpenseActionCompleted={onExpenseActionCompleted}
          initialExpense={initialExpense}
          onClose={onCancel}
        />
      )}
    </>
  );
};
