import { ApolloQueryResult } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Exact } from 'src/graphql/generated/types';
import { useUpdateUser } from 'src/graphql/mutations/update-user';
import { GetUserQuery } from 'src/graphql/queries/__generated__/get-user.generated';

export const PermissionForm = ({
  permissions,
  refetch,
}: {
  permissions: string[];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            userId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetUserQuery>>;
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const [updateUser, { data, loading: updateUserLoading }] = useUpdateUser();

  const userPermissions = [
    { name: 'Write User', value: 'write_user' },
    { name: 'View User', value: 'view_user' },
    { name: 'Delete User', value: 'delete_user' },
  ];

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    await updateUser({
      variables: {
        input: {
          permissions: values.checkboxGroup,
        },
        userId: id!,
      },
    });
  };

  return (
    <div>
      <Form autoComplete="on" form={form} initialValues={{ checkboxGroup: permissions }}>
        <Form.Item
          name="checkboxGroup"
          label="Permissions"
          rules={[
            {
              required: true,
              message: 'Please select at least one option!',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              {userPermissions?.map((item) => (
                <Col key={item.name} xs={12} sm={8} md={8} lg={4} xl={4}>
                  <Checkbox value={item.value}>{item.name}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Button key="submit" type="primary" onClick={handleSubmit} disabled={updateUserLoading}>
          Save
        </Button>
      </Form>
    </div>
  );
};
