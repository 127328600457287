import { useCallback, useState } from 'react';
import { IncomeService } from 'src/services/income.service';

export const useDeleteIncome = (): [
  (id: number) => Promise<boolean | undefined>,
  {
    data: boolean | undefined;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteIncome = useCallback(
    async (id: number) => {
      let result;
      setLoading(true);
      try {
        await IncomeService.deleteIncome(id);
        result = true;
        setData(true);
      } catch (err: any) {
        result = false;
        setError(err);
      }
      setLoading(false);
      return result;
    },
    [setData, setError, setLoading],
  );

  return [deleteIncome, { data, loading, error }];
};
