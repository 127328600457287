import { IRoutesGroup } from 'src/types/route.type';
import { IVehicle, IVehicleClass } from 'src/types/vehicle.type';
import { IBranch, IDestination } from 'src/types/branch.type';
import { IUser } from 'src/types/user.type';
import { IPassenger } from 'src/types/passanger.type';
import { ISeat, ISeatMap } from 'src/types/seat-map.type';

export enum TRIP_STATUS {
  ALL = 'all',
  OPEN = 'open_trips',
  CLOSED = 'closed_trips',
}

export interface ITrip {
  __typename?: 'Trip';
  id: number;
  organizationId: number;
  branchId: number;
  branch?: IBranch;
  routeGroup?: IRoutesGroup;
  destinationId: number;
  destination?: IDestination;
  routeGroupId: number;
  date: string;
  time: string;
  sourceSeatMapId?: number;
  maximumCapacity?: number | null;
  isCheckinAvailable?: boolean;
  expectedCheckinTime?: any | null;
  vehicleId?: number;
  vehicle?: IVehicle;
  driverId?: number;
  driver?: IUser;
  closedAt?: string;
  closedBy?: IUser;
  closedById?: number;
  virtualVehicleNumber?: string;
  vehicleClass?: IVehicleClass;
  vehicleClassId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ITripEventsSearchQuery {
  limit?: number;
  branchId: number;
}

export interface ITripEventType {
  id: number;
  name: string;
  organizationId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ITripEvent {
  id: number;
  organizationId: number;
  userId: number;
  user?: IUser;
  trip_id: number;
  trip?: ITrip;
  type_id: number;
  type?: ITripEventType;
  description: string;
  date: string;
  createdAt: string;
  updatedAt: string;
}

export interface INewTripEventParams {
  eventTypeId: number;
  description: string;
  date: string;
  branchId: number;
}

export interface ITripHandOver {
  id: number;
  organizationId: number;
  tripId: number;
  amount: number;
  passengerId: number;
  passenger?: IPassenger;
  createdAt: string;
  updatedAt: string;
}

export interface INewHandoverParams {
  amount: number;
  passengerId: number;
}

export interface IAddTripWaybillParams {
  waybillIds: number[];
}

export interface ITripSeatMap extends ISeatMap {
  tripId: number;
  seats?: ISeat[];
}

export interface ITransferPassengerParams {
  branchId: number;
  tripId: number;
  travelScheduleId: number;
  passengerId?: number;
}

export interface IRemoveWaybillData {
  waybillId: number;
}

export interface ITripConfiguration {
  id: number;
  routeGroup?: IRoutesGroup;
  routeGroupId: number;
  time: string;
  price?: string;
  vehicleClass?: IVehicleClass;
  vehicleClassId: number;
  days: string[];
  branchId: number;
}
