import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PassengerTicket } from 'src/components/passenger-ticket/PassengerTicket';
import { Printable } from 'src/components/print/Print';
import { useGetTicket } from 'src/hooks/useGetTicket';

export const PrintTicket: FC = () => {
  const params = useParams();
  const [{ data: ticket, loading }] = useGetTicket(parseInt(params.id!, 10));

  return (
    <Printable loading={loading}>
      {ticket && <PassengerTicket ticket={ticket} />}
    </Printable>
  );
};
