import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { ITransaction } from 'src/types/transaction.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { ITicket } from 'src/types/ticket.type';
import { IPassenger, PASSENGERT_TYPE } from 'src/types/passanger.type';

export interface IReservation {
  id: number;
  branchId: number;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  destinationId: number;
  reference: string;
  organizationId: number;
  tripId: number;
  status: string;
  scheduleId: number;
  issuedById: number;
  updatedAt: Date;
  createdAt: Date;
  transactionId: number;
  cancelledAt?: string;
  cancelledById?: string;
}

export type UseCreateReservationParams = {
  passengers: {
    fullName: string;
    type: PASSENGERT_TYPE;
    phone?: string;
    gender: string;
    seatId?: number;
    isPrimaryPassenger: boolean;
    tripId: number;
    bloodGroup: string;
    routeId: number;
    travelScheduleId: number;
    organizationId: number;
    branchId: number;
    nextKinFirstName: string;
    nextKinLastName: string;
    nextKinPhone: string;
  }[];
};

export const useCreateReservation = () =>
  useUpdate<
    { tickets: ITicket[]; transaction: ITransaction; reservation: IReservation; passengers: IPassenger[] },
    UseCreateReservationParams
  >(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<{
      tickets: ITicket[];
      transaction: ITransaction;
      reservation: IReservation;
      passengers: IPassenger[];
    }>(resources.reservation, payload);
    return data;
  });
