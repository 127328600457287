import { useCallback, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { IWaybill } from 'src/types/waybill.type';

export const usePostRemoveWaybillFromTrip = (): [
  (id: number, waybillId: number) => Promise<void>,
  { data: IWaybill | undefined; loading: boolean; error: any },
] => {
  const [data, setData] = useState<IWaybill>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const removeWaybill = useCallback(
    async (tripId: number, waybillId: number) => {
      setLoading(true);
      try {
        const waybillData = await TripService.removeWaybill(tripId, { waybillId });
        setData(waybillData);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError, setLoading],
  );

  return [removeWaybill, { data, loading, error }];
};
