import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { ROUTE_FARE_OVERRIDE_FRAGMENT } from 'src/graphql/fragments/route-fare-override.fragment';
import {
  UpdateRouteFareOverrideMutation,
  UpdateRouteFareOverrideMutationVariables,
} from 'src/graphql/mutations/__generated__/update-route-fare-override.generated';

const UPDATE_ROUTE_FARE_OVERRIDE_QUERY = gql`
  mutation UpdateRouteFareOverride($fareOverrideId: String!, $input: UpdateRouteFareOverrideInput!) {
    updateRouteFareOverride(fareOverrideId: $fareOverrideId, input: $input) {
      ...RouteFareOverrideFragment
    }
  }

  ${ROUTE_FARE_OVERRIDE_FRAGMENT}
`;

export const useUpdateRouteFareOverrideMutation = (): MutationTuple<
  UpdateRouteFareOverrideMutation,
  UpdateRouteFareOverrideMutationVariables
> =>
  useMutationErrorNotification<UpdateRouteFareOverrideMutation, UpdateRouteFareOverrideMutationVariables>(
    UPDATE_ROUTE_FARE_OVERRIDE_QUERY,
  );
