import { AUTH_TOKEN_KEY } from 'src/constants';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

export interface AuthUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  organizationId: number;
  phone: string;
  roles?: string[];
}

export class AuthService {
  public static async authenticate(username: string, password: string): Promise<string> {
    // we clear storage to remove any previous tokens
    localStorage.clear();
    const { data } = await api.post<{ accessToken: string }>(resources.loginUrl, { username, password });
    localStorage.setItem(AUTH_TOKEN_KEY, data.accessToken);
    // setAuthorizationToken(data.accessToken);
    return data.accessToken;
  }

  public static async getAuthenticatedUser(): Promise<AuthUser> {
    const { data } = await api.get<AuthUser>(resources.authUser);
    return data;
  }
}
