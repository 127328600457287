import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateTripSeatStatusMutation,
  UpdateTripSeatStatusMutationVariables,
} from 'src/graphql/mutations/__generated__/update-trip-seat-status.generated';

const UPDATE_TRIP_SEAT_STATUS = gql`
  mutation UpdateTripSeatStatus($seatId: Float!, $status: SeatStatusEnum!) {
  updateTripSeatStatus(seatId: $seatId, status: $status) {
    id
    seatMapId
    number
    column
    row
    status
    createdAt
    updatedAt
  }
}
`;

export const useUpdateTripSeatsStatus = (): MutationTuple<
  UpdateTripSeatStatusMutation,
  UpdateTripSeatStatusMutationVariables
> =>
  useMutationErrorNotification<UpdateTripSeatStatusMutation, UpdateTripSeatStatusMutationVariables>(
    UPDATE_TRIP_SEAT_STATUS,
  );
