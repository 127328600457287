import { IVehicle } from 'src/types/vehicle.type';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';

export interface UseCreateVehicleParams {
  id: number;
  vehicleClassId: number;
  make: string;
  model: string;
  vehicleNumber: string;
  registeredNumber: string;
  seats: number;
}

export const useCreateVehicle = () =>
  useUpdate<IVehicle, UseCreateVehicleParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IVehicle>(resources.vehicleUrl, payload);
    return camelcaseKeys(data, { deep: true });
  });
