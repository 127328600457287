import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PassengerManifest } from 'src/components/passenger-manifest/PassengerManifest';
import { Printable } from 'src/components/print/Print';
import { useGetTrip } from 'src/hooks/getTrips';
import { useGetTripPassengers } from 'src/hooks/useGetTripPassengers';
import { TICKET_STATUS } from 'src/types/ticket.type';

export const TripManifest: FC = () => {
  const params = useParams();

  const [{ data: trip, loading: loadingTrip }] = useGetTrip({ params: { tripId: parseInt(params.id!, 10) } });
  const [{ data: passengers = [], loading }] = useGetTripPassengers(parseInt(params.id!, 10));

  const activePassengers = passengers?.filter((p) => p.ticket?.status === TICKET_STATUS.ISSUED);

  return (
    <Printable loading={loading || loadingTrip}>
      {trip && <PassengerManifest trip={trip} passangers={activePassengers} />}
    </Printable>
  );
};
