export const resources = {
  loginUrl: '/auth/signin',
  authUser: '/auth/user',
  authTerminal: '/auth/terminal',
  userUrl: '/users',
  vehicleUrl: '/vehicles',
  routesUrl: '/routes',
  routeFaresUrl: '/route-fare',
  branchesUrl: '/terminal',
  destinationsUrl: '/destinations',
  routeGroupUrl: '/route-group',
  travelScheduleUrl: '/travel/schedule',
  travelAvailabilityUrl: '/travel/availability',
  vehicleClassUrl: '/vehicle-class',
  tripConfigurationUrl: '/trips/configs',
  userBranchUrl: '/users/branch',
  busClassUrl: '/vehicles/class',
  reservation: '/reservation',
  reservationCompleteUrl: '/reservation/complete',
  transactionsUrl: '/transactions',
  ticketSalesUrl: '/tickets',
  ticketPasssengerSaleUrl: '/tickets/passenger',
  waybillUrl: '/waybill',
  waybillReservationUrl: '/reservation/waybill',
  waybillReservationComplete: '/reservation/waybill/complete',
  trip: {
    getTripsUrl: '/trips',
    getTripEventTypesUrl: '/trips/event-types',
  },
  expensesUrl: '/expenses',
  incomeUrl: '/income',
  countVehiclesUrl: '/analytics/vehicles',
  countRoutesUrl: '/analytics/routes',
  dashboardTransactionsUrl: '/transactions/statistics',
  countTicketsUrl: '/analytics/tickets',
  sumTransactionsUrl: '/analytics/transactions',
  analyticsUrl: '/analytics',
  passengerUrl: '/passengers',
  seatMapUrl: '/seat-maps',
  reservationsUrl: '/reservation',
  destinationByIdUrl: (id: number) => `/destinations/${id}`,
  transactionTypeFilterUrl: '/filter/transaction-type',
  expenseTypeFilterUrl: '/filter/expense-type',
  transactionStatusFilterUrl: '/filter/transaction-status',
  paymentMethodFilterUrl: '/filter/payment-method',
};
