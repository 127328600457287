import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  GetBranchTripsForTransferQuery,
  GetBranchTripsForTransferQueryVariables,
} from 'src/graphql/queries/__generated__/get-trips-for-transfer.generated';

export type TripForTransfer = GetBranchTripsForTransferQuery['getBranchTripsForTransfer'][number];

const GET_TRIPS_FOR_TRANSFER_QUERY = gql`
  query GetBranchTripsForTransfer($branchId: String!, $tripDate: String) {
    getBranchTripsForTransfer(branchId: $branchId, tripDate: $tripDate) {
      id
      destinationName
      className
      dateTime
    }
  }
`;

export const useGetTripsForTransfer = (
  branchId: string,
  tripDate: string,
): QueryResult<GetBranchTripsForTransferQuery, GetBranchTripsForTransferQueryVariables> =>
  useQueryErrorNotification<GetBranchTripsForTransferQuery, GetBranchTripsForTransferQueryVariables>(
    GET_TRIPS_FOR_TRANSFER_QUERY,
    {
      variables: { branchId, tripDate },
    },
  );
