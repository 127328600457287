import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const ParcelSVGIcon = ({ rectFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <rect width="50" height="50" fill={rectFill ?? '#EEE9FD'} rx="4" />
      <path
        fill={pathFill ?? '#6E4CF5'}
        fillOpacity="0.85"
        // eslint-disable-next-line max-len
        d="M35.934 23.07l-.006-.024-3.287-8.353a1.131 1.131 0 00-1.078-.796H18.238c-.497 0-.94.33-1.08.808l-3.074 8.268-.009.02-.005.024c-.039.143-.05.29-.03.433a2.203 2.203 0 00-.006.14v10.72a1.784 1.784 0 001.782 1.782H34.19c.981 0 1.78-.8 1.784-1.782V23.59c0-.037 0-.075-.003-.108.012-.143 0-.28-.038-.413zm-8.666-1.26l-.009.46c-.023 1.315-.932 2.2-2.259 2.2-.647 0-1.204-.208-1.605-.604-.402-.395-.621-.946-.633-1.596l-.009-.46h-6.03l2.33-5.663h11.695l2.394 5.663h-5.874zm-10.986 2.25h4.608c.712 1.672 2.226 2.66 4.113 2.66.988 0 1.904-.276 2.646-.797a4.332 4.332 0 001.485-1.863h4.585v9.782H16.281V24.06z"
      />
    </BaseSVGIcons>
  );
};
