import React from 'react';
import { Button, Modal, Form, Row, Col, Alert, Select, Input } from 'antd';

import 'src/screens/routes-management/styles/routesManagement.styles.less';
import { IDestination } from 'src/types/branch.type';
import { onStateFilter } from 'src/utilities/helpers.utils';
import { countries, states } from 'src/screens/destinations/DestinationUtils';
import { UseCreateDestinationParams, useCreateDestination } from 'src/hooks/useCreateDestination';
import { UseUpdateDestinationParams, useUpdateDestination } from 'src/hooks/useUpdateDestination';

const { Option } = Select;

export interface DestinationModalProps {
  show: boolean;
  onClose: () => void;
  onDestinationActionCompleted: () => void;
  defaultDestination?: IDestination;
}

export const DestinationModal: React.FC<DestinationModalProps> = ({
  show,
  onClose,
  onDestinationActionCompleted,
  defaultDestination,
}) => {
  const [form] = Form.useForm();
  const [createDestination, { loading, error }] = useCreateDestination();
  const [updateDestination, { loading: updateDestinationLoading, error: updateError }] = useUpdateDestination();

  const handleSave = async (values: UseCreateDestinationParams | UseUpdateDestinationParams) => {
    let response: IDestination | undefined;
    if (defaultDestination) {
      response = await updateDestination({ params: { ...values, destinationId: defaultDestination.id } });
    } else {
      response = await createDestination({ params: values });
    }

    if (response) {
      onDestinationActionCompleted();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const onStateSearch = (input: string, option?: { value: string }) => {
    return onStateFilter(states, input, option);
  };

  const isLoading = loading || updateDestinationLoading;

  return (
    <Modal
      title={defaultDestination ? 'Edit Destination' : 'New Destination'}
      open={show}
      closable={!isLoading}
      maskClosable={isLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={isLoading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      {error || (updateError && <Alert message={error ?? updateError} type="error" className="error-alert" />)}

      <Form autoComplete="on" form={form} initialValues={{ ...defaultDestination }} onFinish={handleSave}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item name="name" rules={[{ required: true, message: 'Name is required' }]} label="Name">
              <Input placeholder="Enter name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="code"
              rules={[{ required: true, len: 3, message: '3 Letters Code is required' }]}
              label="Code"
            >
              <Input placeholder="Enter code" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="state" rules={[{ required: true, message: 'Please select state' }]} label="state">
              <Select showSearch placeholder="State" filterOption={onStateSearch}>
                {states.map((state: string) => {
                  return (
                    <Option value={state} key={state}>
                      {state}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="country" rules={[{ required: true, message: 'Please select country' }]} label="Country">
              <Select placeholder="Country">
                {countries.map((country: string) => {
                  return (
                    <Option value={country} key={country}>
                      {country}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
