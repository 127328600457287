export const Cash = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" fill="none" viewBox="0 0 20 12">
      <path
        fill="#17D97C"
        d="M19 0H1a1 1 0 00-1 1v10a1 1 0 001 1h18a1 1 0 001-1V1a1 1
         0 00-1-1zM1.5 10.5v-2a2 2 0 012 2h-2zm0-7v-2h2a2 2 0 01-2
          2zM10 9C8.62 9 7.5 7.657 7.5 6S8.62 3 10 3s2.5 1.343 2.5
           3-1.12 3-2.5 3zm8.5 1.5h-2a2 2 0 012-2v2zm0-7a2 2 0 01-2-2h2v2z"
      />
    </svg>
  );
};
