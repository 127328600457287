import { useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { IBranch } from 'src/types/branch.type';

export interface UseGetBranchesParams {}

export const useGetBranches = () =>
  useGet<IBranch[], UseGetBranchesParams>(async () => {
    const { data } = await api.get<IBranch[]>(resources.branchesUrl);
    return camelcaseKeys(data, { deep: true });
  });
