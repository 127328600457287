import { ApolloQueryResult } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Exact } from 'src/graphql/generated/types';
import { useUpdateUserBranches } from 'src/graphql/mutations/update-user-branches';
import { GetUserBranchesQuery } from 'src/graphql/queries/__generated__/get-user-branches.generated';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';

export const BranchForm = ({
  branches,
  refetch,
}: {
  branches: {
    name: string;
    id: string;
  }[];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            userId: number;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetUserBranchesQuery>>;
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { allBranches } = useBranch();

  const [updateUserBranches, { data, loading: updateUserLoading }] = useUpdateUserBranches();

  const initialCheckboxValues = branches.map((item) => item.id);

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const stringBranchIdsArray = values.checkboxGroup as string[];
    const numberBranchIdsArrayArray = stringBranchIdsArray.map((str) => +str);
    await updateUserBranches({
      variables: {
        input: {
          branchIds: numberBranchIdsArrayArray,
        },
        userId: id!,
      },
    });
  };

  return (
    <div>
      <Form autoComplete="on" form={form} initialValues={{ checkboxGroup: initialCheckboxValues }}>
        <Form.Item
          name="checkboxGroup"
          label="Branch"
          rules={[
            {
              required: true,
              message: 'Please select at least one option!',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              {allBranches?.getTerminals?.map((item) => (
                <Col xs={12} sm={8} md={8} lg={4} xl={4} key={item.id}>
                  <Checkbox value={item.id}>{item.name}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Button key="submit" type="primary" onClick={handleSubmit} disabled={updateUserLoading}>
          Save
        </Button>
      </Form>
    </div>
  );
};
