import { ITransactionAnalysis } from 'src/types/transaction.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';

export interface UseGetTransactionAnalysisParams {
  startDate?: string;
  endDate?: string;
  terminalId: number;
}

export const useGetTransactionAnalysis = ({ params }: UseGetType<UseGetTransactionAnalysisParams>) =>
  useGet<ITransactionAnalysis, UseGetTransactionAnalysisParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<ITransactionAnalysis>(`${resources.transactionsUrl}/analysis`, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
