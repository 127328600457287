import { Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { MoneyText } from 'src/components/topography/MoneyText';
import { IExpense } from 'src/types/expense.type';
import { IUser } from 'src/types/user.type';
import { textOrNA } from 'src/utilities/string.utils';

export const expenseColumns: ColumnsType<IExpense> = [
  {
    title: 'Expense',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => name,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description: string) => textOrNA(description),
  },
  {
    title: 'Logged By',
    dataIndex: 'loggedBy',
    key: 'loggedBy',
    render: (loggedBy: IUser) => `${loggedBy.firstName} ${loggedBy.lastName}`,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => moment(createdAt).format('DD-MM-YYYY hh:mm a'),
  },
  {
    title: 'Payment Mode',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (paymentMethod: string) => textOrNA(paymentMethod),
  },
];

export const tripExpensesOptions = (
  <Select>
    <Select.Option value="Fuel Commission">Fuel Commission</Select.Option>
    <Select.Option value="Waybill Commission">Waybill Commission</Select.Option>
    <Select.Option value="Loading Commision">Loading Commision</Select.Option>
    <Select.Option value="Managers Commission">Managers Commission</Select.Option>
    <Select.Option value="Managers Additional Commission">Managers Additional Commission</Select.Option>
    <Select.Option value="Executive Commission">Executive Commission</Select.Option>
    <Select.Option value="Passenger Ticket Refund">Passenger ticket refund</Select.Option>
    <Select.Option value="Agent Commission">Agent Commission</Select.Option>
    <Select.Option value="Miscellaneous">Miscellaneous</Select.Option>
  </Select>
);
