import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IDestination } from 'src/types/branch.type';
import { UseCreateDestinationParams } from 'src/hooks/useCreateDestination';

export type UseUpdateDestinationParams = UseCreateDestinationParams & { destinationId: number };

export const useUpdateDestination = () =>
  useUpdate<IDestination, UseUpdateDestinationParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<IDestination>(
      `${resources.destinationsUrl}/${options?.params?.destinationId}`,
      payload,
    );
    return data;
  });
