import React, { useState, useEffect } from 'react';
import { Button, Table, Card, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { RouteModal } from 'src/screens/routes-management/routes-modal/RoutesModal';
import { IRoute } from 'src/types/route.type';
import { usePage } from 'src/components/page/Page';
import 'src/screens/routes-management/styles/routesManagement.styles.less';
import { SearchBar } from 'src/components/search-bar/SearchBar';
import { useGetRoutes } from 'src/hooks/useGetRoutes';

export const RoutesManagementScreen = () => {
  const { setSubTitle, setTitle } = usePage();
  const [{ data = [], loading }, refetchRoutes] = useGetRoutes();
  const [selectedRoute, setSelectedRoute] = useState<IRoute>();
  const [showRouteModal, setShowRouteModal] = useState<boolean>(false);
  const [searchField, setSearchField] = useState<string>('');

  useEffect(() => {
    setTitle('Routes');
    setSubTitle('Manage Routes');
  }, []);

  const closeRouteModal = () => {
    setSelectedRoute(undefined);
    setShowRouteModal(false);
  };

  const showEditRouteModal = (id: number) => {
    const route = data?.find((r) => r.id === id);
    setSelectedRoute(route);
    setShowRouteModal(true);
  };

  const onRouteActionComplete = async (route: IRoute) => {
    const routeIndex = data?.findIndex((r) => r.id === route.id);
    const updateRouteList = [...data];

    if (routeIndex > -1) {
      updateRouteList[routeIndex] = route;
    } else {
      updateRouteList.push(route);
    }

    refetchRoutes();
    setSelectedRoute(undefined);
    setShowRouteModal(false);
    message.success('route has been edited successfully!!', 2.5);
  };

  const columns: ColumnsType<IRoute> = [
    {
      title: 'Departure',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => <span>{branch?.name}</span>,
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (destination) => <span>{destination?.name}</span>,
    },
    {
      title: 'Code',
      dataIndex: 'destination',
      key: 'destination',
      render: (destination) => <span>{destination?.code}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => {
        return (
          <Button
            type="ghost"
            onClick={() => showEditRouteModal(id)}
            className="more-btn"
            href="#"
            icon={<EditOutlined />}
          />
        );
      },
      fixed: 'right',
      width: 100,
    },
  ];

  const handleRoutesSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
  };

  let filteredRoutes = [...data];

  if (searchField !== '') {
    filteredRoutes = data.filter((routes) => {
      return routes?.destination?.name.toLowerCase().includes(searchField.toLowerCase());
    });
  }

  return (
    <div className="routes-page-container">
      <Card
        className="card"
        bordered={false}
        extra={[
          <div className="search-container">
            <SearchBar
              width={300}
              placeHolder="Search by Destination"
              className="routes-search"
              onChange={(e) => handleRoutesSearch(e)}
            />
            <Button key="add-route-key" type="primary" icon={<PlusOutlined />} onClick={() => setShowRouteModal(true)}>
              Add New
            </Button>
          </div>,
        ]}
      >
        <Table
          loading={loading}
          rowKey={(route: IRoute) => route.id}
          columns={columns}
          dataSource={filteredRoutes}
          size="small"
          bordered
          pagination={{ pageSize: 50, total: data.length }}
          scroll={{ x: 1000 }}
        />
      </Card>
      {showRouteModal && (
        <RouteModal
          show={showRouteModal}
          onClose={closeRouteModal}
          onRouteCreated={onRouteActionComplete}
          defaultRoute={selectedRoute}
        />
      )}
    </div>
  );
};
