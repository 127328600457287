import { Table, Card, Space } from 'antd';
import { PassengerCheckIn } from 'src/screens/reservation-details/PassengerCheckin';

import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { ReservationTicket, ReservationTrip, ReservationSchedule } from 'src/graphql/queries/get-reservation-by-id';

interface IProps {
  data: ReservationTicket[];
  trip: ReservationTrip;
  schedule: ReservationSchedule;
}

export const PassengerDetails = ({ data, trip, schedule }: IProps) => {
  const columns: ColumnsType<ReservationTicket> = [
    {
      title: 'Fullname',
      key: '_',
      render: (passengerData) => {
        return <p>{passengerData?.passenger?.fullName}</p>;
      },
    },
    {
      title: 'Destination',
      key: '_',
      render: () => {
        return <p>{schedule?.route?.destination?.name}</p>;
      },
    },
    {
      title: 'Gender',
      key: '_',
      render: (passengerData) => {
        return <p>{passengerData?.passenger?.gender}</p>;
      },
    },
    {
      title: 'Seat Number',
      key: '_',
      render: (passengerData) => {
        return <p>{passengerData?.passenger?.seat?.number}</p>;
      },
    },
    {
      title: 'Phone Number',
      key: '_',
      render: (passengerData) => {
        return <p>{passengerData?.passenger?.phone}</p>;
      },
    },
    {
      title: 'Next of Kin Name',
      key: '_',
      render: (passengerData) => {
        return (
          <p>
            {passengerData?.passenger?.nextKinFirstName} {passengerData?.passenger?.nextKinLastName}
          </p>
        );
      },
    },
    {
      title: 'Next of Kin Phone Number',
      key: '_',
      width: 300,
      render: (passengerData) => {
        return <p>{passengerData?.passenger?.nextKinPhone}</p>;
      },
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 150,
      render: (passengerData) => (
        <Space align="center">
          <Space>
            <Link to={`/ticket/${passengerData.id}/print`} target="_">
              <PrinterOutlined />
            </Link>
          </Space>
          {passengerData.passenger?.seat?.id ? null : (
            <Space>
              <PassengerCheckIn passengerTicket={passengerData} trip={trip} />
            </Space>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="reservation-passenger-details">
      <Card title="Passenger’s Details" style={{ width: '100%' }}>
        <div className="reservation-passenger-card-content">
          <Table columns={columns} dataSource={data} scroll={{ x: 1600 }} rowKey="id" pagination={false} />
        </div>
      </Card>
    </div>
  );
};
