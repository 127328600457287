import { FC, useEffect } from 'react';
import { Alert, DatePicker, Form, message, Modal, Select, Tag } from 'antd';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import moment from 'moment';
import { ITrip } from 'src/types/trip.type';
import { IPassenger } from 'src/types/passanger.type';
import { useGetTripSchedules } from 'src/hooks/useGetTripSchedules';
import { ITravelSchedule } from 'src/types/travel.type';
import { usePostTransferPassenger } from 'src/hooks/usePostTransferPassenger';
import { useGetTripSeatMap } from 'src/hooks/GetTripSeats';
import { ISeat, SEAT_STATUS } from 'src/types/seat-map.type';
import { useGetBranches } from 'src/hooks/useGetBranches';
import { TripForTransfer, useGetTripsForTransfer } from 'src/graphql/queries/get-trips-for-transfer';
import { DATE_FORMATS } from 'src/utilities/date.utils';

interface TransferModalProps {
  open?: boolean;
  onClose?: () => void;
  passenger?: IPassenger;
  trip?: ITrip;
}

interface TransferPassengerFormFields {
  branchId: number;
  tripId: number;
  travelScheduleId: number;
}

export const TransferPassengerModal: FC<TransferModalProps> = ({ open, passenger, trip, onClose }) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm<TransferPassengerFormFields>();

  const branchId = Form.useWatch('branchId', form) ?? activeBranch?.id;
  const tripDate = Form.useWatch('tripDate', form);
  const tripId = Form.useWatch('tripId', form);

  const { data: trips, loading: loadingTrips } = useGetTripsForTransfer(String(branchId), tripDate);
  const [{ data: branches }] = useGetBranches();
  const [{ data: tripSchedules, loading: loadingSchedules }] = useGetTripSchedules(tripId ?? trip?.id);
  const [transferPassenger, { loading: loadingTransfer, error }] = usePostTransferPassenger(trip?.id!);
  const [{ data: tripSeats, loading: loadingTripSeats }] = useGetTripSeatMap(tripId ?? trip?.id);

  useEffect(() => {
    form.resetFields();
  }, [passenger]);

  const onComplete = async () => {
    const values = await form.validateFields();
    if (values) {
      const transferedPassenger = await transferPassenger({
        ...values,
        tripId: Number(values.tripId),
        passengerId: passenger?.id,
      });

      if (transferedPassenger && onClose) {
        message.success('Passenger successfully transfered');
        onClose();
      }
    }
  };

  return (
    <Modal
      okButtonProps={{ children: 'Transfer' }}
      onOk={onComplete}
      title="Transfer Passenger"
      open={open}
      onCancel={onClose}
      closable={!loadingTransfer}
      confirmLoading={loadingTransfer}
    >
      {error && <Alert message={error?.message} type="error" closable style={{ marginBottom: 10 }} />}
      <Form
        layout="vertical"
        form={form}
        initialValues={{ branchId: activeBranch?.id, tripDate: moment() }}
        disabled={loadingTransfer}
      >
        <Form.Item name="branchId" label="Select departure branch">
          <Select>
            {branches?.map((b) => (
              <Select.Option key={b.id} value={b.id}>
                {b.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select trip date"
          name="tripDate"
        >
          <DatePicker placeholder="Select Trip Date" format="YYYY-MM-DD" className="w-100" />
        </Form.Item>
        <Form.Item name="tripId" label="Select trip">
          <Select loading={loadingTrips} disabled={!branchId || loadingTrips}>
            {trips?.getBranchTripsForTransfer?.map((t: TripForTransfer) => {
              return (
                <Select.Option key={t.id} value={t.id}>
                  {`${t.destinationName}`} | {t.className} |{' '}
                  <Tag>{moment(t.dateTime).format(DATE_FORMATS.DD_MM_YYYY__hh_mm_A)}</Tag>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="travelScheduleId" label="Select schedule">
          <Select loading={loadingSchedules} disabled={!tripId || loadingSchedules}>
            {tripSchedules?.map((schedule: ITravelSchedule) => {
              const dateTime = moment(`${schedule?.trip?.date} ${schedule?.trip?.time}`);
              return (
                <Select.Option key={schedule.id} value={schedule.id}>
                  {schedule.route?.destination?.name} | <Tag>{dateTime.format(DATE_FORMATS.DD_MM_YYYY__hh_mm_A)}</Tag>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="seatId" label="Select seat">
          <Select loading={loadingSchedules} disabled={!tripId || loadingTripSeats}>
            {tripSeats?.seats?.map((seat: ISeat) => {
              return (
                <Select.Option key={seat.id} value={seat.id} disabled={seat.status !== SEAT_STATUS.AVAILABLE}>
                  {seat.number}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
