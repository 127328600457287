import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { TripReportQuery, TripReportQueryVariables } from 'src/graphql/queries/__generated__/get-trip-report.generated';

export type TripReport = TripReportQuery['tripReport'];

const GET_TRIP_REPORT_QUERY = gql`
  query TripReport($tripId: String!) {
    tripReport(tripId: $tripId) {
      closingBalance
      totalExpenses
      totalTransactions
    }
  }
`;

export const useGetTripReport = (tripId: string): QueryResult<TripReportQuery, TripReportQueryVariables> =>
  useQueryErrorNotification<TripReportQuery, TripReportQueryVariables>(GET_TRIP_REPORT_QUERY, {
    variables: { tripId },
    fetchPolicy: 'network-only',
  });
