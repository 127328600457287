import { Button, Modal, Form, Row, Col, Select, message, Tag, InputNumber } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';

import 'src/screens/travel-schedule/new-shedule-modal/styles/addScheduleModal.less';
import { IRoute } from 'src/types/route.type';
import { INewTravelScheduleParams, ITravelSchedule, IUpdateTravelScheduleParams } from 'src/types/travel.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip } from 'src/types/trip.type';
import { onRouteFilter, onTripFilter } from 'src/utilities/helpers.utils';
import { useGetRouteGroupRoutes } from 'src/hooks/useGetRouteGroupRoutes';
import { UseGetTripsQueryParams, useGetTrips } from 'src/hooks/useGetTrips';
import { DATE_FORMATS } from 'src/utilities/date.utils';
import { usePostCreateTravelSchedule } from 'src/hooks/usePostCreateTravelSchedule';
import { usePostUpdateTravelSchedule } from 'src/hooks/usePostUpdateTravelSchedule';

export const ADD_SCEDULE_MODAL = 'ADD_SCEDULE_MODAL';
const { Option } = Select;

export interface AddTravelScheduleProps {
  show: boolean;
  onClose: () => void;
  initialSchedule?: ITravelSchedule;
  onComplete: (schedule: ITravelSchedule) => void;
}

export const CreateTravelSchedule: React.FC<AddTravelScheduleProps> = ({
  show,
  onClose,
  onComplete,
  initialSchedule,
}) => {
  const [form] = Form.useForm();
  const { activeBranch } = useBranch();
  const [{ data: routes, loading: loadingRoutes }, getRouteGroupRoutes] = useGetRouteGroupRoutes(
    initialSchedule?.trip?.routeGroupId,
  );
  const [tripQuery] = useState<UseGetTripsQueryParams>({
    branchId: activeBranch?.id,
    startDate: moment().format(DATE_FORMATS.YYYY_MM_DD),
    status: 'open_trips',
    offset: 100,
  });
  const [{ data: trips, loading }] = useGetTrips({ params: tripQuery });
  const [createTravelSchedule, { loading: createLoading }] = usePostCreateTravelSchedule();
  const [updateTravelSchedule, { loading: updateLoading }] = usePostUpdateTravelSchedule();

  const handleSave = async (payload: INewTravelScheduleParams | IUpdateTravelScheduleParams) => {
    let schedule: ITravelSchedule | null;
    if (initialSchedule) {
      schedule = await updateTravelSchedule(initialSchedule.id, payload);
    } else {
      schedule = await createTravelSchedule(payload);
    }

    if (schedule && onComplete) {
      message.success(`Schedule successfully ${initialSchedule ? 'updated' : 'created'}`);
      onComplete(schedule);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params: INewTravelScheduleParams = {
        ...values,
        branchId: activeBranch?.id,
      };
      handleSave(params);
    } catch (e) {
      message.error('Fill in all fields', 2);
    }
  };

  const routeOptions = routes.map((route: IRoute) => (
    <Option key={route.id} value={route.id}>
      {`${route.branch?.name}  → ${route.destination?.name}`}
    </Option>
  ));

  const tripOptions = trips?.items.map((trip: ITrip) => {
    const dateTime = moment(`${trip.date} ${trip.time}`);

    return (
      <Option key={trip.id} value={trip.id}>
        {`${trip.destination?.name}`} | {trip.vehicleClass?.name} |{' '}
        <Tag>{dateTime.format(DATE_FORMATS.DD_MM_YYYY__hh_mm_A)}</Tag>
      </Option>
    );
  });

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const onTripSearch = (input: string, options?: { value: number }) => {
    return onTripFilter(trips?.items ?? [], input, options);
  };

  const onRouteSearch = (input: string, option?: { value: number }) => {
    return onRouteFilter(routes, input, option);
  };

  const onTripSelected = async (id: number) => {
    const trip = trips?.items.find((t) => t.id === id);
    await getRouteGroupRoutes(trip?.routeGroupId!);
  };

  const isLoading = loading || createLoading || updateLoading;

  return (
    <Modal
      title={initialSchedule ? 'Update Schedule' : 'Add Schedule'}
      open={show}
      closable={!isLoading}
      maskClosable={isLoading}
      onCancel={handleCancel}
      data-testid={ADD_SCEDULE_MODAL}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={isLoading} onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      <Form
        autoComplete="on"
        form={form}
        layout="vertical"
        initialValues={
          initialSchedule
            ? { ...initialSchedule, travelFare: initialSchedule.travelFare + initialSchedule.serviceCharge }
            : undefined
        }
      >
        <Row gutter={{ xs: 24 }}>
          <Col span={24}>
            <Form.Item label="Trip" name="tripId" rules={[{ required: true, message: 'Please select a trip' }]}>
              <Select showSearch placeholder="Select a trip" filterOption={onTripSearch} onSelect={onTripSelected}>
                {tripOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Route" name="routeId" rules={[{ required: true, message: 'Please select a route' }]}>
              <Select
                showSearch
                placeholder="Select a route"
                filterOption={onRouteSearch}
                disabled={loadingRoutes}
                loading={loadingRoutes}
              >
                {routeOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Travel fare"
              name="travelFare"
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'Please enter valid travel fare', type: 'number' }]}
            >
              <InputNumber className="full-width" placeholder="Fare price" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
