import { Select, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDestination } from 'src/types/branch.type';
import { ITripConfiguration } from 'src/types/trip.type';
import { IVehicleClass } from 'src/types/vehicle.type';

const { Option } = Select;

export const tripConfigColumns: ColumnsType<ITripConfiguration> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    render: ({ name }: IDestination) => <span>{`${name.toUpperCase()}`}</span>,
    key: 'routeGroup',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    render: (time: string) => time,
    key: 'tim',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
    align: 'center',
  },
  {
    title: 'Vehicle Class',
    dataIndex: 'vehicleClass',
    key: 'vehicleClass',
    render: (vehicleClass: IVehicleClass) => vehicleClass.name,
  },
  {
    title: 'Days',
    dataIndex: 'days',
    render: (days: string[]) => days.map((day) => <Tag key={day}> {day} </Tag>),
    key: 'days',
  },
];

export const frequencyOptions = [...new Array(15)]?.map((_, index) => {
  const key = index + 1;
  return (
    <Option key={key} value={key}>
      {key}
    </Option>
  );
});
