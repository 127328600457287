import { Button, Modal, Form, Input, Row, Col, Alert, Select } from 'antd';
import React, { useEffect } from 'react';
import { useCreateVehicleClassConfig } from 'src/graphql/mutations/create-vehicle-class-config';
import { useUpdateVehicleClassConfig } from 'src/graphql/mutations/update-vehicle-class-config';
import { VehicleClassConfig } from 'src/graphql/queries/find-vehicle-class-configuration';
import { useFindVehicleClassesQuery } from 'src/graphql/queries/find-vehicle-classes';

import 'src/screens/vehicle-class/vehicle-class-modal/styles/vehicleClassModal.style.less';
import { currencySelector } from 'src/screens/vehicle-class/VehicleClassUtils';

export interface VehicleClassConfigProps {
  show: boolean;
  onClose: () => void;
  onActionComplete: () => void;
  defaultVehicleClassConfig?: VehicleClassConfig;
}

export const VehicleClassConfigModal: React.FC<VehicleClassConfigProps> = ({
  show,
  onClose,
  onActionComplete,
  defaultVehicleClassConfig,
}) => {
  const { data: vehicleClasses, loading: findVehicleClassesLoading } = useFindVehicleClassesQuery();
  const [createVehicleClassConfig, { loading: createVehicleClassConfigLoading, error: createError }] =
    useCreateVehicleClassConfig();
  const [updateVehicleClassConfig, { loading: updateVehicleClassConfigLoading, error: updateError }] =
    useUpdateVehicleClassConfig();
  const [form] = Form.useForm();

  const loading = createVehicleClassConfigLoading || updateVehicleClassConfigLoading || findVehicleClassesLoading;
  const error = createError || updateError;

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show]);

  useEffect(() => {
    if (defaultVehicleClassConfig) {
      form.resetFields();
      form.setFieldsValue({
        charge: defaultVehicleClassConfig.charge,
        vehicleClassId: defaultVehicleClassConfig.vehicleClass.id,
        currency: 'NGN',
      });
    }
  }, [defaultVehicleClassConfig]);

  const handleSave = async ({ charge, vehicleClassId }: { vehicleClassId: string; charge: string }) => {
    let result;
    if (defaultVehicleClassConfig) {
      result = await updateVehicleClassConfig({
        variables: { charge: parseFloat(charge), vehicleClassConfigId: defaultVehicleClassConfig.id },
      });
    } else {
      result = await createVehicleClassConfig({ variables: { charge: parseFloat(charge), vehicleClassId } });
    }

    if (result.data && onActionComplete) {
      onActionComplete();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={defaultVehicleClassConfig ? 'Edit Vehicle Class CONfig' : 'Add Vehicle Class Config'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error.message} type="error" className="error-alert" />}

      <Form autoComplete="on" layout="vertical" form={form} onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="vehicleClassId"
              label="Vehicle Class"
              rules={[{ required: true, message: 'please select a class' }]}
            >
              <Select>
                {vehicleClasses?.findVehicleClasses.map((vc) => (
                  <Select.Option value={vc.id} key={vc.id}>
                    {vc.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="charge" label="Charge" rules={[{ required: true, message: 'Please input the charge' }]}>
              <Input type="number" placeholder="Enter charge" addonBefore={currencySelector} size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
