import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  GetUserBranchesQuery,
  GetUserBranchesQueryVariables,
} from 'src/graphql/queries/__generated__/get-user-branches.generated';

const GET_USER_BRANCHES_QUERY = gql`
  query GetUserBranches($userId: Float!) {
    getUserBranches(userId: $userId) {
      id
      name
    }
  }
`;

export const useGetUserBranchesQuery = (
  userId: number,
): QueryResult<GetUserBranchesQuery, GetUserBranchesQueryVariables> =>
  useQueryErrorNotification<GetUserBranchesQuery, GetUserBranchesQueryVariables>(GET_USER_BRANCHES_QUERY, {
    variables: { userId },
  });
