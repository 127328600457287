import { LoadingView } from 'src/components/loading-view/LoadingView';
import { SeatSelector } from 'src/components/seat/seatSelector';
import { useGetTripSeatmapQuery } from 'src/graphql/queries/get-trip-seatMap';
import { ITrip } from 'src/types/trip.type';
import { generateSeatingGrid } from 'src/screens/trips/TripUtils';

import 'src/screens/trips/components/styles/tripSeatMap.styles.less';

export const TripSeatMap = ({ trip }: { trip: ITrip }) => {
  const { data, loading, error } = useGetTripSeatmapQuery(String(trip.id));

  if (!data || loading || error) return <LoadingView />;
  return (
    <div className="trip-seat-map-container">
      <div className="trip-seat-map-main">
        <SeatSelector seatMap={generateSeatingGrid(data)} />
      </div>
    </div>
  );
};
