import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';

export interface IUseTerminalAuthParams {
  branchId: number;
}

export const useTerminalAuth = () =>
  useUpdate<{ accessToken: string }, IUseTerminalAuthParams>(async (options) => {
    const { data } = await api.post<{ accessToken: string }>(resources.authTerminal, {
      ...options?.params,
      noTransform: true,
    });
    return data;
  });
