/* eslint-disable max-len */
import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const CalendarIcon = ({ rectFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <rect width="50" height="50" fill={rectFill ?? '#E4F6FA'} rx="4" />
      <path
        fill={pathFill ?? '#55BCD9'}
        d="M37.322 14.022h-5.625v-2.143a.269.269 0 00-.268-.268h-1.875a.269.269 0 00-.268.268v2.143h-8.571v-2.143a.269.269 0 00-.268-.268h-1.875a.269.269 0 00-.268.268v2.143h-5.625a1.07 1.07 0 00-1.072 1.072v22.232a1.07 1.07 0 001.072 1.071h24.643a1.07 1.07 0 001.071-1.071V15.093a1.07 1.07 0 00-1.071-1.071zm-1.34 21.964H14.018V23.263h21.964v12.723zm-21.964-15v-4.553h4.286v1.607c0 .147.12.268.268.268h1.875c.147 0 .268-.12.268-.268v-1.607h8.571v1.607c0 .147.12.268.268.268h1.875c.147 0 .268-.12.268-.268v-1.607h4.285v4.553H14.018z"
      />
    </BaseSVGIcons>
  );
};
