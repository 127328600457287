/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Button, Modal, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetTripSeatmapQuery } from 'src/graphql/queries/get-trip-seatMap';
import { IPassenger } from 'src/types/passanger.type';
import { generateSeatingGrid } from 'src/screens/trips/TripUtils';
import { CheackInSeatSelector } from 'src/components/seat/checkin-seatSelector';
import { useCheckInPassengerMutation } from 'src/graphql/mutations/checkin-passenger';
import { ReservationTicket } from 'src/graphql/queries/get-reservation-by-id';

export interface CheckinProps {
  show: boolean;
  onClose: () => void;
  passenger: ReservationTicket['passenger'];
}

export const CheckinModal: React.FC<CheckinProps> = ({ show, onClose, passenger }) => {
  const { data: tripData, loading, error } = useGetTripSeatmapQuery(String(passenger.tripId));
  const [seatId, setSeatId] = useState('');
  const [checkinPassenger, { loading: loadingCheckinPassenger, data: checkinPassengerData }] =
    useCheckInPassengerMutation();

  const onCheckinPassenger = async () => {
    await checkinPassenger({
      variables: {
        passengerId: parseInt(passenger.id, 10),
        seatId: parseInt(seatId, 10),
      },
    });
  };

  useEffect(() => {
    if (checkinPassengerData) {
      onClose();
      window.location.reload();
    }
  }, [checkinPassengerData]);

  return (
    <Modal
      title="Check in"
      open={show}
      onCancel={() => onClose()}
      footer={[
        <Button key="back" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={loadingCheckinPassenger}
          disabled={seatId.length === 0 || loadingCheckinPassenger}
          type="primary"
          onClick={() => onCheckinPassenger()}
        >
          Submit
        </Button>,
      ]}
    >
      {loading || !tripData || error ? (
        <div className="reservation-container">
          <Skeleton active />
        </div>
      ) : (
        <div className="trip-seat-map-container check-in-modal">
          <div className="trip-seat-map-main">
            <CheackInSeatSelector
              seatMap={generateSeatingGrid(tripData)}
              onChange={(data) => setSeatId(data)}
              selected={seatId}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
