import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

export const useQueryErrorNotification = <Q = any, V extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<Q, V>,
  options?: QueryHookOptions<Q, V> | undefined,
) => {
  const { errorMessage } = useMessage();

  return useQuery<Q, V>(query, {
    ...options,
    onError: (error) => errorMessage({ content: `Error completing request: ${error.message}` }),
  });
};
