import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { ROUTE_FARE_OVERRIDE_FRAGMENT } from 'src/graphql/fragments/route-fare-override.fragment';
import {
  CreateRouteFareOverrideMutation,
  CreateRouteFareOverrideMutationVariables,
} from 'src/graphql/mutations/__generated__/create-route-fare-override.generated';

const CREATE_ROUTE_FARE_OVERRIDE_QUERY = gql`
  mutation CreateRouteFareOverride($input: CreateRouteFareOverrideInput!) {
    createRouteFareOverride(input: $input) {
      ...RouteFareOverrideFragment
    }
  }

  ${ROUTE_FARE_OVERRIDE_FRAGMENT}
`;

export const useCreateRouteFareOverrideMutation = (): MutationTuple<
  CreateRouteFareOverrideMutation,
  CreateRouteFareOverrideMutationVariables
> =>
  useMutationErrorNotification<CreateRouteFareOverrideMutation, CreateRouteFareOverrideMutationVariables>(
    CREATE_ROUTE_FARE_OVERRIDE_QUERY,
  );
