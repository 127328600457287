import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import 'src/components/reservation-summary/styles/reservationSummary.styles.less';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { MoneyText } from 'src/components/topography/MoneyText';

export const ReservationSummary = () => {
  const navigate = useNavigate();
  const { route, searchParams, schedule, getPassengerCount } = useReservation();
  const departureDate = moment(searchParams?.date).format('ddd Do MMM, YYYY');

  const fare = (schedule?.travelFare ?? 0) + (schedule?.serviceCharge ?? 0);

  return (
    <div className="schedule">
      <div className="details">
        <p>Trip summary</p>
        <h1>
          <ArrowRightOutlined />
          {route?.branch?.name}
        </h1>
        <h1>
          <ArrowLeftOutlined />
          {`${route?.destination?.name}(${route?.destination?.code})`}
        </h1>
      </div>
      <div className="details">
        <p>Departure</p>
        <h1>{departureDate}</h1>
        <br />
      </div>
      <div className="details">
        <p>Fare</p>
        <h1>
          <MoneyText value={fare} />
        </h1>
        <h1>{getPassengerCount()}X Passengers</h1>
      </div>
      <div className="details">
        <Button className="button" type="text" onClick={() => navigate('/ticketing')}>
          CHANGE
        </Button>
      </div>
    </div>
  );
};
