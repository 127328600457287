import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { ITripConfiguration } from 'src/types/trip.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';
import { ICreateTripConfigParams } from 'src/hooks/usePostCreateTripConfig';

export type IUpdateTripConfigParams = Omit<ICreateTripConfigParams, 'branchId'> & { configId: number };

export const useUpdateTripConfig = () =>
  useUpdate<ITripConfiguration, IUpdateTripConfigParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<ITripConfiguration>(
      `${resources.tripConfigurationUrl}/${options?.params?.configId}`,
      payload,
    );
    return camelcaseKeys(data, { deep: true });
  });
