import { useCallback, useEffect, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { ITripHandOver } from 'src/types/trip.type';

export const useGetTripHandovers = (
  id: number,
): [
  { data: ITripHandOver[] | undefined; loading: boolean; error: any },
  (id: number) => Promise<ITripHandOver[] | undefined>,
] => {
  const [data, setData] = useState<ITripHandOver[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (tripId: number) => {
      let result;
      setLoading(true);
      try {
        result = await TripService.getTripHandOvers(tripId);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return result;
    },
    [setData, setError],
  );

  useEffect(() => {
    const getTripHandovers = async () => {
      fetch(id);
    };
    getTripHandovers();
  }, [fetch, id]);

  return [{ data, loading, error }, fetch];
};
