import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CheckInPassengerMutation,
  CheckInPassengerMutationVariables,
} from 'src/graphql/mutations/__generated__/checkin-passenger.generated';

const CHECKIN_PASSENGER_MUTATION = gql`
  mutation CheckInPassenger($passengerId: Float!, $seatId: Float!) {
    checkInPassenger(passengerId: $passengerId, seatId: $seatId) {
      tripId
      travelScheduleId
      seatId
      organizationId
      routeId
      phone
      nextKinPhone
      nextKinLastName
      nextKinFirstName
      id
      isPrimaryPassenger
      gender
      fullName
      email
      createdAt
      checkInTime
      branchId
    }
  }
`;

export const useCheckInPassengerMutation = (): MutationTuple<
  CheckInPassengerMutation,
  CheckInPassengerMutationVariables
> =>
  useMutationErrorNotification<CheckInPassengerMutation, CheckInPassengerMutationVariables>(CHECKIN_PASSENGER_MUTATION);
