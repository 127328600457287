import { useCallback, useEffect, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { IWaybill } from 'src/types/waybill.type';

export const useGetTripWaybills = (
  id: number,
): [{ data: IWaybill[] | undefined; loading: boolean; error: any }, (id: number) => Promise<void>] => {
  const [data, setData] = useState<IWaybill[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (tripId: number) => {
      setLoading(true);
      try {
        const result = await TripService.getTripWaybills(tripId);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError],
  );

  useEffect(() => {
    const getTripWaybills = async () => {
      fetch(id);
    };
    getTripWaybills();
  }, [fetch, id]);

  return [{ data, loading, error }, fetch];
};
