import { IVehicle, IVehicleSearchQuery } from 'src/types/vehicle.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { PaginatedResponse, api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { resources } from 'src/services/api/resources';

export const useGetVehicles = ({ params }: UseGetType<IVehicleSearchQuery>) =>
  useGet<PaginatedResponse<IVehicle>, IVehicleSearchQuery>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<IVehicle>>(resources.vehicleUrl, { params: payload });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
