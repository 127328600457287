import { gql } from '@apollo/client';

export const ROUTE_FARE_OVERRIDE_FRAGMENT = gql`
  fragment RouteFareOverrideFragment on RouteFareOverride {
    id
    fare
    vehicleClass {
      id
      name
    }
    currency
    route {
      id
      destination {
        name
      }
    }
  }
`;
