import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

export const useMutationErrorNotification = <Q = any, V extends OperationVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<Q, V>,
  options?: MutationHookOptions<Q, V> | undefined,
) => {
  const { errorMessage } = useMessage();

  return useMutation<Q, V>(mutation, {
    ...options,
    onError: (e) => {
      e.graphQLErrors.map(
        (error) => errorMessage({ content: `Error completing request: ${error.message}` }),
      );
    },
  });
};
