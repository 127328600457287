import { useCallback, useState } from 'react';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

import { TripService } from 'src/services/trip.service';
import { INewHandoverParams, ITripHandOver } from 'src/types/trip.type';

export const usePostCreateHandover = (): [
  (id: number, params: INewHandoverParams[]) => Promise<ITripHandOver[] | null>,
  {
    data: ITripHandOver[] | undefined;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<ITripHandOver[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { errorMessage } = useMessage();

  const createHandOver = useCallback(
    async (id: number, payload: INewHandoverParams[]) => {
      let result = null;
      setLoading(true);
      try {
        result = await TripService.addTripHandOvers(id, payload);
        setData(result);
        return result;
      } catch (err: any) {
        setError(err);
        errorMessage({ content: 'Error creating handover record' });
      }
      setLoading(false);
      return null;
    },
    [setData, setError, setLoading],
  );

  return [createHandOver, { data, loading, error }];
};
