import { useEffect, useState } from 'react';
import { Button, Table, Card } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { RouteFareModal } from 'src/screens/routes-fare/route-fares-modal/RouteFaresModal';
import { usePage } from 'src/components/page/Page';
import { IRouteFare } from 'src/types/route.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { useGetRouteFares } from 'src/hooks/useGetRouteFares';
import { routeFareColumns } from 'src/screens/routes-fare/RouteFareUtils';

export const RoutesFareManagement = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const [pageSize, setPageSize] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const [{ data, loading }, fetchRouteFare] = useGetRouteFares({
    params: { branchId: activeBranch?.id!, page, offset: pageSize },
  });

  const [showRouteFareModal, setShowRouteFareModal] = useState<boolean>(false);
  const [selectedRouteFare, setSelectedRouteFare] = useState<IRouteFare>();

  useEffect(() => {
    setTitle('Routes');
    setSubTitle('Route Fares');
  }, []);

  useEffect(() => {
    const reaload = async () => {
      if (loading) return;
      await fetchRouteFare({ params: { branchId: activeBranch?.id!, page, offset: pageSize } });
    };
    reaload();
  }, [page, pageSize]);

  const closeRouteFareModal = () => {
    setSelectedRouteFare(undefined);
    setShowRouteFareModal(false);
  };

  const showEditRouteFareModal = (id: number) => {
    const routeFare = data?.items.find((rf) => rf.id === id);
    setSelectedRouteFare(routeFare);
    setShowRouteFareModal(true);
  };

  const onRouteFareActionComplete = async () => {
    await fetchRouteFare({ params: { branchId: activeBranch?.id!, page, offset: pageSize } });
    closeRouteFareModal();
  };

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setPage(currenctPage);
    setPageSize(newPageSize);
  };

  const columns = [...routeFareColumns];
  columns.push({
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (id: number) => {
      return <EditOutlined onClick={() => showEditRouteFareModal(id)} style={{ fontSize: '16px', color: '#08c' }} />;
    },
    fixed: 'right',
    width: 100,
  });

  return (
    <div className="routes-fare-page-container">
      <Card
        bordered={false}
        extra={[
          <Button
            key="add-route-fare-key"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowRouteFareModal(true)}
          >
            Add New
          </Button>,
        ]}
      >
        <Table
          loading={loading}
          rowKey={(routeFare: IRouteFare) => routeFare.id}
          columns={columns}
          dataSource={data?.items}
          pagination={{
            onChange: onPageChange,
            pageSize,
            current: page,
            showSizeChanger: true,
            total: data?.meta.totalItems,
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
      {showRouteFareModal && (
        <RouteFareModal
          show={showRouteFareModal}
          onClose={closeRouteFareModal}
          onRouteFareChange={onRouteFareActionComplete}
          defaultRouteFare={selectedRouteFare}
        />
      )}
    </div>
  );
};
