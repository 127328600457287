import { Button, Table, Card, message, Popconfirm, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';

import 'src/screens/vehicles/styles/busManagement.styles.less';
import { VehicleModal } from 'src/screens/vehicles/vehicle-modal/VehicleModal';
import { usePage } from 'src/components/page/Page';
import { IVehicle, IVehicleSearchQuery } from 'src/types/vehicle.type';
import { SearchBar } from 'src/components/search-bar/SearchBar';
import { useGetVehicles } from 'src/hooks/useGetVehicles';
import { vehicleColumns } from 'src/screens/vehicles/VehicleUtils';
import { useDeleteVehicle } from 'src/hooks/useDeleteVehicle';

export const VehicleScreen = () => {
  const { setSubTitle, setTitle } = usePage();
  const [filterQuery, setFilterQuery] = useState<IVehicleSearchQuery>({
    page: 1,
    offset: 100,
  });
  const [{ data, loading }, fetchVehicles] = useGetVehicles({ params: filterQuery });

  const [showVehicleModal, setShowVehicleModal] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [searchField, setSearchField] = useState<string>('');
  const [filteredVehicles, setFilteredVehicles] = useState<IVehicle[] | undefined>(data?.items);

  const [deleteVehicle] = useDeleteVehicle();

  useEffect(() => {
    setTitle('Vehicle Management');
    setSubTitle('Vehicles');
  }, []);

  const closeVehicleModal = () => {
    setSelectedVehicle(undefined);
    setShowVehicleModal(false);
  };

  const showEditVehicleModal = (id: number) => {
    const vehicle = data?.items.find((v) => v.id === id);
    setSelectedVehicle(vehicle);
    setShowVehicleModal(true);
  };

  const onVehicleActionComplete = async () => {
    setSelectedVehicle(undefined);
    setShowVehicleModal(false);
    message.success('vehicle has been updated successfully!!', 2.5);

    await fetchVehicles({ params: filterQuery });
  };

  const onConfirmDeleteVehicle = async (id: number) => {
    const deleted = await deleteVehicle({ params: { id } });

    if (deleted) {
      message.success('Vehicle successfully deleted');
      await fetchVehicles({ params: filterQuery });
    }
  };

  const onPageChange = (currentPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currentPage, offset: newPageSize });
  };

  const columns: ColumnsType<IVehicle> = [...vehicleColumns];
  columns.push({
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => {
      return (
        <Space split="|">
          <Button onClick={() => showEditVehicleModal(id)} className="more-btn" href="#" icon={<EditOutlined />} />

          <Popconfirm
            title="Are you sure you want to delete this vehicle?"
            onConfirm={() => onConfirmDeleteVehicle(id)}
            placement="topRight"
          >
            <Button className="more-btn" href="#" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      );
    },
    fixed: 'right',
    width: 100,
  });

  const handleVehicleSearch = (e: string) => {
    setSearchField(e);

    if (searchField !== '') {
      const newVehicles = data!.items.filter((vehicle) => {
        return vehicle?.registeredNumber.toLowerCase().includes(searchField.toLowerCase());
      });

      setFilteredVehicles(newVehicles);
    } else {
      setFilteredVehicles(undefined);
    }
  };

  return (
    <div className="buses-page-container">
      <Card
        className="card-container"
        bordered={false}
        extra={[
          <div className="extra-container">
            <SearchBar
              className="vehicle-search"
              placeHolder="Search by Vehicle Number"
              onSearch={(e: string) => handleVehicleSearch(e)}
            />
            <Button key="add-new-bus" type="primary" icon={<PlusOutlined />} onClick={() => setShowVehicleModal(true)}>
              Add New
            </Button>
          </div>,
        ]}
      >
        <Table
          rowKey={(v: IVehicle) => v.id}
          columns={columns}
          dataSource={filteredVehicles || data?.items}
          size="small"
          bordered
          loading={loading}
          pagination={{
            pageSize: data?.meta.offset,
            current: data?.meta.page,
            onChange: onPageChange,
            showSizeChanger: true,
            total: data?.meta.totalItems,
            defaultPageSize: 100,
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
      {showVehicleModal && (
        <VehicleModal
          defaultVehicle={selectedVehicle}
          show={showVehicleModal}
          onClose={closeVehicleModal}
          onComplete={onVehicleActionComplete}
        />
      )}
    </div>
  );
};
