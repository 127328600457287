import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';
import { IRouteFare } from 'src/types/route.type';

export interface ICreateRouteFareParams {
  routeId: number;
  branchId: number;
  fare: number;
  currency: string;
}

export const useCreateRouteFare = () =>
  useUpdate<IRouteFare, ICreateRouteFareParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IRouteFare>(resources.routeFaresUrl, payload);
    return camelcaseKeys(data, { deep: true });
  });
