import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IDestination } from 'src/types/branch.type';
import { IReservations } from 'src/types/ticket.type';
import { ITrip } from 'src/types/trip.type';

export const reservationColumns: ColumnsType<IReservations> = [
  {
    title: 'Reference',
    dataIndex: 'reference',
    key: 'reference',
    render: (reference: string) => reference,
  },
  {
    title: 'Lead Passenger',
    dataIndex: 'contactName',
    key: 'contactName',
    render: (leadPassenger: string) => leadPassenger,
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (destination: IDestination) => destination.name,
  },
  {
    title: 'Trip',
    dataIndex: 'trip',
    key: 'trip',
    render: (trip: ITrip) => (
      <Link to={`/trips/${trip.id}`}>
        {`${trip.destination?.code} | ${moment(`${trip.date} ${trip.time}`).format('hh:mm A')}`}
      </Link>
    ),
  },
];
