import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import 'src/screens/trips/components/styles/trip.styles.less';

export const TripOptions = ({
  tripId,
  onEdit,
  editable = true,
}: {
  tripId: number;
  onEdit: () => void;
  editable?: boolean;
}) => {
  return (
    <div>
      <Space>
        <Link to={`/trips/${tripId}`}>
          <EyeOutlined style={{ color: '#1890FF' }} />
        </Link>
        {!editable && (
          <button onClick={onEdit} type="button" className="trip-edit-button">
            <EditOutlined style={{ color: '#1890FF' }} />
          </button>
        )}
      </Space>
    </div>
  );
};
