import { ITrip } from 'src/types/trip.type';
import { IBranch } from 'src/types/branch.type';
import { IUser } from 'src/types/user.type';
import { PaginatedInterface } from 'src/types/type';
import { UseCreateExpenseParams } from 'src/hooks/usePostCreateExpense';

export enum EXPENSE_NAME {
  WAYBILL_COMMISSION = 'Waybill Commission',
  FUEL_COMMISSION = 'Fuel Commission',
}
export interface IExpense {
  id: number;
  name: string;
  branchId: number;
  branch?: IBranch;
  loggedById: number;
  loggedBy: IUser;
  amount: number;
  organizationId: number;
  description?: string;
  tripId?: number;
  trip?: ITrip;
  createdAt: Date | string;
  updatedAt: Date | string;
  paymentMethod: string;
}

export interface IDeleteExpenseResponse {
  message: string;
}

export interface IExpenseSearchQuery extends PaginatedInterface {
  tripId?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  branchId: number;
  paymentMethod?: string;
  expenseType?: string;
}

export interface IExpenseSummary {
  totalTrips: number;
  totalMiscellaneous: number;
  totalExpenses: number;
}

export interface IUpdateExpenseParams extends Omit<UseCreateExpenseParams, 'branchId'> {}
