import camelcaseKeys from 'camelcase-keys';

import { INewPassengerParams, IPassenger } from 'src/types/passanger.type';
import { ITicket } from 'src/types/ticket.type';
import { ITransaction, PAYMENT_METHODS } from 'src/types/transaction.type';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IWaybill, ICreateWayBillParams } from 'src/types/waybill.type';

export class ReservationService {
  public static async createReservation(
    payload: INewPassengerParams[],
  ): Promise<{ tickets: ITicket[]; transaction: ITransaction; passengers: IPassenger[] }> {
    const { data } = await api.post(resources.reservation, { passengers: payload, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async createWaybill(
    payload: ICreateWayBillParams,
  ): Promise<{ waybill: IWaybill; transaction: ITransaction }> {
    const { data } = await api.post(resources.waybillReservationUrl, payload);
    return camelcaseKeys(data, { deep: true });
  }

  public static async completeWaybillReservation(payload: {
    transactionId: number;
    waybillId: number;
    paymentMethod: PAYMENT_METHODS;
  }): Promise<{ waybill: IWaybill; transaction: ITransaction }> {
    const { data } = await api.post(resources.waybillReservationComplete, payload);

    return camelcaseKeys(data, { deep: true });
  }
}
