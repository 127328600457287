import React, { useState } from 'react';
import { Descriptions, Input, Table, Typography } from 'antd';
import logo from 'src/icons/Logo.png';
import 'src/components/closing-document/styles/closingDocument.styles.less';
import { ITrip, ITripHandOver } from 'src/types/trip.type';
import { MoneyText } from 'src/components/topography/MoneyText';
import { EXPENSE_NAME, IExpense } from 'src/types/expense.type';
import {
  AllDestination,
  AllWaybills,
  destinationColumn,
  groupHandoversByDestination,
  groupPassengersByDestination,
  groupWaybillsByDestination,
  handoverColumn,
  tripExpenceColumn,
  waybillColumn,
} from 'src/screens/trips/TripUtils';
import { IPassenger } from 'src/types/passanger.type';
import { IWaybill } from 'src/types/waybill.type';
import moment from 'moment';

interface ClosingPaperProps {
  passengers: IPassenger[];
  tripdata: ITrip;
  expenses: IExpense[];
  waybills: IWaybill[];
  handovers: ITripHandOver[];
}

export const ClosingPaper: React.FC<ClosingPaperProps> = (props) => {
  const { tripdata, passengers, expenses, waybills, handovers } = props;
  const date = `${tripdata?.date}`;

  const [totalSeats, setTotalSeats] = useState<number>(0);

  const totalSeatSetter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setTotalSeats(+inputValue);
  };

  const allDestinations = groupPassengersByDestination(passengers);
  const allWaybills = groupWaybillsByDestination(waybills);
  const allHandovers = groupHandoversByDestination(handovers);

  const allDestinationSummary = () => {
    const totalDestination = allDestinations?.reduce(
      (sum: number, destinations: AllDestination) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allWaybillsDestinationSummary = () => {
    const totalDestination = allWaybills?.reduce(
      (sum: number, destinations: AllWaybills) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allHandOverSummary = () => {
    const totalDestination = allHandovers?.reduce(
      (sum: number, destinations: AllDestination) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allExpenseSummary = () => {
    const totalExpenses = expenses?.reduce((sum: number, expense: IExpense) => {
      return expense.name === EXPENSE_NAME.WAYBILL_COMMISSION || expense.name === EXPENSE_NAME.FUEL_COMMISSION
        ? sum + expense.amount
        : sum;
    }, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalExpenses!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const filteredExpense = expenses.filter(
    (expense: IExpense) =>
      expense.name === EXPENSE_NAME.FUEL_COMMISSION || expense.name === EXPENSE_NAME.WAYBILL_COMMISSION,
  );

  return (
    <div className="document-container">
      <div className="logo-container">
        <img src={logo} alt="company logo" />
        <Typography.Title level={5}>{moment(date).format('Do MMMM YYYY').toUpperCase()}</Typography.Title>
      </div>
      <div style={{ fontWeight: 'bold', marginBottom: '2.5rem', paddingLeft: ' 0.5rem' }}>
        <p>{`Branch: ${tripdata.branch?.name.toUpperCase()} `}</p>
        <p>{`Destination: ${tripdata.destination?.name.toUpperCase()} `}</p>
        <p>{`Vehicle No: ${tripdata.vehicle?.vehicleNumber.toUpperCase() || 'Unassigned'} `}</p>
        <p>{`Driver Name: ${tripdata.driver?.lastName.toUpperCase() || 'Unassigned'}`}</p>
        <Descriptions style={{ marginTop: '2rem' }}>
          <Descriptions.Item label="Total Seats Sold" style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              placeholder="Value"
              type="number"
              style={{ width: 70, display: 'block' }}
              onChange={(e) => totalSeatSetter(e)}
              value={totalSeats}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>

      <Table
        rowKey="id"
        columns={destinationColumn}
        dataSource={allDestinations}
        bordered
        size="small"
        pagination={false}
        summary={allDestinationSummary}
        title={() => 'Passengers Summary:'}
        style={{ width: '100%' }}
      />

      <Table
        rowKey="id"
        columns={waybillColumn}
        dataSource={allWaybills}
        size="small"
        pagination={false}
        summary={allWaybillsDestinationSummary}
        title={() => 'Waybill / Loads Summaries'}
        style={{ width: '100%' }}
      />

      <Table
        rowKey="id"
        columns={handoverColumn}
        dataSource={allHandovers}
        size="small"
        pagination={false}
        summary={allHandOverSummary}
        title={() => 'Hand-Over Summaries'}
        style={{ width: '100%' }}
      />

      <Table
        rowKey="id"
        columns={tripExpenceColumn}
        dataSource={filteredExpense}
        size="small"
        pagination={false}
        summary={allExpenseSummary}
        title={() => 'Expense Summaries'}
        style={{ width: '100%' }}
      />
    </div>
  );
};
