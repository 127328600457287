import camelcaseKeys from 'camelcase-keys';
import { UseGetType, useGet } from 'src/hooks//shared/useGet';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { ITripConfiguration } from 'src/types/trip.type';

export type UseGetConfigParams = {
  branchId: number;
  page?: number;
};

export const useGetTripConfigs = ({ params }: UseGetType<UseGetConfigParams>) =>
  useGet<PaginatedResponse<ITripConfiguration>, UseGetConfigParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<ITripConfiguration>>(resources.tripConfigurationUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
