import { Card } from 'antd';
import Moment from 'moment';
import { ReservationTransaction } from 'src/graphql/queries/get-reservation-by-id';
import { PAYMENT_METHODS, PAYMENT_METHOD_LABELS } from 'src/types/transaction.type';
import { getCurrencyText } from 'src/utilities/currency';

interface IProps {
  data: ReservationTransaction;
}

export const PaymentDetails = ({ data }: IProps) => {
  const dateString = data?.processedAt;
  const dateObject = Moment.utc(dateString);

  const formattedDate = dateObject.format('MMMM D, YYYY h:mm:ss a');

  return (
    <div className="reservation-payment-details">
      <Card title="Payment details" style={{ width: '100%' }}>
        <div className="reservation-payment-card-content">
          <div className="payment-box-shadow">
            <h2>Amount</h2>
            <p>{getCurrencyText(data.amount + data.serviceCharge)}</p>
          </div>
          <div className="payment-box-shadow">
            <h2>Payment Method </h2>
            <span>
              <p>{PAYMENT_METHOD_LABELS[data.paymentMethod as PAYMENT_METHODS]}</p>
            </span>
          </div>
          <div className="payment-box-shadow">
            <h2>Date</h2>
            <p>{data?.processedAt !== null ? formattedDate : data?.processedAt}</p>
          </div>
          <div>
            <h2>Processed By </h2>
            <p>
              {data?.ticketer?.firstName} {data?.ticketer?.lastName}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};
