import { Layout, Menu, Card, Typography, Avatar, Dropdown } from 'antd';
import {
  DashboardOutlined,
  HighlightOutlined,
  UserOutlined,
  CarOutlined,
  DragOutlined,
  DollarOutlined,
  CheckOutlined,
  CaretDownOutlined,
  AuditOutlined,
  BookOutlined,
  SolutionOutlined,
  DropboxOutlined,
  SendOutlined,
  AimOutlined,
} from '@ant-design/icons';
import { ReactNode, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';

import 'src/components/admin-layout/styles/adminLayout.styles.less';
import { styles } from 'src/components/admin-layout/styles/layoutStyles';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { clearTerminalToken } from 'src/utilities/storage.utils';

const { Sider, Content } = Layout;
const { Item, SubMenu } = Menu;
const { Text } = Typography;

export interface AdminLayoutProps {
  children?: ReactNode;
}

export const AccountSwitcher = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { user } = useAuth();
  const { activeBranch, branches, setActiveBranchId } = useBranch();

  const onBranchSwitch = (item: any) => {
    clearTerminalToken();
    setActiveBranchId(parseInt(item.key, 10));
  };

  const menu = (
    <Menu
      selectable
      selectedKeys={[`${activeBranch?.id}`]}
      onSelect={onBranchSwitch}
      items={
        branches?.map((branch) => ({
          key: branch.id,
          label: branch.name,
          icon: activeBranch?.id === branch.id && <CheckOutlined />,
        })) ?? []
      }
    />
  );

  return (
    <div className="card-div">
      <Dropdown overlay={menu} trigger={['click']}>
        {isCollapsed ? (
          <Avatar size="large" style={styles.avatar}>
            {`${user?.firstName.charAt(0)}.${user?.lastName.charAt(0)}`}
          </Avatar>
        ) : (
          <Card size="small" className="user-card">
            <div className="account-info-display">
              <Avatar size="large" style={styles.avatar}>
                {`${user?.firstName.charAt(0)}.${user?.lastName.charAt(0)}`}
              </Avatar>
              <div className="name-display">
                <Text strong>OIS</Text>
                <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
                <Text type="secondary">{activeBranch?.name}</Text>
              </div>
              <CaretDownOutlined />
            </div>
          </Card>
        )}
      </Dropdown>
    </div>
  );
};

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { isAdmin } = useAuth();

  return (
    <Layout className="main-container">
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={(value) => setIsCollapsed(value)}
        className="sider-box"
        width={267}
      >
        <AccountSwitcher isCollapsed={isCollapsed} />
        <Menu mode="inline" className="sider-menu">
          <Item key="dashboard" icon={<DashboardOutlined />}>
            <Link className="sidebar-links" to="/dashboard">
              Dashboard
            </Link>
          </Item>
          <Item className="sidebar-link-item" icon={<AuditOutlined />}>
            <Link className="sidebar-links" to="/ticketing">
              E-Ticketing
            </Link>
          </Item>
          <Item className="sidebar-link-item" icon={<BookOutlined />}>
            <Link className="sidebar-links" to="/reservations">
              Reservations
            </Link>
          </Item>
          <Item className="sidebar-link-item" icon={<SolutionOutlined />}>
            <Link className="sidebar-links" to="/passengers">
              Passengers
            </Link>
          </Item>
          <Item className="sidebar-link-item" icon={<DropboxOutlined />}>
            <Link className="sidebar-links" to="/waybills">
              Waybills
            </Link>
          </Item>
          <Item key="users" icon={<UserOutlined />} className="sidebar-link-item">
            <Link className="sidebar-links" to="/users">
              Users
            </Link>
          </Item>
          {isAdmin && (
            <Item key="destinations" icon={<AimOutlined />} className="sidebar-link-item">
              <Link className="sidebar-links" to="/destinations">
                Destinations
              </Link>
            </Item>
          )}
          <SubMenu key="trips" icon={<SendOutlined />} title="Trip management">
            <Item key="trips-index" className="sidebar-link-item">
              <Link className="sidebar-links" to="/trips">
                View Trips
              </Link>
            </Item>
            <Item key="trip-configuration" className="sidebar-link-item">
              <Link className="sidebar-links" to="/trips/configuration">
                Trip Configuration
              </Link>
            </Item>
          </SubMenu>
          <SubMenu key="finance" icon={<DollarOutlined />} title="Finances">
            <Item key="transactions" className="sidebar-link-item">
              <Link className="sidebar-links" to="/transactions">
                Transactions
              </Link>
            </Item>
            <Item key="expenses" className="sidebar-link-item">
              <Link className="sidebar-links" to="/expenses">
                Expenses
              </Link>
            </Item>
          </SubMenu>
          <SubMenu key="vehicles" icon={<CarOutlined />} title="Vehicle Management">
            <Item className="sidebar-link-item">
              <Link className="sidebar-links" to="/vehicles">
                Vehicles
              </Link>
            </Item>
            <Item className="sidebar-link-item">
              <Link className="sidebar-links" to="/vehicles/class">
                Vehicle class
              </Link>
            </Item>
            <Item className="sidebar-link-item">
              <Link className="sidebar-links" to="/vehicles/class-configuration">
                Class Configuration
              </Link>
            </Item>
          </SubMenu>
          <SubMenu key="routes" icon={<DragOutlined />} title="Routes">
            <Item key="routes-index" className="sidebar-link-item">
              <Link className="sidebar-links" to="/routes">
                Manage routes
              </Link>
            </Item>
            <Item key="routes-fare">
              <Link className="sidebar-links" to="/routes/fare">
                Route fares
              </Link>
            </Item>
            <Item key="fare-overrides">
              <Link className="sidebar-links" to="/routes/fare-overrides">
                Fare Overrides
              </Link>
            </Item>
            <Item key="routes-group">
              <Link className="sidebar-links" to="/routes/groups">
                Route Group
              </Link>
            </Item>
          </SubMenu>
          <SubMenu key="travel" icon={<HighlightOutlined />} title="Travel">
            <Item key="travel-schedule" className="sidebar-link-item">
              <Link className="sidebar-links" to="/travel/schedule">
                Travel Schedule
              </Link>
            </Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Content className="content-box">
          <div className="outlet">
            {children ?? null}
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
