import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from 'src/graphql/mutations/__generated__/update-user.generated';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!, $userId: ID!) {
    updateUser(input: $input, userId: $userId) {
      roles
      permissions
      firstName
      lastName
      phone
    }
  }
`;

export const useUpdateUser = (): MutationTuple<UpdateUserMutation, UpdateUserMutationVariables> =>
  useMutationErrorNotification<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER);
