import { Button, Modal, Form, Input, Row, Col, Alert, Select } from 'antd';
import React from 'react';

import 'src/screens/routes-fare/route-fares-modal/styles/routeFaresModal.style.less';
import { IRoute, IRouteFare } from 'src/types/route.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ICreateRouteFareParams, useCreateRouteFare } from 'src/hooks/useCreateRouteFare';
import { IUpdateRouteFareParams, useUpdateRouteFare } from 'src/hooks/useUpdateRouteFare';
import { useGetRoutes } from 'src/hooks/useGetRoutes';

const { Option } = Select;

export interface RouteFaresProps {
  show: boolean;
  onClose: () => void;
  onRouteFareChange: (routeFare: IRouteFare) => void;
  defaultRouteFare?: IRouteFare;
}

export const RouteFareModal: React.FC<RouteFaresProps> = ({ show, onClose, onRouteFareChange, defaultRouteFare }) => {
  const { activeBranch } = useBranch();
  const [{ data: routes }] = useGetRoutes();
  const [createRouteFare, { loading: createRouteFareLoading, error: createError }] = useCreateRouteFare();
  const [updateRouteFare, { loading: updateRouteFareLoading, error: updateError }] = useUpdateRouteFare();
  const [form] = Form.useForm();

  const loading = createRouteFareLoading || updateRouteFareLoading;
  const error = createError || updateError;

  const handleSave = async (values: any) => {
    let result;
    if (defaultRouteFare) {
      const params: IUpdateRouteFareParams = { ...values, fare: Number(values.fare), routeFareId: defaultRouteFare.id };
      result = await updateRouteFare({ params });
    } else {
      const params: ICreateRouteFareParams = { ...values, branchId: activeBranch?.id!, fare: Number(values.fare) };
      result = await createRouteFare({ params });
    }

    if (result && onRouteFareChange) {
      onRouteFareChange(result);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const routeOptions = routes?.map((route: IRoute) => (
    <Option key={route.id} value={route.id}>
      {`${route.branch?.name}  → ${route.destination?.name}`}
    </Option>
  ));

  return (
    <Modal
      title={defaultRouteFare ? 'Edit Route Fare' : 'Add Route Fare'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form autoComplete="on" form={form} initialValues={defaultRouteFare} onFinish={handleSave}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="routeId" rules={[{ required: true, message: 'Please select a route' }]}>
              <Select showSearch placeholder="Choose a route">
                {routeOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="fare" rules={[{ required: true, message: 'Enter fare' }]}>
              <Input placeholder="Amount" type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="currency" rules={[{ required: true, message: 'please select currency' }]}>
              <Select showSearch placeholder="Currency">
                <Option value="NGN">NGN</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} />
        </Row>
      </Form>
    </Modal>
  );
};
