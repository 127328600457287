import { Space, Tag } from 'antd';
import { RoleColorMapping, RoleNameMapping, USER_ROLE } from 'src/types/user.type';

export const getRoleTags = (roles: USER_ROLE[]) => {
  return (
    <Space size={10} wrap>
      {roles.map((r) => (
        <Tag color={RoleColorMapping[r]}>{RoleNameMapping[r]}</Tag>
      ))}
    </Space>
  );
};
