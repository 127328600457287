export const USD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const NGN = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'NGN',
});

export const getCurrencyText = (value: number, currency = NGN) => {
  return currency.format(value);
};
