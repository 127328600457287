import { api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IVehicle } from 'src/types/vehicle.type';

interface UseDeleteVehicleParams {
  id: number;
}

export const useDeleteVehicle = () =>
  useUpdate<IVehicle[], UseDeleteVehicleParams>(async (options) => {
    const { data } = await api.delete(`${resources.vehicleUrl}/${options?.params?.id}`);
    return camelcaseKeys(data, { deep: true });
  });
