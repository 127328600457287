import { IUser, USER_ROLE } from 'src/types/user.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';

export interface UseGetUserParams {
  branchId: number;
  roles?: USER_ROLE[];
}

export const useGetUsers = ({ params }: UseGetType<UseGetUserParams>) =>
  useGet<IUser[], UseGetUserParams>(
    async (options) => {
      const payload = { ...options?.params };
      const { data } = await api.get<IUser[]>(resources.userUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
