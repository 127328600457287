import { IDestination } from 'src/types/branch.type';
import { IPassenger } from 'src/types/passanger.type';
import { IRoute, IRoutesGroup } from 'src/types/route.type';
import { ITrip } from 'src/types/trip.type';
import { IUser } from 'src/types/user.type';
import { IVehicle, IVehicleClass } from 'src/types/vehicle.type';

export const onRouteFilter = (routes: IRoute[], input: string, option?: { value: number }) => {
  const route = routes.find((r) => r.id === option?.value);
  const inBranch = route?.branch?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inDestination = route?.destination?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inDestinationCode = route?.destination?.code.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inBranch || inDestination || inDestinationCode;
};

export const onRouteGroupFilter = (routeGroups: IRoutesGroup[], input: string, option?: { value: number }) => {
  const routeGroup = routeGroups.find((rg) => rg.id === option?.value);
  const inRouteGroup = routeGroup?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inRouteGroup;
};

export const onTripFilter = (trips: ITrip[], input: string, option?: { value: number }) => {
  const trip = trips.find((t) => t.id === option?.value);
  const inDestination = trip?.destination?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inDestinationCode = trip?.destination?.code.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inDestination || inDestinationCode;
};

export const onVehicleFilter = (vehicles: IVehicle[], input: string, option?: { value: number }) => {
  const vehicle = vehicles.find((v) => v.id === option?.value);
  const inVehiclePlate = vehicle?.registeredNumber.toLowerCase().includes(input) ?? false;
  const inVehicleMake = vehicle?.make.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inVehicleModel = vehicle?.model.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inVehicleNumber = vehicle?.vehicleNumber.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inVehiclePlate || inVehicleMake || inVehicleModel || inVehicleNumber;
};

export const onVehicleClassFilter = (vehicleClasses: IVehicleClass[], input: string, option?: { value: number }) => {
  const vehicleClass = vehicleClasses.find((v) => v.id === option?.value);
  const inVehicleClass = vehicleClass?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inVehicleClass;
};

export const onDestinationFilter = (destinations: IDestination[], input: string, option?: { value: number }) => {
  const destination = destinations.find((v) => v.id === option?.value);
  const inDestination = destination?.name.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inDestinationCode = destination?.code.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inDestination || inDestinationCode;
};

export const onUserFilter = (users: IUser[], input: string, option?: { value: number }) => {
  const user = users.find((v) => v.id === option?.value);
  const inUserFirstName = user?.firstName.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inUserLastName = user?.lastName.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inUserEmail = user?.email.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inUserFirstName || inUserLastName || inUserEmail;
};

export const onPassengerFilter = (passengers: IPassenger[], input: string, option?: { value: number }) => {
  const passenger = passengers.find((v) => v.id === option?.value);
  const inFullName = passenger?.fullName.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  const inPhone = passenger?.phone?.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;

  return inFullName || inPhone;
};

export const onStateFilter = (states: string[], input: string, option?: { value: string }) => {
  const state = states.find((s) => s === option?.value);
  const inState = state?.toLowerCase().includes(input.toLocaleLowerCase()) ?? false;
  return inState;
};
