import { PrinterOutlined } from '@ant-design/icons';
import { Button, List, Tag } from 'antd';
import { FC } from 'react';
import { ITicket } from 'src/types/ticket.type';

interface TicketListProps {
  tickets: ITicket[];
}

export const TicketList: FC<TicketListProps> = ({ tickets }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={tickets}
      renderItem={(ticket: ITicket) => (
        <List.Item key={ticket.id}>
          <List.Item.Meta
            title={
              <span>
                {ticket.passenger?.fullName} <Tag>{ticket.passenger?.type}</Tag>
              </span>
            }
            description={ticket.passenger?.pnr}
          />
          <Button
            href={`/ticket/${ticket.id}/print`}
            target="blank"
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
            size="small"
          >
            Print
          </Button>
        </List.Item>
      )}
    />
  );
};
