import { ITravelSchedule } from 'src/types/travel.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { resources } from 'src/services/api/resources';
import { PaginatedResponse, api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { PaginatedInterface } from 'src/types/type';

export interface UseGetTravelScheduleParams extends PaginatedInterface {
  id?: number;
  date?: string;
  branchId: number;
  vehicleClassId?: number;
  routeId?: number;
  organizationId?: number;
  time?: string;
  vehicleId?: number;
  startDate?: string;
  endDate?: string;
}

export const useGetTravelSchedules = ({ params }: UseGetType<UseGetTravelScheduleParams>) =>
  useGet<PaginatedResponse<ITravelSchedule>, UseGetTravelScheduleParams>(
    async (options) => {
      const payload = { ...options?.params };
      const { data } = await api.get<PaginatedResponse<ITravelSchedule>>(resources.travelScheduleUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
