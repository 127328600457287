import React, { createContext, useContext, useState, useMemo } from 'react';
import { PageHeader, Divider, Button, Modal } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

import { pageStyle } from 'src/components/page/styles/pageStyle';
import { ExclamationCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

export interface PageProps {
  title?: string;
  subtitle?: string;
}

export interface PageProviderValues {
  setSubTitle: (subtitle: string) => void;
  setTitle: (title: string) => void;
}

export const PageContext = createContext<PageProviderValues>({ setSubTitle: () => {}, setTitle: () => {} });

export const usePage = () => {
  if (!PageContext) {
    throw Error('usePage can only be used within an PageContextProvider');
  }

  return useContext(PageContext);
};

export const Page: React.FC<PageProps> = ({ title, subtitle }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [subTitle, setSubTitle] = useState(subtitle ?? '');
  const [pageTitle, setTitle] = useState(title ?? '');

  const logoutCallback = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to sign out?',
      okText: 'Sign Out',
      cancelText: 'Cancel',
      onOk: logout,
    });
  };

  const values = useMemo(() => ({ setSubTitle, setTitle }), [setSubTitle, setTitle]);

  return (
    <PageContext.Provider value={values}>
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          subTitle={subTitle}
          extra={[
            <Button
              onClick={logoutCallback}
              key="logout-button"
              danger
              type="primary"
              shape="round"
              icon={<LogoutOutlined />}
            >
              Sign Out
            </Button>,
          ]}
        />
        <Divider style={pageStyle.divider} />
        <div>
          <Outlet />
        </div>
      </div>
    </PageContext.Provider>
  );
};
