export const Pos = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
      <path
        fill="#184AAB"
        d="M0 14.111c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747
         1.667-1.667V8H0v6.111zm6.667-2.361c0-.23.187-.417.416-.417h4.723c.229
          0 .416.188.416.417v1.389c0 .229-.187.417-.416.417H7.083a.418.418
          0 01-.416-.417v-1.39zm-4.445 0c0-.23.188-.417.417-.417h2.5c.23
          0 .417.188.417.417v1.389c0 .229-.188.417-.417.417h-2.5a.418.418
           0 01-.417-.417v-1.39zM20 1.889v1.666H0V1.89C0 .969.747.222
           1.667.222h16.666C19.253.222 20 .97 20 1.89z"
      />
    </svg>
  );
};
