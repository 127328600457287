import { useCallback, useEffect, useState } from 'react';
import { RoutesService } from 'src/services/routes.service';
import { IRoute } from 'src/types/route.type';

export const useGetRouteGroupRoutes = (
  routeGroupId?: number,
): [
  {
    data: IRoute[];
    loading: boolean;
    error: any;
  },
  (id: number) => Promise<IRoute[] | null>,
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<IRoute[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRouteGroupRoutes = useCallback(
    async (id: number) => {
      let routeGroupRoutesList = null;
      setLoading(true);
      try {
        routeGroupRoutesList = await RoutesService.getRouteGroupRoutes(id);
        setData(routeGroupRoutesList);
      } catch (err: any) {
        setError(err);
      }

      setLoading(false);
      return routeGroupRoutesList;
    },
    [setData, setError, setLoading],
  );

  useEffect(() => {
    if (routeGroupId) {
      fetchRouteGroupRoutes(routeGroupId);
    }
  }, [routeGroupId]);
  return [{ data, loading, error }, fetchRouteGroupRoutes];
};
