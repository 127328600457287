import { Tag } from 'antd';
import { FC } from 'react';
import { PAYMENT_METHODS } from 'src/types/transaction.type';

export interface PaymentMethodTagProps {
  method: PAYMENT_METHODS;
}

export const PaymentMethodTag: FC<PaymentMethodTagProps> = ({ method }) => {
  let text: string = '';

  if (method === PAYMENT_METHODS.BANK_TRANSFER) text = 'Bank Transfer';
  if (method === PAYMENT_METHODS.POS) text = 'P.O.S';
  if (method === PAYMENT_METHODS.CASH) text = 'Cash';

  return <Tag color="default">{text}</Tag>;
};
