import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { IPassenger } from 'src/types/passanger.type';
import logo from 'src/icons/Logo.png';
import 'src/components/passenger-manifest/styles/passengerManifest.styles.less';
import { ITrip } from 'src/types/trip.type';
import moment from 'moment';
import { textOrNA } from 'src/utilities/string.utils';
import { ITravelSchedule } from 'src/types/travel.type';

export const PASSENGER_MANIFEST_PAGE = 'PASSENGER_MANIFEST_PAGE';

interface ManifestProps {
  trip: ITrip;
  passangers: IPassenger[];
}

const columns: ColumnsType<IPassenger> = [
  {
    title: 'PNR',
    dataIndex: 'pnr',
    key: 'pnr',
  },
  {
    title: 'Passenger Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Destination',
    dataIndex: 'travelSchedule',
    key: 'travelSchedule',
    render: (ts: ITravelSchedule) => ts.route?.destination?.name,
  },
  {
    title: 'Sex',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Next of Kin',
    render: (passenger: IPassenger) => <p>{`${passenger.nextKinFirstName} ${passenger.nextKinLastName}`}</p>,
  },
  {
    title: 'Next of Kin Phone',
    render: (passenger: IPassenger) => passenger.nextKinPhone,
  },
];

export const PassengerManifest: FC<ManifestProps> = ({ trip, passangers = [] }) => {
  const tripDateTime = moment(`${trip.date} ${trip.time}`);

  return (
    <div data-testid={PASSENGER_MANIFEST_PAGE} className="passenger-manifest">
      <div className="logo">
        <img src={logo} alt="company logo" />
      </div>
      <div className="header">
        <h4>Passenger Manifest</h4>
        <span>DATE: {tripDateTime.format('Do MMM, YYYY')}</span>
      </div>
      <div className="content">
        <div className="row">
          <div className="col">
            <h4>DEPARTURE TIME</h4>
            <p>{tripDateTime.format('h:mm:ss a')}</p>
          </div>
          <div className="col">
            <h4>DESTINATION</h4>
            <p>
              {trip.destination?.name} ({trip.destination?.code})
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>CHASIS NUMBER</h4>
            <p>000XXXX</p>
          </div>
          <div className="col">
            <h4>ENGINE NUMBER</h4>
            <p>0000XXXX</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>VEHICLE CLASS</h4>
            <p>{trip.vehicleClass?.name}</p>
          </div>
          <div className="col">
            <h4>VEHICLE REG NUMBER</h4>
            <p>{textOrNA(trip.vehicle?.registeredNumber)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>MAKE OF VEHICLE</h4>
            <p>{textOrNA(trip.vehicle?.make)}</p>
          </div>
          <div className="col">
            <h4>VEHICLE MODEL</h4>
            <p>{textOrNA(trip.vehicle?.model)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>DRIVERS NAME</h4>
            {trip.driver ? <p>{`${trip.driver.firstName} ${trip.driver.lastName}`}</p> : 'N/A'}
          </div>
          <div className="col">
            <h4>DRIVERS LICENSE</h4>
            <p>{trip.driver?.driversLicenseNumber}</p>
          </div>
        </div>
      </div>
      <Table
        size="small"
        rowKey={(passanger: IPassenger) => passanger.id}
        columns={columns}
        dataSource={passangers}
        pagination={false}
      />
    </div>
  );
};
