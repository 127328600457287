import React, { useEffect, useState } from 'react';
import { Card, Button, message, Table } from 'antd';
import moment from 'moment';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { usePage } from 'src/components/page/Page';
import { PlusOutlined } from '@ant-design/icons';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip } from 'src/types/trip.type';
import { TripModal } from 'src/screens/trips/trip-modal/TripModal';
import { TripOptions } from 'src/screens/trips/components/TripOptions';
import { UseGetTripsQueryParams, useGetTrips } from 'src/hooks/useGetTrips';
import { tripColumns } from 'src/screens/trips/utils/trip-utils';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { TripSearch } from 'src/screens/trips/components/TripSearch';

export const TRIP_TEST_ID = 'TRIP_TEST_ID';

export const Trips: React.FC = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedTrip, setSelectedTrip] = useState<ITrip>();
  const [filterQuery, setFilterQuery] = useState<UseGetTripsQueryParams>({
    branchId: activeBranch?.id,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
    endDate: moment().format(DEFAULT_DATE_FORMAT),
    status: 'open_trips',
    page: 1,
    offset: 50,
  });

  const [{ data, loading }, fetchTrips] = useGetTrips({ params: filterQuery });

  useEffect(() => {
    setTitle('Trips');
    setSubTitle('');
  }, []);

  useEffect(() => {
    fetchTrips({ params: filterQuery });
  }, [filterQuery]);

  const onClose = async () => {
    setSelectedTrip(undefined);
    setShowModal(false);
  };

  const onTripActionCompleted = async () => {
    await fetchTrips({ params: filterQuery });
    message.success(`Trip successfully ${selectedTrip ? 'updated' : 'created'}`);
    onClose();
  };

  const onEditTrip = (trip: ITrip) => {
    setSelectedTrip(trip);
    setShowModal(true);
    setIsEdit(true);
  };

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currenctPage, offset: newPageSize });
  };

  const cols = [...tripColumns];
  cols.push({
    title: 'Action',
    dataIndex: 'id',
    align: 'center',
    fixed: 'right',
    width: 100,
    render: (id: number, trip: ITrip) => (
      <TripOptions tripId={id} onEdit={() => onEditTrip(trip)} editable={!!trip.closedAt} />
    ),
  });

  return (
    <div>
      <TripSearch onApply={setFilterQuery} />
      <Card
        key="TTIP_SCHEDULE_KEY"
        data-testid={TRIP_TEST_ID}
        bordered={false}
        extra={[
          <Button
            key="add-schedule-key"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedTrip(undefined);
              setShowModal(true);
              setIsEdit(false);
            }}
          >
            Add New
          </Button>,
        ]}
      >
        <Table
          rowKey={(row: ITrip) => row.id}
          columns={cols}
          dataSource={data?.items}
          bordered
          loading={loading}
          pagination={{
            pageSize: data?.meta.offset,
            current: data?.meta.page,
            onChange: onPageChange,
            showSizeChanger: true,
            total: data?.meta.totalItems,
          }}
          scroll={{ x: 1500 }}
        />
      </Card>
      {showModal && (
        <TripModal
          show={showModal}
          onTripActionCompleted={onTripActionCompleted}
          onClose={onClose}
          initialTrip={selectedTrip}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};
