import { Form, Modal, Select } from 'antd';
import React from 'react';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IBranch } from 'src/types/branch.type';
import { ITransferWayBillParams, usePostTransferWaybill } from 'src/hooks/usePostTransferWaybill';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { IWaybill } from 'src/types/waybill.type';

export interface WaybilModalProps {
  waybill: IWaybill;
  show?: boolean;
  onClose?: () => void;
  onExpenseActionCompleted?: () => void;
}

export const WaybillTranserModal: React.FC<WaybilModalProps> = ({
  show,
  onClose,
  onExpenseActionCompleted,
  waybill,
}) => {
  const { branches } = useBranch();
  const [form] = Form.useForm();
  const { successMessage, errorMessage } = useMessage();

  const [transferWaybill, { loading: transferWaybillLoading }] = usePostTransferWaybill();

  const handleCancel = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  const handleTransfer = async (values: { branch: string }) => {
    const [selectedBranch] = branches?.filter((branch: IBranch) => branch.name === values.branch) || [];

    const payload: ITransferWayBillParams = {
      branchId: selectedBranch?.id,
      waybillId: waybill.id,
      ticketerId: waybill.ticketerId,
      destinationId: waybill.destinationId,
    };

    const postTransferResponse = await transferWaybill({ params: payload });

    if (onExpenseActionCompleted && postTransferResponse) {
      successMessage({ content: 'Waybill successfully transferred', duration: 2.3 });
      onExpenseActionCompleted();
    }
  };

  const loading = transferWaybillLoading;

  return (
    <Modal
      title="Transfer Waybill"
      open={show}
      closable={!loading}
      maskClosable={loading}
      okText="Transfer"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleTransfer(values);
          })
          .catch((info) => {
            errorMessage({ content: `Waybill transfer failed, ${info}`, duration: 2.3 });
          });
      }}
    >
      <Form autoComplete="on" form={form} layout="vertical">
        <Form.Item
          label="Departure Branch"
          name="branch"
          rules={[{ required: true, message: 'Select a branch to transfer waybill' }]}
        >
          <Select
            showSearch
            placeholder="Select departure Branch"
            className="form-item-input"
            options={branches?.map((item: IBranch) => {
              return { value: item.name, label: item.name };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
