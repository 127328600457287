import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { IReservations } from 'src/types/ticket.type';
import { PaginatedInterface } from 'src/types/type';

export interface UseGetReservationsParams extends PaginatedInterface {
  search?: string;
  limit?: number;
}

export const useGetReservations = ({ params }: UseGetType<UseGetReservationsParams>) =>
  useGet<PaginatedResponse<IReservations>, UseGetReservationsParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<IReservations>>(resources.reservationsUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
