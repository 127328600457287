import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CloseTripMutation,
  CloseTripMutationVariables,
} from 'src/graphql/mutations/__generated__/close-trip.generated';

const CLOSE_TRIP_MUTATION = gql`
  mutation CloseTrip($tripId: Float!) {
    closeTrip(tripId: $tripId) {
      id
      organizationId
      branchId
      destinationId
      routeGroupId
      date
      time
      vehicleClassId
      closedAt
      createdAt
      updatedAt
    }
  }
`;

export const useCloseTripMutation = (): MutationTuple<CloseTripMutation, CloseTripMutationVariables> =>
  useMutationErrorNotification<CloseTripMutation, CloseTripMutationVariables>(CLOSE_TRIP_MUTATION);
