import { Button, Col, Collapse, DatePicker, Form, Row, Select, Space } from 'antd';
import { useGetDestinations } from 'src/hooks/useGetDestinations';
import { IDestination } from 'src/types/branch.type';
import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import moment, { Moment } from 'moment';
import { DATE_FORMATS, DEFAULT_DATE_FORMAT, HUMAN_READABLE_WITH_TIME_FORMAT_SHORT } from 'src/utilities/date.utils';
import { useGetTrips } from 'src/hooks/useGetTrips';
import { ITrip } from 'src/types/trip.type';
import { getTripDateTime } from 'src/screens/trips/TripUtils';
import { onDestinationFilter, onTripFilter } from 'src/utilities/helpers.utils';
import { UseGetTravelScheduleParams } from 'src/hooks/useGetTravelSchedule';
import { useGetRoutes } from 'src/hooks/useGetRoutes';

export interface ScheduleFilterprops {
  onApply?: (filter: UseGetTravelScheduleParams) => void;
}
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const panelStyle = {
  marginBottom: 24,
  background: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 4,
  border: 'none',
};

const formSpan = 5;

export const ScheduleFilter = ({ onApply }: ScheduleFilterprops) => {
  const { activeBranch } = useBranch();
  const [{ data: routes }] = useGetRoutes();
  const [form] = Form.useForm();

  const [{ data: destinationData }] = useGetDestinations();
  const [{ data: trips }] = useGetTrips({
    params: {
      branchId: activeBranch?.id,
      status: 'open_trips',
      startDate: moment().format(DEFAULT_DATE_FORMAT),
      offset: 100,
    },
  });

  const destinationOptions = destinationData?.map((destination: IDestination) => {
    return (
      <Option key={destination.id} value={destination.id}>
        {destination.name}
      </Option>
    );
  });

  const onDestinationSearch = (input: string, option?: { value: number }) => {
    return onDestinationFilter(destinationData!, input, option);
  };

  const onTripSearch = (input: string, option?: { value: number }) => {
    return onTripFilter(trips?.items!, input, option);
  };

  const tripOptions = trips?.items?.map((trip: ITrip) => {
    const { dateTime } = getTripDateTime(trip);
    return (
      <Option key={trip.id} value={trip.id}>
        {`${trip.destination?.name.toUpperCase()} | ${moment(dateTime, DATE_FORMATS.DD_MM_YYYY__hh_mm_A).format(
          HUMAN_READABLE_WITH_TIME_FORMAT_SHORT,
        )}`}
      </Option>
    );
  });

  const onApplyFilter = async () => {
    const { dateRange, destinationId, ...values } = await form.validateFields();
    const filter: UseGetTravelScheduleParams = { branchId: activeBranch?.id!, offset: 100, ...values };
    if (dateRange) {
      filter.startDate = (dateRange[0] as Moment).format(DEFAULT_DATE_FORMAT);
      filter.endDate = (dateRange[1] as Moment).format(DEFAULT_DATE_FORMAT);
    }

    if (destinationId) {
      const route = routes?.find((r) => r.destination?.id === destinationId);
      filter.routeId = route?.id;
    }

    if (onApply) {
      onApply(filter);
    }
  };

  const onClear = () => {
    form.resetFields();
    if (onApply) {
      onApply({ branchId: activeBranch?.id!, offset: 100, startDate: moment().format(DEFAULT_DATE_FORMAT) });
    }
  };

  const caret = ({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={caret}
      style={{ background: 'rgba(0, 0, 0, 0.02)' }}
    >
      <Panel header="Filter" key="1" style={panelStyle}>
        <Form form={form} layout="vertical">
          <Row align="middle" gutter={[16, 16]}>
            <Col span={formSpan}>
              <Form.Item name="destinationId" label="Destination">
                <Select showSearch placeholder="Final Destination" filterOption={onDestinationSearch}>
                  {destinationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="tripId" label="Trip">
                <Select showSearch placeholder="Select trip" filterOption={onTripSearch}>
                  {tripOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item name="dateRange" label="Date Range">
                <RangePicker defaultValue={[moment(), moment()]} />
              </Form.Item>
            </Col>
            <Col span={formSpan}>
              <Form.Item label=" ">
                <Space split="|">
                  <Button type="primary" icon={<FilterFilled />} onClick={onApplyFilter}>
                    Apply Filters
                  </Button>
                  <Button onClick={onClear}>Clear filters</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};
