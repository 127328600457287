import React from 'react';
import { useParams } from 'react-router-dom';
import { Printable } from 'src/components/print/Print';
import { useGetTrip } from 'src/hooks/getTrips';
import { TripClosingBook } from 'src/screens/trips/components/TripClosingBook';

export const ClosingBook: React.FC = () => {
  const params = useParams();

  const [{ data, loading }] = useGetTrip({ params: { tripId: parseInt(params.id!, 10) } });

  return (
    <Printable styles={{ padding: '0 5%' }} loading={loading || !data}>
      {data && <TripClosingBook trip={data} printMode />}
    </Printable>
  );
};
