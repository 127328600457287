import React from 'react';
import { Input } from 'antd';
import 'src/components/search-bar/styles/searchbar.styles.less';

const { Search } = Input;

export interface SearchBarProps {
  className?: string;
  placeHolder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  width?: number;
  loading?: boolean;
}

export const SearchBar = (props: SearchBarProps) => {
  const { className, placeHolder, onChange, onSearch, width, loading } = props;
  return (
    <div className="search-container" style={{ width: width ?? '100%' }}>
      <label>
        <b>search:</b>
      </label>
      <Search
        allowClear
        loading={loading}
        className={className}
        placeholder={placeHolder}
        onChange={onChange}
        enterButton
        onSearch={onSearch}
      />
    </div>
  );
};
