import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateVehicleClassConfigMutation,
  UpdateVehicleClassConfigMutationVariables,
} from 'src/graphql/mutations/__generated__/update-vehicle-class-config.generated';
import { VEHICLE_CLASS_CONFIG_FRAGMENT } from 'src/graphql/fragments/vehicle-class-config.fragment';

const CREATE_VEHICLE_CLASS_CONFIG_QUERY = gql`
  mutation UpdateVehicleClassConfig($vehicleClassConfigId: ID!, $charge: Float!) {
    updateVehicleClassConfig(id: $vehicleClassConfigId, charge: $charge) {
      ...VehicleClassConfigFragment
    }
  }
  ${VEHICLE_CLASS_CONFIG_FRAGMENT}
`;

export const useUpdateVehicleClassConfig = (): MutationTuple<
  UpdateVehicleClassConfigMutation,
  UpdateVehicleClassConfigMutationVariables
> =>
  useMutationErrorNotification<UpdateVehicleClassConfigMutation, UpdateVehicleClassConfigMutationVariables>(
    CREATE_VEHICLE_CLASS_CONFIG_QUERY,
  );
