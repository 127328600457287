import camelcaseKeys from 'camelcase-keys';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IUpdateExpenseParams, IExpense, IExpenseSearchQuery, IDeleteExpenseResponse } from 'src/types/expense.type';

export class ExpenseService {
  public static async getExpenses(params?: IExpenseSearchQuery): Promise<PaginatedResponse<IExpense>> {
    const { data } = await api.get(`${resources.expensesUrl}`, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async updateExpense(id: number, params?: IUpdateExpenseParams): Promise<IExpense> {
    const { data } = await api.put(`${resources.expensesUrl}/${id}`, { ...params, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async findExpense(id: number): Promise<IExpense> {
    const { data } = await api.get(`${resources.expensesUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async deleteExpense(id: number): Promise<IDeleteExpenseResponse> {
    const { data } = await api.delete(`${resources.expensesUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }
}
