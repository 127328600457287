import React from 'react';
import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const WaybillSVGIcon = ({ rectFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <rect width="50" height="50" fill={rectFill ?? '#EEECFD'} rx="4" />
      <rect width="50" height="50" fill="#fff" fillOpacity="0.3" rx="4" />
      <path
        fill={pathFill ?? '#7F77F7'}
        d="M34.375 19.14h-3.984v-.468a5.391 5.391 0 00-10.782 0v.469h-3.984a.936.936 0 00-.938.937v15.703c0 .519.42.
        938.938.938h18.75a.937.937 0 00.938-.938V20.078a.936.936 0 00-.938-.937zm-6.094 0H21.72v-.468A3.28 3.
        28 0 0125 15.39a3.28 3.28 0 013.281 3.28v.47z"
      />
    </BaseSVGIcons>
  );
};
