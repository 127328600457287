import { Timeline } from 'antd';
import { ITripEvent } from 'src/types/trip.type';
import { EventTimeLineItem } from 'src/screens/trips/components/EventTimeLineItem';

export const TripEventTimeLine = ({ tripEvents }: { tripEvents: ITripEvent[] }) => {
  return (
    <Timeline className="mt-50">
      {tripEvents.map((event) => (
        <Timeline.Item>
          <EventTimeLineItem event={event} />
        </Timeline.Item>
      ))}
    </Timeline>
  );
};
