import { IRoutesGroup } from 'src/types/route.type';
import { UseGetType, useGet } from 'src/hooks//shared/useGet';
import { api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { resources } from 'src/services/api/resources';

export interface UseGetRouteGroupParams {
  branchId: number;
}

export const useGetRouteGroups = ({ params }: UseGetType<UseGetRouteGroupParams>) =>
  useGet<IRoutesGroup[], UseGetRouteGroupParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<IRoutesGroup[]>(resources.routeGroupUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
