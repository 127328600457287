import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import { OpenTripMutation, OpenTripMutationVariables } from 'src/graphql/mutations/__generated__/open-trip.generated';

const OPEN_TRIP_MUTATION = gql`
  mutation OpenTrip($tripId: Float!) {
    openTrip(tripId: $tripId) {
      id
      organizationId
      branchId
      destinationId
      routeGroupId
      date
      time
      vehicleClassId
      closedAt
      createdAt
      updatedAt
    }
  }
`;

export const useOpenTripMutation = (): MutationTuple<OpenTripMutation, OpenTripMutationVariables> =>
  useMutationErrorNotification<OpenTripMutation, OpenTripMutationVariables>(OPEN_TRIP_MUTATION);
