import React from 'react';
import { Skeleton } from 'antd';

import 'src/screens/reservation-details/styles/reservationDetails.styles.less';
import { TripDetails } from 'src/screens/reservation-details/TripDetails';
import { PassengerDetails } from 'src/screens/reservation-details/PassengerDetails';
import { PaymentDetails } from 'src/screens/reservation-details/PaymentDetails';
import { LeadPassenger } from 'src/screens/reservation-details/LeadPassenger';
import { useParams } from 'react-router-dom';
import { useGetReservationById } from 'src/graphql/queries/get-reservation-by-id';

export const ReservationDetails = () => {
  const { id } = useParams();

  const {
    data,
    loading,
  } = useGetReservationById(parseInt(id!, 10));

  if (loading || !data) {
    return (
      <div className="reservation-container">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="reservation-container">
      <TripDetails trip={data.getReservationById.trip} schedule={data.getReservationById.schedule} />
      <PassengerDetails
        data={data.getReservationById.tickets}
        trip={data.getReservationById.trip}
        schedule={data.getReservationById.schedule}
      />
      <PaymentDetails data={data.getReservationById.transaction} />
      <LeadPassenger
        data={data.getReservationById.tickets}
        trip={data.getReservationById.trip}
        schedule={data.getReservationById.schedule}
      />
    </div>
  );
};
