import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { ITripConfiguration } from 'src/types/trip.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';

export type IUpdateTripConfigStatusParams = {
  configId: number;
  active: boolean;
};

export const useUpdateTripConfigStatus = () =>
  useUpdate<ITripConfiguration, IUpdateTripConfigStatusParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<ITripConfiguration>(
      `${resources.tripConfigurationUrl}/${options?.params?.configId}/status`,
      payload,
    );
    return camelcaseKeys(data, { deep: true });
  });
