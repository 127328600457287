import { useCallback, useEffect, useState } from 'react';
import { UserService } from 'src/services/user.service';
import { IBranch } from 'src/types/branch.type';

export const useGetUserBranches = (
  id: number,
): [
  {
    data: IBranch[] | null;
    loading: boolean;
    error: any;
  },
  () => Promise<void>,
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<IBranch[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserbranches = useCallback(async () => {
    setLoading(true);
    try {
      const result = await UserService.getUserBranches();
      setData(result);
    } catch (err: any) {
      setError(err);
    }
    setLoading(false);
  }, [setData, setError, setLoading, id]);

  useEffect(() => {
    const getData = async () => {
      await fetchUserbranches();
    };
    getData();
  }, [fetchUserbranches]);

  return [{ data, loading, error }, fetchUserbranches];
};
