import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { GetUserQuery, GetUserQueryVariables } from 'src/graphql/queries/__generated__/get-user.generated';

const GET_USER_QUERY = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      email
      firstName
      lastName
      permissions
      phone
      roles
      testUser
    }
 
  }
`;

export const useGetUserQuery = (userId: string): QueryResult<GetUserQuery, GetUserQueryVariables> =>
  useQueryErrorNotification<GetUserQuery, GetUserQueryVariables>(GET_USER_QUERY, {
    variables: { userId },
  });
