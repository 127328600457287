import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { ITicket, ITicketsQueryParams } from 'src/types/ticket.type';

export class TicketService {
  public static async getTickets(params: ITicketsQueryParams): Promise<ITicket[]> {
    const { data } = await api.get(resources.ticketSalesUrl, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async findTicket(id: number): Promise<ITicket> {
    const { data } = await api.get(`${resources.ticketSalesUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async cancelTicket(id: number): Promise<ITicket> {
    const { data } = await api.post(`${resources.ticketSalesUrl}/${id}/cancel`);
    return camelcaseKeys(data, { deep: true });
  }
}
