import React, { PropsWithChildren } from 'react';
import { HasAccess } from '@permify/react-role';
import { USER_ROLE } from 'src/types/user.type';
import { UnauthorizedPage } from 'src/components/error-pages/UnauthorizedPages';

export interface RBACProps extends PropsWithChildren {
  roles?: USER_ROLE[];
  children: React.ReactNode | any;
  renderFailed?: boolean;
}

export const RBACContainer: React.FC<RBACProps> = ({ roles = [], children, renderFailed }) => {
  return (
    <HasAccess roles={roles} renderAuthFailed={renderFailed ? <UnauthorizedPage /> : undefined}>
      {children}
    </HasAccess>
  );
};
