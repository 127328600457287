import { ReactNode } from 'react';
import 'src/screens/dashboard/styles/dashboard.styles.less';
import { Card, Empty } from 'antd';
import { getWayBillIcon } from 'src/utilities/icons.utils';
import { MoneyText } from 'src/components/topography/MoneyText';
import { useGetWaybills } from 'src/hooks/useGetWaybills';

export const WAYBILL_CARD_TEST_ID = 'WAYBILL_CARD_TEST_ID';

interface WaybillItemProps {
  icon: ReactNode;
  title: string;
  route: string;
  amount: number;
}

export const Item = (props: WaybillItemProps) => {
  const { icon, title, route, amount } = props;
  return (
    <div className="waybillcard-item">
      <div className="waybillcard-item-left">
        {icon}
        <p>{title}</p>
      </div>
      <div className="waybillcard-item-right" style={{ textAlign: 'right' }}>
        <p>{route}</p>
        <MoneyText value={amount} />
      </div>
    </div>
  );
};

export const WaybillCard = () => {
  const [{ data: waybills, loading }] = useGetWaybills({ params: { limit: 4 } });

  return (
    <Card data-testid={WAYBILL_CARD_TEST_ID} className="waybill-card" loading={loading}>
      <div className="title" style={{ marginBottom: 20 }}>
        <p>Waybill</p>
      </div>

      {waybills && waybills.length < 1 && <Empty />}

      {waybills?.map((waybill) => {
        const Icon = getWayBillIcon(waybill.type);
        return (
          <Item
            key={waybill.id}
            amount={waybill.amount}
            icon={<Icon />}
            title={waybill.sendersName}
            route={`${waybill.branch?.name} → ${waybill.destination?.name}`}
          />
        );
      })}
    </Card>
  );
};
