export const Transfer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
      <path
        fill="#E46F77"
        d="M0 4.562v-.625C0 3.42.42 3 .938 3H15V1.125c0-.835 1.012-1.252 1.6-.663l3.125
         3.125a.938.938 0 010 1.326L16.6 8.038c-.586.586-1.6.175-1.6-.663V5.5H.937A.937.937
          0 010 4.562zM19.063 10.5H5V8.625c0-.833-1.01-1.253-1.6-.663L.275 11.087a.938.938
           0 000 1.326L3.4 15.538c.587.587 1.6.174 1.6-.663V13h14.063c.517
           0 .937-.42.937-.938v-.624a.937.937 0 00-.938-.938z"
      />
    </svg>
  );
};
