import { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CheckinModal } from 'src/screens/reservation-details/CheckInModal';
import Moment from 'moment';
import 'moment-timezone';
import { ReservationTicket, ReservationTrip } from 'src/graphql/queries/get-reservation-by-id';

interface IProps {
  passengerTicket: ReservationTicket;
  trip: ReservationTrip;
}

export const PassengerCheckIn = ({ passengerTicket, trip }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const expectedCheckinTimeObject = Moment.utc(trip?.expectedCheckinTime);

  const formattedDate = expectedCheckinTimeObject.format('MMMM D, YYYY h:mm:ss a');

  const onClose = async () => {
    setShowModal(false);
  };

  return (
    <div>
      {trip.isCheckinAvailable ? (
        <Button type="primary" onClick={() => setShowModal(true)}>
          Check in
        </Button>
      ) : (
        <Tooltip placement="topLeft" title={`${formattedDate}`}>
          <Button type="primary" disabled onClick={() => setShowModal(true)}>
            Check in
          </Button>
        </Tooltip>
      )}
      {trip.isCheckinAvailable ? (
        <CheckinModal show={showModal} onClose={onClose} passenger={passengerTicket.passenger!} />
      ) : null}
    </div>
  );
};
