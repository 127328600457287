import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IIncome, IIncomeSearchQuery, INewIncomeParams, IUpdateIncomeParams } from 'src/types/income.type';

export class IncomeService {
  public static async getIncome(params?: IIncomeSearchQuery): Promise<IIncome[]> {
    const { data } = await api.get(`${resources.incomeUrl}`, { params: { ...params, noTransform: true } });
    return camelcaseKeys(data, { deep: true });
  }

  public static async createIncome(params?: INewIncomeParams): Promise<IIncome> {
    const { data } = await api.post(`${resources.incomeUrl}`, { ...params, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async updateIncome(id: number, params?: IUpdateIncomeParams): Promise<IIncome> {
    const { data } = await api.put(`${resources.incomeUrl}/${id}`, { ...params, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async deleteIncome(id: number): Promise<any> {
    const { data } = await api.delete(`${resources.incomeUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }
}
