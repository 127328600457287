import camelcaseKeys from 'camelcase-keys';
import { UseGetTravelScheduleParams } from 'src/hooks/useGetTravelSchedule';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import {
  ITravelSchedule,
  ITravelScheduleSearchParams,
  INewTravelScheduleParams,
  IUpdateTravelScheduleParams,
} from 'src/types/travel.type';

export class TravelService {
  public static async getTravelSchedules(params: UseGetTravelScheduleParams): Promise<ITravelSchedule[]> {
    const { data } = await api.get(`${resources.travelScheduleUrl}`, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async findAvailableSchedules(params: ITravelScheduleSearchParams): Promise<ITravelSchedule[]> {
    const { data } = await api.get(`${resources.travelAvailabilityUrl}`, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async createTravelSchedule(params: INewTravelScheduleParams): Promise<ITravelSchedule> {
    const { data } = await api.post<ITravelSchedule>(resources.travelScheduleUrl, { ...params, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async updateTravelSchedule(id: number, params: IUpdateTravelScheduleParams): Promise<ITravelSchedule> {
    const { data } = await api.patch<ITravelSchedule>(`${resources.travelScheduleUrl}/${id}`, {
      ...params,
      noTransform: true,
    });
    return camelcaseKeys(data, { deep: true });
  }
}
