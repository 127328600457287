import { NumericFormat } from 'react-number-format';

export const MoneyText = ({ value, showCurrency = true }: { value: number | string; showCurrency?: boolean }) => {
  return (
    <NumericFormat
      value={value}
      prefix={showCurrency ? '₦' : ''}
      displayType="text"
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
    />
  );
};
