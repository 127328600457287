import { IExpense, IExpenseSearchQuery } from 'src/types/expense.type';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';

export const useGetExpenses = ({ params }: UseGetType<IExpenseSearchQuery>) =>
  useGet<PaginatedResponse<IExpense>, IExpenseSearchQuery>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<IExpense>>(resources.expensesUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
