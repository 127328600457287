import axios, { AxiosError } from 'axios';
import snakecaseKeys from 'snakecase-keys';
import { clearSessionToken, clearTerminalToken } from 'src/utilities/storage.utils';

export type RequestError = any | AxiosError;

export type PaginatedResponse<T> = {
  items: T[];
  meta: {
    page: number;
    nextPage?: string;
    prevPage?: number;
    offset: number;
    totalItems: number;
    totalPages: number;
    itemCount: number;
  };
};

const transformRequests = (data: any) => {
  if (data) {
    if (data.noTransform) {
      return JSON.stringify(data);
    }
    const transformedData = snakecaseKeys(data, { deep: true });
    return JSON.stringify(transformedData);
  }

  return data;
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_ETICKETING_SERVICE_URL ?? 'https://platform.okeysontransports.com/',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [transformRequests],
  // this will be uncommented in a new work for making key changes.
  // transformResponse: [transformResponse],
});

api.interceptors.request.use((config) => {
  // we need to intercept and transform all query params
  if (config.params) {
    if (!config.params.noTransform) {
      const params = snakecaseKeys(config.params, { deep: true });
      return { ...config, params };
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      clearTerminalToken();
      clearSessionToken();
      // Force redirect to landing page
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export const setAuthorizationToken = (token: string | null) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};
