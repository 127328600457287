import { ITransaction, PAYMENT_METHODS, TRANSACTION_STATUS, TRANSACTION_TYPE } from 'src/types/transaction.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

export interface ITransactionQueryParams {
  type?: TRANSACTION_TYPE;
  status?: TRANSACTION_STATUS;
  paymentMethod?: PAYMENT_METHODS;
  ticketerId?: number;
  startDate?: string;
  endDate?: string;
  limit?: number;
  branchId: number;
}

export const useGetTransactions = ({ params }: UseGetType<ITransactionQueryParams>) =>
  useGet<ITransaction[], ITransactionQueryParams>(
    async (options) => {
      const payload = { ...options?.params };
      const { data } = await api.get<ITransaction[]>(resources.transactionsUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
