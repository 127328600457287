import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { IWaybill } from 'src/types/waybill.type';

export interface UseGetWaybillParams {
  reference?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  assigned?: 'true' | 'false';
  tripId?: number;
  limit?: number;
}

export const useGetWaybills = (params?: UseGetType<UseGetWaybillParams>) =>
  useGet<IWaybill[], UseGetWaybillParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<IWaybill[]>(resources.waybillUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    params?.params ? { params: params.params } : undefined,
  );
