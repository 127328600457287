import { useCallback, useState } from 'react';

import { IncomeService } from 'src/services/income.service';
import { IIncome, INewIncomeParams } from 'src/types/income.type';

export const usePostCreateIncome = (): [
  (params: INewIncomeParams) => Promise<IIncome | undefined>,
  {
    data: IIncome | undefined;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<IIncome>();
  const [loading, setLoading] = useState<boolean>(false);

  const createIncome = useCallback(
    async (payload: INewIncomeParams) => {
      let result;
      setLoading(true);
      try {
        result = await IncomeService.createIncome(payload);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return result;
    },
    [setData, setError, setLoading],
  );

  return [createIncome, { data, loading, error }];
};
