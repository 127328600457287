import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';
import { IRouteFare } from 'src/types/route.type';
import { ICreateRouteFareParams } from 'src/hooks/useCreateRouteFare';

export type IUpdateRouteFareParams = Omit<ICreateRouteFareParams, 'branchId'> & { routeFareId: number };

export const useUpdateRouteFare = () =>
  useUpdate<IRouteFare, IUpdateRouteFareParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<IRouteFare>(`${resources.routeFaresUrl}/${options?.params?.routeFareId}`, payload);
    return camelcaseKeys(data, { deep: true });
  });
