import { useEffect, useState } from 'react';

import { Button, Table, Card, Popconfirm, message, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import 'src/screens/users/styles/userManagement.styles.less';
import { UserModal } from 'src/screens/users/user-modal/UserModal';
import { usePage } from 'src/components/page/Page';
import { IUser, USER_ROLE } from 'src/types/user.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { useGetUsers } from 'src/hooks/useGetUsers';
import { getRoleTags } from 'src/screens/users/UserUtils';
import { RBACContainer } from 'src/components/RBAC/RBACContainer';
import { useDeleteUser } from 'src/hooks/useDeleteUser';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { Link } from 'react-router-dom';

export const Users = () => {
  const page = usePage();
  const { activeBranch } = useBranch();
  const [deleteUser] = useDeleteUser();
  const { user: authUser } = useAuth();

  const [{ data, loading }, fetchUsers] = useGetUsers({ params: { branchId: activeBranch?.id! } });

  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();

  useEffect(() => {
    page.setTitle('Users');
    page.setSubTitle('All Users');
  }, []);

  const closeUserModal = () => {
    setSelectedUser(undefined);
    setShowUserModal(false);
  };

  const showEditUserModal = (id: number) => {
    const user = data?.find((u) => u.id === id);
    setSelectedUser(user);
    setShowUserModal(true);
  };

  const onConfirmDeleteUser = async (id: number) => {
    const deleted = await deleteUser({ params: { id } });

    if (deleted) {
      message.success('User successfully deleted');
      await fetchUsers({ params: { branchId: activeBranch?.id! } });
    }
  };

  const onUserActionComplete = async () => {
    await fetchUsers({ params: { branchId: activeBranch?.id! } });
    setSelectedUser(undefined);
    setShowUserModal(false);
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'first_name',
      render: (text, { lastName }) => <p>{`${text} ${lastName}`}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: USER_ROLE[]) => getRoleTags(roles),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
      render: (id: number, record: IUser) => {
        return (
          <Space split="|">
            <Link to={`${id}`}>
              <Button
                className="more-btn"
                icon={<EyeOutlined />}
                disabled={!authUser.roles?.includes(USER_ROLE.Admin) && record.roles?.includes(USER_ROLE.Admin)}
              />
            </Link>
            <Button
              onClick={() => showEditUserModal(id)}
              className="more-btn"
              href="#"
              icon={<EditOutlined />}
              disabled={!authUser.roles?.includes(USER_ROLE.Admin) && record.roles?.includes(USER_ROLE.Admin)}
            />

            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => onConfirmDeleteUser(id)}
              placement="topRight"
            >
              <Button
                className="more-btn"
                href="#"
                icon={<DeleteOutlined />}
                disabled={!authUser.roles?.includes(USER_ROLE.Admin) && record.roles?.includes(USER_ROLE.Admin)}
              />
            </Popconfirm>
          </Space>
        );
      },
      fixed: 'right',
    },
  ];

  return (
    <div className="user-page-container">
      <RBACContainer roles={[USER_ROLE.Admin, USER_ROLE.Manager]}>
        <Card
          bordered={false}
          extra={[
            // eslint-disable-next-line max-len
            <Button
              key="add-new-user"
              type="primary"
              icon={<PlusOutlined />}
              disabled={!(authUser.roles?.includes(USER_ROLE.Admin) || authUser.roles?.includes(USER_ROLE.Manager))}
              onClick={() => setShowUserModal(true)}
            >
              Add New
            </Button>,
          ]}
        >
          <Table
            loading={loading}
            rowKey={(user: IUser) => user.id}
            columns={columns}
            dataSource={data ?? []}
            size="small"
            bordered
            pagination={{ pageSize: 100, total: data?.length ?? 100 }}
            scroll={{ x: 500 }}
          />
        </Card>

        {showUserModal && (
          <UserModal
            show={showUserModal}
            onClose={closeUserModal}
            onComplete={onUserActionComplete}
            defaultUser={selectedUser}
          />
        )}
      </RBACContainer>
    </div>
  );
};
