import { Card } from 'antd';
import { ReservationTrip, ReservationSchedule } from 'src/graphql/queries/get-reservation-by-id';

interface IProps {
  trip: ReservationTrip | undefined;
  schedule: ReservationSchedule | undefined;
}

export const TripDetails = ({ trip, schedule }: IProps) => {
  return (
    <div className="reservation-trip-details">
      <Card title="Trip Details" style={{ width: '100%' }}>
        <div className="reservation-trip-card-content">
          <div>
            <h2>Departure Terminal</h2>
            <p>{schedule?.route?.branch?.name}</p>
          </div>
          <div>
            <h2>Departing</h2>
            <p>Date: {trip?.date}</p>
            <p>Time: {trip?.time}</p>
          </div>
          <div className="__final-destination">
            <h2>Final destination</h2>
            <p>{schedule?.route?.destination?.name}</p>
          </div>
          <div>
            <h2>Route group</h2>
            <p>{trip?.routeGroup?.name}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
