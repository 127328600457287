import React, { useState, createContext, useMemo, useEffect, useContext, useCallback } from 'react';
import { Result } from 'antd';
import { Link, NavigateOptions, useNavigate } from 'react-router-dom';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { AUTH_TOKEN_KEY } from 'src/constants';
import { setAuthorizationToken } from 'src/services/api';
import { AuthService, AuthUser } from 'src/services/auth.service';
import { usePermify } from '@permify/react-role';
import { clearSessionToken, clearTerminalToken } from 'src/utilities/storage.utils';
import { USER_ROLE } from 'src/types/user.type';

/**
 * AuthProvider will load the token info.
 *
 * If the token is not available, an unauthorised view will be rendered.
 *
 * If the token is avaibale, the provider will make a request
 * for the authorised user, and make the user information available to it's children.
 * If the token is no longer valid, an unauthorised page will be displayed.
 *
 */

export interface IAuthProviderProps {
  children: React.ReactNode | React.ReactNode[];
  // Amadosi (2022-08-14) we use this strictly for testing, till we have a better way to mock this provider
  test?: boolean;
}

export type IAuthProviderValues = {
  user: AuthUser;
  logout: () => void;
  isAdmin?: boolean;
  hasRoles: (roles: string[]) => boolean;
};

const defaultUser: AuthUser = {
  id: 0,
  organizationId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export const AuthContext = createContext<IAuthProviderValues>({
  user: defaultUser,
  logout: () => {},
  hasRoles: () => false,
});

export const useAuth = () => {
  if (!AuthContext) {
    throw Error('useAuth can only be used within an AuthContextProvider');
  }

  return useContext(AuthContext);
};

export const AuthProvider = ({ children, test }: IAuthProviderProps) => {
  const navigate = useNavigate();
  const { setUser: setPermifyUser } = usePermify();

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<AuthUser>(defaultUser);

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem(AUTH_TOKEN_KEY);
      setAuthorizationToken(token);

      try {
        const authUser = await AuthService.getAuthenticatedUser();
        setUser(authUser);
        setAuthenticated(true);
        setPermifyUser({
          id: authUser.id.toString(),
          roles: authUser.roles,
          // permissions will be extracted from the token
          permissions: [],
        });
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          setAuthenticated(false);
        }
      }

      setLoading(false);
    };

    if (test) {
      setUser(defaultUser);
      setAuthenticated(true);
    } else {
      getAuthUser();
    }
  }, []);

  const logout = useCallback(() => {
    setLoading(true);
    setAuthorizationToken(null);
    clearTerminalToken();
    clearSessionToken();
    setAuthenticated(false);

    const options: NavigateOptions = { replace: true };
    // by default lets navigate to the login page
    navigate('/', options);
  }, [setLoading, setAuthenticated]);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return user.roles?.some((role) => roles.includes(role)) ?? false;
    },
    [user.roles],
  );

  const isAdmin = useMemo(() => {
    return user.roles?.includes(USER_ROLE.Admin);
  }, [user.roles]);

  const value = useMemo(() => ({ user, logout, isAdmin, hasRoles }), [user, logout, isAdmin, hasRoles]);

  if (loading) {
    return <LoadingView />;
  }

  if (!authenticated) {
    return (
      <Result
        status="403"
        title="Unauthorised Access"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Link replace to="/">
            Back Home
          </Link>
        }
      />
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
