import { IUpdateVehicleParams, IVehicle } from 'src/types/vehicle.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

export const useUpdateVehicle = () =>
  useUpdate<IVehicle, IUpdateVehicleParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.put<IVehicle>(`${resources.vehicleUrl}/${payload.id}`, payload);
    return data;
  });
