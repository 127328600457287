import { ReactNode } from 'react';
import { BusSVGIcon, PassengerSVGIcon, RouteSVGIcon, WaybillSVGIcon } from 'src/icons';
import { Card } from 'antd';

import 'src/screens/dashboard/styles/dashboard.styles.less';
import { ITermialStatistics } from 'src/types/dashboardQuery.type';

export const TERMINAL_STATISTICS_CARD_TEST_ID = 'TERMINAL_STATISTICS_CARD_TEST_ID';

interface TerminalStatisticsItemProps {
  icon?: ReactNode;
  figure?: number;
  statistics?: string;
}

interface TerminalStatisticCardProps {
  data: ITermialStatistics | undefined;
  loading: boolean;
}

const Item = (props: TerminalStatisticsItemProps) => {
  const { icon, figure, statistics } = props;
  return (
    <div className="statisticscard-item">
      <div className="statisticscard-item-left">
        <p>{statistics}</p>
        <p>{figure}</p>
      </div>
      <div className="statisticscard-item-right">{icon}</div>
    </div>
  );
};

export const TerminalStatisticsCard = ({ data, loading }: TerminalStatisticCardProps) => {
  return (
    <Card data-testid={TERMINAL_STATISTICS_CARD_TEST_ID} className="statistics-card" loading={loading}>
      <div className="title" style={{ marginBottom: 20 }}>
        <p>Terminal Statistics</p>
      </div>
      <Item icon={<BusSVGIcon />} figure={data?.vehicleCount ?? 0} statistics="Available vehicles" />
      <Item icon={<RouteSVGIcon />} figure={data?.routeCount ?? 0} statistics="Available routes" />
      <Item icon={<PassengerSVGIcon />} figure={data?.passengerCount ?? 0} statistics="Passengers" />
      <Item icon={<WaybillSVGIcon />} figure={data?.waybillCount ?? 0} statistics="Waybill" />
    </Card>
  );
};
