import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IWaybill } from 'src/types/waybill.type';
import { ITransaction, PAYMENT_METHODS } from 'src/types/transaction.type';

export interface UseCompleteWaybillReservationParams {
  transactionId: number;
  waybillId: number;
  paymentMethod: PAYMENT_METHODS;
}

export const useCompleteWaybillReservation = () =>
  useUpdate<{ waybill: IWaybill; transaction: ITransaction }, UseCompleteWaybillReservationParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<{ waybill: IWaybill; transaction: ITransaction }>(
      resources.waybillReservationComplete,
      payload,
    );
    return data;
  });
