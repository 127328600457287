import { api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IWaybill } from 'src/types/waybill.type';

interface CancelWaybillParams {
  id: number;
  cancelReason?: string;
}

export const useCancelWaybill = () =>
  useUpdate<IWaybill, CancelWaybillParams>(async (options) => {
    const { id, cancelReason } = options?.params || {};
    if (!id) {
      throw new Error('Waybill ID is required.');
    }
    const { data } = await api.post<IWaybill>(`${resources.waybillUrl}/${id}/cancel`, {
      cancelReason,
    });
    return camelcaseKeys(data, { deep: true });
  });
