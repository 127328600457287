import { useCallback, useEffect, useState } from 'react';
import { WaybillService } from 'src/services/waybill.service';
import { IWaybill } from 'src/types/waybill.type';

export const useGetWaybill = (
  id: number,
): [
  {
    data: IWaybill | null;
    loading: boolean;
    error: any;
  },
  (id: number) => Promise<void>,
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<IWaybill | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchWaybill = useCallback(
    async (waybillId: number) => {
      setLoading(true);
      try {
        const result = await WaybillService.getWaybillById(waybillId);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError, setLoading],
  );

  useEffect(() => {
    const getData = async () => {
      await fetchWaybill(id);
    };
    getData();
  }, [id]);

  return [{ data, loading, error }, fetchWaybill];
};
