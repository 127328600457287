import { useCallback, useEffect, useState } from 'react';
import { TicketService } from 'src/services/ticket.service';
import { ITicket } from 'src/types/ticket.type';

export const useGetTicket = (
  id: number,
): [{ data: ITicket | undefined; loading: boolean; error: any }, (id: number) => Promise<void>] => {
  const [data, setData] = useState<ITicket>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (ticketId: number) => {
      setLoading(true);
      try {
        const result = await TicketService.findTicket(ticketId);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError],
  );

  useEffect(() => {
    const getTicket = async () => {
      fetch(id);
    };
    getTicket();
  }, [fetch, id]);

  return [{ data, loading, error }, fetch];
};
