import { ColumnsType } from 'antd/lib/table';
import { MoneyText } from 'src/components/topography/MoneyText';
import { RouteFareOverride } from 'src/graphql/queries/find-route-fare-overrides';
import { IRoute, IRouteFare } from 'src/types/route.type';

export const routeFareColumns: ColumnsType<IRouteFare> = [
  {
    title: 'Route',
    dataIndex: 'route',
    key: 'route',
    render: (route: IRoute) => <span>{`${route.branch?.name}  →  ${route.destination?.name}`}</span>,
  },
  {
    title: 'Amount',
    dataIndex: 'fare',
    key: 'fare',
    render: (fare: number) => <MoneyText value={fare} />,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
];

export const routeFareOverrideColumns: ColumnsType<RouteFareOverride> = [
  {
    title: 'Route',
    dataIndex: 'route',
    key: 'route',
    render: (route: RouteFareOverride['route']) => route.destination?.name,
  },
  {
    title: 'Class',
    dataIndex: 'vehicleClass',
    key: 'vehicleClass',
    render: (vehicleClass: RouteFareOverride['vehicleClass']) => vehicleClass.name,
  },
  {
    title: 'Amount',
    dataIndex: 'fare',
    key: 'fare',
    render: (fare: number) => <MoneyText value={fare} showCurrency />,
  },
];
