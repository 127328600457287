import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IDestination } from 'src/types/branch.type';
import { ITrip } from 'src/types/trip.type';
import { IUser } from 'src/types/user.type';
import { IWaybill, WAYBILL_STATUS } from 'src/types/waybill.type';
import { MoneyText } from 'src/components/topography/MoneyText';
import { Badge } from 'antd';

export const getWaybillStatusBadge = (status: string) => {
  if (status === WAYBILL_STATUS.CANCELLED) return <Badge status="error" text="cancelled" />;
  if (status === WAYBILL_STATUS.ISSUED) return <Badge status="success" text="issued" />;
  if (status === WAYBILL_STATUS.ON_HOLD) return <Badge status="processing" text="pending" />;

  return null;
};

export const waybillColumns: ColumnsType<IWaybill> = [
  {
    title: 'Reference',
    dataIndex: 'reference',
    key: 'reference',
    render: (reference: string) => reference,
  },
  {
    title: 'Package Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: string) => type,
  },
  {
    title: 'Senders Name',
    dataIndex: 'sendersName',
    key: 'sendersName',
    render: (name: string) => name,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date) => moment(date).format('ddd Do MMM, YYYY'),
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (destination: IDestination) => destination.name,
  },
  {
    title: 'Trip',
    dataIndex: 'trip',
    key: 'trip',
    render: (trip?: ITrip) => {
      if (!trip) return 'N/A';
      const dateTime = moment(`${trip?.date} ${trip?.time}`).format('DD-MM-YYYY hh:mm A');
      return <Link to={`/trips/${trip.id}`}>{`${trip.destination?.name} | ${dateTime}`}</Link>;
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (quantity) => quantity,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => <MoneyText value={amount} />,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value: number) => <MoneyText value={value} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => getWaybillStatusBadge(status),
  },
  {
    title: 'Authorised Clerk',
    dataIndex: 'ticketer',
    key: 'ticketer',
    render: (ticketer: IUser) => `${ticketer?.firstName} ${ticketer?.lastName}`,
  },
];
