import { ITrip } from 'src/types/trip.type';
import { UseGetType, useGet } from 'src/hooks/shared/useGet';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { PaginatedInterface } from 'src/types/type';

export interface UseGetTripsQueryParams extends PaginatedInterface {
  startDate?: string;
  endDate?: string;
  branchId?: number;
  status: 'closed_trips' | 'open_trips' | 'all';
}

export const useGetTrips = ({ params }: UseGetType<UseGetTripsQueryParams>) =>
  useGet<PaginatedResponse<ITrip>, UseGetTripsQueryParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<ITrip>>(resources.trip.getTripsUrl, {
        params: payload,
      });

      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
