import { CloseCircleOutlined } from '@ant-design/icons';
import { message, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { useDeleteIncome } from 'src/hooks/useDeleteIncome';

export interface IDeleteIncomeProps {
  id: number;
  onDelete: () => void;
}

export const DeleteIncomeAction = ({ id, onDelete }: IDeleteIncomeProps) => {
  const [deleteIncome, { error }] = useDeleteIncome();

  useEffect(() => {
    if (error) message.error(error?.response?.data?.message);
  }, [error]);

  const onConfirm = async () => {
    const deleted = await deleteIncome(id);

    if (deleted) {
      message.success('Income successfully deleted');
      onDelete();
    }
  };

  return (
    <Popconfirm title="Are you sure to delete this Income?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <CloseCircleOutlined style={{ color: '#c0392b' }} />
    </Popconfirm>
  );
};
