import { Card, Col, Row, Statistic } from 'antd';
import { ReactNode } from 'react';
import { FlexBox } from 'src/components/flex-box/FlexBox';
import { BankSVGIcon } from 'src/icons/BankIcon';
import { ExpenseSVGIcon } from 'src/icons/ExpenseIcon';
import { IncomeSVGIcon } from 'src/icons/IncomeIcon';
import { RevenueSVGIcon } from 'src/icons/RevenueIcon';
import { TicketSVGIcon } from 'src/icons/TicketIcon';
import { TripSVGIcon } from 'src/icons/TripIcon';
import { FilterOptions } from 'src/screens/dashboard/components/FilterOptions';
import { ITermialStatistics } from 'src/types/dashboardQuery.type';

interface SummaryCardProps {
  icon?: ReactNode;
  title?: string;
  value?: number;
  isMoney?: boolean;
  loading?: boolean;
  precision?: number;
}

interface SummaryPanelProps {
  data: ITermialStatistics | undefined;
  loading: boolean;
  onRangeSelected?: ((start: string | null, end: string | null) => void) | undefined;
}

const SummaryCard = (props: SummaryCardProps) => {
  const { icon, title, value, isMoney = false, loading, precision } = props;

  return (
    <Card loading={loading}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Statistic title={title} value={value} precision={precision} prefix={isMoney && '₦'} />
        {icon}
      </FlexBox>
    </Card>
  );
};

export const SummaryPanel = ({ data, loading, onRangeSelected }: SummaryPanelProps) => {
  return (
    <Card extra={<FilterOptions onRangeSelected={onRangeSelected} />}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <SummaryCard title="Closed Trips" value={data?.tripCount ?? 0} icon={<TripSVGIcon />} loading={loading} />
        </Col>
        <Col span={8}>
          <SummaryCard
            title="Total Tickets"
            value={data?.ticketCount ?? 0}
            icon={<TicketSVGIcon />}
            loading={loading}
          />
        </Col>
        <Col span={8}>
          <SummaryCard
            title="Transactions"
            value={data?.transactionCount ?? 0}
            icon={<BankSVGIcon />}
            loading={loading}
          />
        </Col>

        <Col span={8}>
          <SummaryCard
            title="Revenue"
            isMoney
            value={data?.transactionSum ?? 0}
            icon={<RevenueSVGIcon />}
            loading={loading}
            precision={2}
          />
        </Col>

        <Col span={8}>
          <SummaryCard
            title="Expenses"
            isMoney
            value={data?.expenseSum ?? 0}
            icon={<ExpenseSVGIcon />}
            loading={loading}
            precision={2}
          />
        </Col>
        <Col span={8}>
          <SummaryCard
            title="Net Income"
            isMoney
            value={data?.netIncome ?? 0}
            icon={<IncomeSVGIcon />}
            loading={loading}
            precision={2}
          />
        </Col>
      </Row>
    </Card>
  );
};
