import { useCallback, useState } from 'react';
import { TicketService } from 'src/services/ticket.service';
import { ITicket } from 'src/types/ticket.type';

export const usePostCancelTicket = (): [
  (id: number) => Promise<ITicket | null>,
  { data: ITicket | undefined; loading: boolean; error: any },
] => {
  const [data, setData] = useState<ITicket>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const update = useCallback(
    async (id: number) => {
      let ticket: ITicket | null = null;
      setLoading(true);
      try {
        const ticketData = await TicketService.cancelTicket(id);
        setData(ticketData);
        ticket = ticketData;
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return ticket;
    },
    [setData, setError, setLoading],
  );

  return [update, { data, loading, error }];
};
