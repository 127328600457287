import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const TripSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#EEE9FD'} />
      <path
        fill={pathFill ?? '#6E4CF5'}
        // eslint-disable-next-line max-len
        d="M38.095 22.611l-.694-1.213a.234.234 0 00-.32-.084l-1.485.867-2.294-6.334a1.873 1.873 0 00-1.784-1.301H18.824a2.347 2.347 0 00-2.233 1.626l-2.185 6.012-1.488-.868a.234.234 0 00-.32.085l-.694 1.21a.23.23 0 00.085.317l1.77 1.031-.425 1.172a.836.836 0 00-.053.293v10.201c0 .46.346.832.77.832h1.98c.361 0 .675-.272.75-.653l.226-1.105h15.985l.225 1.105c.08.38.39.653.75.653h1.98c.426 0 .771-.372.771-.832V25.424c0-.1-.017-.2-.052-.293l-.425-1.172 1.766-1.031a.234.234 0 00.088-.317zm-20.361 6.082a1.172 1.172 0 110-2.343 1.172 1.172 0 010 2.343zm11.367 2.198a.235.235 0 01-.234.234h-7.735a.235.235 0 01-.234-.234V28.43c0-.13.105-.235.234-.235h1.172c.13 0 .235.106.235.235v1.054h4.922V28.43c0-.13.105-.235.234-.235h1.172c.129 0 .234.106.234.235v2.46zm3.164-2.198a1.172 1.172 0 110-2.343 1.172 1.172 0 010 2.343zm-15.82-5.947l2.13-5.856.015-.038.011-.038a.233.233 0 01.223-.162H31.35l2.21 6.094H16.444z"
      />
    </BaseSVGIcons>
  );
};
