import { useParams } from 'react-router-dom';
import { Printable } from 'src/components/print/Print';
import { WaybillDocument } from 'src/components/waybill-document/WaybillDocument';
import { useGetWaybill } from 'src/hooks/useGetWaybill';

export const PrintWaybill = () => {
  const params = useParams();

  const [{ data: waybill, loading }] = useGetWaybill(parseInt(params.id!, 10));

  return <Printable loading={loading}>{waybill && <WaybillDocument waybill={waybill} />}</Printable>;
};
