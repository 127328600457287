import React, { ReactNode } from 'react';

interface BaseSVGIconsProps {
  fill?: string;
  height?: string;
  width?: string;
  viewBoxWidth?: string;
  viewBoxHeight?: string;
  children?: ReactNode | ReactNode[];
}

export interface SVGIconProps {
  circleFill?: string;
  pathFill?: string;
  rectFill?: string;
  gFill?: string;
}

export const BaseSVGIcons = ({ fill, height, viewBoxHeight, viewBoxWidth, width, children }: BaseSVGIconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '50'}
      height={height ?? '51'}
      fill={fill ?? 'none'}
      viewBox={` 0 0 ${viewBoxWidth ?? '50'}  ${viewBoxHeight ?? '51'}`}
    >
      {children}
    </svg>
  );
};
