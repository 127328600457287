import { Tabs } from 'antd';

import { ITrip } from 'src/types/trip.type';
import { TripPassengers } from 'src/screens/trips/components/TripPassengers';
import { TripEvents } from 'src/screens/trips/components/TripEvents';
import { TripHandOver } from 'src/screens/trips/components/TripHandOver';
import { TripWaybills } from 'src/screens/trips/components/TripWaybills';
import { TripClosingBook } from 'src/screens/trips/components/TripClosingBook';
import { TripSeatMap } from 'src/screens/trips/components/TripSeatMap';

enum TAB_KEYS {
  passangers = 'Passenger-tab',
  events = 'Events-tab',
  handOver = 'HandOver-tab',
  closingBooks = 'ClosingBooks-tab',
  waybills = 'Waybills-tab',
  seatMap = 'SeatMap-tab',
}

export const TripTabContent = ({ trip }: { trip: ITrip }) => {
  return (
    <Tabs type="card">
      <Tabs.TabPane tab="Passengers" key={TAB_KEYS.passangers}>
        <TripPassengers trip={trip} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Events" key={TAB_KEYS.events}>
        <TripEvents trip={trip} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Hand Over" key={TAB_KEYS.handOver}>
        <TripHandOver trip={trip} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Waybills" key={TAB_KEYS.waybills}>
        <TripWaybills trip={trip} />
      </Tabs.TabPane>
      {trip.sourceSeatMapId && (
        <Tabs.TabPane tab="Seat Map" key={TAB_KEYS.seatMap}>
          <TripSeatMap trip={trip} />
        </Tabs.TabPane>
      )}
      <Tabs.TabPane tab="Closing Books" key={TAB_KEYS.closingBooks}>
        <TripClosingBook trip={trip} />
      </Tabs.TabPane>
    </Tabs>
  );
};
