export enum USER_ROLE {
  User = 'role:user',
  Admin = 'role:admin',
  CustomerSupport = 'role:customer_support',
  SystemAdminstrator = 'role:system_admin',
  Operations = 'role:operations',
  FleetManagement = 'role:fleet_managment',
  Manager = 'role:manager',
}

export const RoleNameMapping = {
  [USER_ROLE.User]: 'User',
  [USER_ROLE.Admin]: 'Administrator',
  [USER_ROLE.CustomerSupport]: 'Customer Support',
  [USER_ROLE.Operations]: 'Operations',
  [USER_ROLE.SystemAdminstrator]: 'System/IT Specialist',
  [USER_ROLE.FleetManagement]: 'Fleet Management',
  [USER_ROLE.Manager]: 'Manager',
};

export const RoleColorMapping = {
  [USER_ROLE.User]: 'magenta',
  [USER_ROLE.Admin]: 'red',
  [USER_ROLE.CustomerSupport]: 'volcano',
  [USER_ROLE.Operations]: 'orange',
  [USER_ROLE.SystemAdminstrator]: 'gold',
  [USER_ROLE.FleetManagement]: 'lime',
  [USER_ROLE.Manager]: 'green',
};

export interface IUserDeprecated {
  id: number;
  public_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  organization_id: number;
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: number;
  publicId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  driversLicenseNumber?: string;
  organizationId: number;
  createdAt: string;
  updatedAt: string;
  roles: USER_ROLE[];
}

export interface IUserBranch {
  id?: number;
  user_id: number;
  branch_id: number;
}
