import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { UseCreateExpenseParams, usePostCreateExpense } from 'src/hooks/usePostCreateExpense';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IExpense, IUpdateExpenseParams } from 'src/types/expense.type';
import { ITrip } from 'src/types/trip.type';
import { tripExpensesOptions } from 'src/screens/expenses/ExpenseUtils';
import { usePutUpdateExpense } from 'src/hooks/usePutUpdateExpense';
import { useGetFilterPaymentMethod } from 'src/hooks/useGetFilterPaymentMethod';

export interface ExpenseModalProps {
  show?: boolean;
  trip?: ITrip;
  onClose?: () => void;
  onExpenseActionCompleted?: (expense: UseCreateExpenseParams | IUpdateExpenseParams) => void;
  initialExpense?: IExpense;
}

const { Option } = Select;

export const ExpenseModal: React.FC<ExpenseModalProps> = ({
  show,
  onClose,
  onExpenseActionCompleted,
  initialExpense,
  trip,
}) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();

  const [createExpense, { loading: creatingExpenseLoading }] = usePostCreateExpense();
  const [updateExpense, { loading: updateExpenseLoading }] = usePutUpdateExpense();
  const [{ data: paymentMethod = [], loading: paymentMethodLoading }] = useGetFilterPaymentMethod();

  const handleCancel = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  const handleExpenseRequest = async (params: UseCreateExpenseParams | IUpdateExpenseParams) => {
    let expense;
    if (initialExpense) {
      expense = await updateExpense(initialExpense.id, params);
    } else {
      expense = await createExpense({ params: params as UseCreateExpenseParams });
    }

    if (onExpenseActionCompleted && expense) {
      message.success(`Expense successfully ${initialExpense ? 'updated' : 'created'}`);
      onExpenseActionCompleted(params);
    }
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    if (values) {
      const newExpense: UseCreateExpenseParams = { ...values, branchId: activeBranch?.id!, tripId: trip?.id };
      handleExpenseRequest(newExpense);
    }
  };

  const methodofPaymentOptions = paymentMethod.map((methodofPayment: string) => {
    return (
      <Option key={methodofPayment} value={methodofPayment}>
        {methodofPayment}
      </Option>
    );
  });

  let onMethodofPaymentSearch;

  const loading = creatingExpenseLoading || updateExpenseLoading;

  return (
    <Modal
      title={initialExpense ? 'Update Trip' : 'Add Expense'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      <Form autoComplete="on" form={form} layout="vertical" initialValues={initialExpense}>
        <Form.Item
          label="Expense"
          name="name"
          rules={[{ required: true, message: 'Please provide a name for this expense' }]}
        >
          {trip ? tripExpensesOptions : <Input />}
        </Form.Item>

        <Form.Item
          label="Payment Method"
          name="paymentMethod"
          rules={[{ required: false, message: 'Please provide a payment method' }]}
        >
          <Select
            showSearch
            placeholder="Payment Method"
            filterOption={onMethodofPaymentSearch}
            loading={paymentMethodLoading}
          >
            {methodofPaymentOptions}
          </Select>
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: 'Please provide the amunt of the expense' }]}
        >
          <InputNumber className="fw" />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
