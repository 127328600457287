import { ITicket, TICKET_STATUS } from 'src/types/ticket.type';
import { ITravelSchedule } from 'src/types/travel.type';
import { ISeat } from 'src/types/seat-map.type';
import { PaginatedInterface } from 'src/types/type';

export enum PASSENGERT_TYPE {
  ADULT = 'Adult',
  CHILD = 'Child',
  INFANT = 'Infant',
}
export interface IPassengerDeprecated {
  id: number;
  full_name: string;
  type: PASSENGERT_TYPE;
  tracking_id: string; // this field if simply used for internally tracking the passengers unique entry.
  email: string;
  phone: string;
  age: number;
  gender: string;
  seat_number: number;
  is_primary_passenger: boolean;
  blood_group: string;
  route_id: number;
  travel_schedule_id: number;
  organization_id: number;
  next_kin_first_name: string;
  next_kin_last_name: string;
  next_kin_email: string;
  next_kin_phone: string;
  created_at: string;
  updated_at: string;
}

export interface IPassenger {
  id: number;
  pnr: string;
  fullName: string;
  type: PASSENGERT_TYPE;
  trackingId: string; // this field if simply used for internally tracking the passengers unique entry.
  phone?: string;
  age?: number;
  gender: string;
  seat?: ISeat;
  ticket?: ITicket;
  isPrimaryPassenger: boolean;
  tripId: number;
  bloodGroup: string;
  routeId: number;
  travelScheduleId: number;
  travelSchedule?: ITravelSchedule;
  organizationId: number;
  nextKinFirstName: string;
  nextKinLastName: string;
  nextKinEmail: string;
  nextKinPhone: string;
  createdAt: string;
  updatedAt: string;
}

export interface INewPassengerParams {
  fullName: string;
  type: PASSENGERT_TYPE;
  trackingId: string; // this field if simply used for internally tracking the passengers unique entry.
  phone?: string;
  gender: string;
  seatId?: number;
  isPrimaryPassenger: boolean;
  tripId: number;
  bloodGroup: string;
  routeId: number;
  travelScheduleId: number;
  organizationId: number;
  branchId: number;
  nextKinFirstName: string;
  nextKinLastName: string;
  nextKinPhone: string;
}

export interface IFindPassengersQueryParams extends PaginatedInterface {
  branchId: number;
  limit?: number;
  search?: string;
  ticketStatus?: TICKET_STATUS[];
}
export interface IPassengerManifest {
  id: number;
  fullName: string;
  gender: string;
  address: string;
  phone: string;
  age: number;
  nextOfKin: {
    nextKinFirstName: string;
    nextKinLastName: string;
    nextKinEmail: string;
    nextKinPhone: string;
  };
}
