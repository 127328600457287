import { ApolloQueryResult } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { USER_ROLE } from 'src/types/user.type';
import { useParams } from 'react-router-dom';
import { Exact } from 'src/graphql/generated/types';
import { useUpdateUser } from 'src/graphql/mutations/update-user';
import { GetUserQuery } from 'src/graphql/queries/__generated__/get-user.generated';
import { useEffect } from 'react';

export const RolesForm = ({
  roles,
  refetch,
}: {
  roles: string[];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            userId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetUserQuery>>;
}) => {
  const [form] = Form.useForm();

  const { id } = useParams();

  const [updateUser, { data, loading: updateUserLoading }] = useUpdateUser();

  const userRoles = [
    { name: 'User', value: 'role:user' },
    { name: 'Administrator', value: 'role:admin' },
    { name: 'Customer Support', value: 'role:customer_support' },
    { name: 'Operations', value: 'role:operations' },
    { name: 'System/IT Specialist', value: 'role:system_admin' },
    { name: 'Fleet Management', value: 'role:fleet_managment' },
    { name: 'Manager', value: 'role:manager' },
  ];

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    await updateUser({
      variables: {
        input: {
          roles: values.checkboxGroup,
        },
        userId: id!,
      },
    });
  };

  return (
    <div>
      <Form autoComplete="on" form={form} initialValues={{ checkboxGroup: roles }}>
        <Form.Item
          name="checkboxGroup"
          label="Roles"
          rules={[
            {
              required: true,
              message: 'Please select at least one option!',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              {userRoles?.map((item) => (
                <Col key={item.name} xs={12} sm={8} md={8} lg={4} xl={4}>
                  <Checkbox
                    value={item.value}
                    // eslint-disable-next-line max-len
                    disabled={item.value === USER_ROLE.Admin && roles && !roles.includes(USER_ROLE.Admin)}
                  >
                    {item.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Button key="submit" type="primary" onClick={handleSubmit} disabled={updateUserLoading}>
          Save
        </Button>
      </Form>
    </div>
  );
};
