import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const IncomeSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#E4F6FA'} />
      <path
        fill={pathFill ?? '#55BCD9'}
        d="M16.4487 14.5H19.8572C19.8942 14.502 19.9301 14.5128 19.9619 14.5316C19.9937 14.5503 20.0204
        14.5763 20.0396 14.6074L24.583 22.0661H29.1757V14.7256C29.175 14.6964 29.1802 14.6673 29.191 14.6401C29.2018
        14.6129 29.2181 14.588 29.2388 14.567C29.2595 14.546 29.2843 14.5293 29.3117 14.5178C29.3391 14.5063 29.3685
        14.5002 29.3983 14.5H32.5513C32.6108 14.5 32.6679 14.5232 32.71 14.5645C32.7521 14.6058 32.7757 14.6618 32.7757
        14.7202V22.0607H34.7829C34.8424 22.0607 34.8995 22.0839 34.9416 22.1252C34.9837 22.1665 35.0073 22.2225 35.0073
        22.2809V24.2664C35.0073 24.3248 34.9837 24.3809 34.9416 24.4222C34.8995 24.4635 34.8424 24.4867 34.7829
        24.4867H32.7757V26.1714H34.7829C34.8421 26.1714 34.8989 26.1943 34.9409 26.2353C34.983 26.2762 35.0068 26.3317
        35.0073 26.3898V28.3717C35.0068 28.4298 34.983 28.4854 34.9409 28.5263C34.8989 28.5672 34.8421 28.5902 34.7829
        28.5902H32.7757V36.2887C32.7757 36.3471 32.7521 36.4032 32.71 36.4445C32.6679 36.4858 32.6108 36.509 32.5513
        36.509H29.3983C29.3613 36.5069 29.3253 36.4961 29.2935 36.4774C29.2617 36.4587 29.2351 36.4326 29.2158
        36.4015L24.583 28.5902H20.0688V36.2887C20.0664 36.3465 20.041 36.4011 19.998 36.4406C19.955 36.4801 19.8978
        36.5015 19.8389 36.5H16.4487C16.3892 36.5 16.3321 36.4768 16.29 36.4355C16.2479 36.3942 16.2243 36.3382
        16.2243 36.2798V28.5902H14.2171C14.1592 28.5883 14.1042 28.5646 14.0638 28.5238C14.0233 28.4831 14.0004
        28.4286 14 28.3717V26.3898C14.0005 26.3317 14.0243 26.2762 14.0664 26.2353C14.1084 26.1943 14.1652 26.1714
        14.2244 26.1714H16.2316V24.4867H14.2244C14.195 24.4867 14.1658 24.481 14.1385 24.4699C14.1113 24.4588 14.0866
        24.4426 14.0657 24.4222C14.0236 24.3809 14 24.3248 14 24.2664V22.2863C14 22.2279 14.0236 22.1719 14.0657
        22.1306C14.1078 22.0893 14.1649 22.0661 14.2244 22.0661H16.2316V14.7256C16.2301 14.6675 16.2521 14.6112 16.2928
        14.5689C16.3334 14.5266 16.3895 14.5019 16.4487 14.5ZM20.0688 24.4867V26.1714H23.1707L22.3679
        24.4867H20.0688ZM26.7726 26.1714H29.1757V24.4867H25.9844L26.7726 26.1714ZM29.1757 28.5902H28.2104L29.1757
        30.5596V28.5902ZM20.0688 20.3652V22.0661H20.9027L20.0688 20.3652Z"
      />
    </BaseSVGIcons>
  );
};
