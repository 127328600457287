import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { IDestination } from 'src/types/branch.type';

export interface UseCreateDestinationParams {
  name: string;
  code: string;
  state: string;
  country: number;
}

export const useCreateDestination = () =>
  useUpdate<IDestination, UseCreateDestinationParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IDestination>(resources.destinationsUrl, payload);
    return data;
  });
