import { Select, Row, Col, Form, Typography } from 'antd';
import React from 'react';
import { useGetFilterPaymentMethod } from 'src/hooks/useGetFilterPaymentMethod';
import { useGetFilterTransactionStatus } from 'src/hooks/useGetFilterTransactionStatus';
import { useGetFilterTransactionType } from 'src/hooks/useGetFilterTransactionType';
import { ITransactionQueryParams } from 'src/hooks/useGetTransactions';
import { useGetUsers } from 'src/hooks/useGetUsers';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IUser, USER_ROLE } from 'src/types/user.type';

const { Option } = Select;
interface TransactionFilterProps {
  onChange: (data: ITransactionQueryParams) => void;
}

export const TransactionsFilter: React.FC<TransactionFilterProps> = ({ onChange }) => {
  const [form] = Form.useForm();
  const { activeBranch } = useBranch();

  const [{ data: ticketers = [], loading }] = useGetUsers({
    params: {
      branchId: activeBranch?.id!,
      roles: [
        USER_ROLE.Operations,
        USER_ROLE.Admin,
        USER_ROLE.CustomerSupport,
        USER_ROLE.Manager,
        USER_ROLE.SystemAdminstrator,
        USER_ROLE.User,
      ],
    },
  });

  const [{ data: paymentMethod = [], loading: paymentMethodLoading }] = useGetFilterPaymentMethod();
  const [{ data: transactionType = [], loading: transactionTypeLoading }] = useGetFilterTransactionType();
  const [{ data: transactionStatus = [], loading: transactionStatusLoading }] = useGetFilterTransactionStatus();

  const onFilterChange = async () => {
    const filterData: ITransactionQueryParams = await form.validateFields();
    onChange(filterData);
  };

  const listTheTicketers = ticketers.map((user: IUser) => (
    <Option key={user.id} value={user.id}>
      {`${user.firstName} ${user.lastName}`}
    </Option>
  ));

  return (
    <Form form={form}>
      <Row align="middle">
        <Col flex="70px">
          <Typography.Title level={5}>Filter:</Typography.Title>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Form.Item name="type" className="filters">
                <Select
                  placeholder="Transaction type"
                  bordered={false}
                  allowClear
                  onChange={onFilterChange}
                  loading={transactionTypeLoading}
                >
                  {transactionType.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="paymentMethod" className="filters">
                <Select
                  placeholder="Payment method"
                  bordered={false}
                  allowClear
                  onChange={onFilterChange}
                  loading={paymentMethodLoading}
                >
                  {paymentMethod.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="status" className="filters">
                <Select
                  placeholder="Status"
                  bordered={false}
                  allowClear
                  onChange={onFilterChange}
                  loading={transactionStatusLoading}
                >
                  {transactionStatus.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="ticketerId" className="filters">
                <Select
                  placeholder="Processed by"
                  bordered={false}
                  allowClear
                  onChange={onFilterChange}
                  loading={loading}
                >
                  {listTheTicketers}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
