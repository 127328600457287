import { useCallback, useState } from 'react';
import { RoutesService } from 'src/services/routes.service';
import { IRoute } from 'src/types/route.type';
import { useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

export const useGetRoutes = () =>
  useGet<IRoute[]>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.get<IRoute[]>(resources.routesUrl, {
      params: payload,
    });
    return data;
  });

export const useLazyGetRoutes = (): [
  (id: number) => Promise<void>,
  {
    data: IRoute[] | null;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<IRoute[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRoutes = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        const result = await RoutesService.getRoutes({ branchId: id });
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError, setLoading],
  );

  return [fetchRoutes, { data, loading, error }];
};
