import { Form, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MoneyText } from 'src/components/topography/MoneyText';
import { VehicleClassConfig } from 'src/graphql/queries/find-vehicle-class-configuration';
import { IVehicleClass } from 'src/types/vehicle.type';

export const vehicleClassColumns: ColumnsType<IVehicleClass> = [
  {
    title: 'Class',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'creaetdAt',
    render: (createdAt: string) => createdAt,
  },
];

export const vehicleClassConfigColumns: ColumnsType<VehicleClassConfig> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 400,
  },
  {
    title: 'Class',
    dataIndex: 'vehicleClass',
    key: 'vehicleClass',
    render: (vClass: VehicleClassConfig['vehicleClass']) => vClass.name,
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (charge: number) => <MoneyText value={charge} />,
  },
];

export const currencySelector = (
  <Form.Item name="currency" noStyle initialValue="NGN">
    <Select className="currency" disabled>
      <Select.Option value="NGN">NGN</Select.Option>
    </Select>
  </Form.Item>
);
