import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

import { useUpdate } from 'src/hooks/shared/useUpdate';
import camelcaseKeys from 'camelcase-keys';
import { IWaybill } from 'src/types/waybill.type';

export interface ITransferWayBillParams {
  branchId: number;
  waybillId: number;
  ticketerId: number;
  destinationId: number;
}

export const usePostTransferWaybill = () =>
  useUpdate<IWaybill, ITransferWayBillParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IWaybill>(`${resources.waybillUrl}/${payload.waybillId}/transfer`, payload);
    return camelcaseKeys(data, { deep: true });
  });
