import { useCallback, useEffect, useState } from 'react';
import { IncomeService } from 'src/services/income.service';
import { IIncome, IIncomeSearchQuery } from 'src/types/income.type';

export const useGetIncome = (
  params?: IIncomeSearchQuery,
): [
  { data: IIncome[] | undefined; loading: boolean; error: any },
  (params?: IIncomeSearchQuery) => Promise<IIncome[] | undefined>,
] => {
  const [data, setData] = useState<IIncome[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (payload?: IIncomeSearchQuery) => {
      let result;
      setLoading(true);
      try {
        result = await IncomeService.getIncome(payload);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);

      return result;
    },
    [setData, setError],
  );

  useEffect(() => {
    const getIncome = async () => {
      fetch(params);
    };
    getIncome();
  }, [fetch, params]);

  return [{ data, loading, error }, fetch];
};
