import { useCallback, useState } from 'react';
import { TravelService } from 'src/services/travel.service';
import { INewTravelScheduleParams, ITravelSchedule } from 'src/types/travel.type';

export const usePostCreateTravelSchedule = (): [
  (params: INewTravelScheduleParams) => Promise<ITravelSchedule | null>,
  {
    data: ITravelSchedule | null;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<ITravelSchedule | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const createTravelSchedule = useCallback(
    async (params: INewTravelScheduleParams) => {
      setLoading(true);
      try {
        const result = await TravelService.createTravelSchedule(params);
        setData(result);
        return result;
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return null;
    },
    [setData, setError, setLoading],
  );

  return [createTravelSchedule, { data, loading, error }];
};
