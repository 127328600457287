import camelcaseKeys from 'camelcase-keys';
import { UseGetType, useGet } from 'src/hooks//shared/useGet';
import { PaginatedResponse, api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IRouteFare } from 'src/types/route.type';
import { PaginatedInterface } from 'src/types/type';

export type UseGetRouteFaresParams = {
  branchId: number;
} & PaginatedInterface;

export const useGetRouteFares = ({ params }: UseGetType<UseGetRouteFaresParams>) =>
  useGet<PaginatedResponse<IRouteFare>, UseGetRouteFaresParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.get<PaginatedResponse<IRouteFare>>(resources.routeFaresUrl, {
        params: payload,
      });
      return camelcaseKeys(data, { deep: true });
    },
    { params },
  );
