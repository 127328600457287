import { Button, Modal, Form, Input, Row, Col, Alert, Select } from 'antd';
import React, { useEffect } from 'react';
import { ICreateRouteGroupParams, useCreateRouteGroup } from 'src/hooks/useCreateRouteGroup';
import { useGetRoutes } from 'src/hooks/useGetRoutes';
import { IUpdateRouteGroupParams, useUpdateRouteGroup } from 'src/hooks/useUpdateRouteGroup';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IRoute, IRoutesGroup } from 'src/types/route.type';
import { onRouteFilter } from 'src/utilities/helpers.utils';

const { Option } = Select;
export interface RoutesGroupProps {
  show: boolean;
  onClose: () => void;
  onRouteGroupActionCompleted: (routeGroup: IRoutesGroup) => void;
  initialData?: IRoutesGroup;
}

const RouteGroupModal: React.FC<RoutesGroupProps> = ({ show, onClose, onRouteGroupActionCompleted, initialData }) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();
  const [updateRouteGroup, { loading: updateLoading }] = useUpdateRouteGroup();
  const [createRouteGroup, { loading: createLoading }] = useCreateRouteGroup();
  const [{ data: routes, loading: loadingRoutes, error }] = useGetRoutes();

  const loading = createLoading || updateLoading || loadingRoutes;

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const setFormData = () => {
      if (initialData) {
        form.setFields([
          { name: 'name', value: initialData.name },
          { name: 'routeIds', value: initialData.routes?.map((r) => r.id) },
        ]);
      } else {
        form.resetFields();
      }
    };
    setFormData();
  }, [initialData]);

  const routeOptions =
    routes?.map((route: IRoute) => (
      <Option key={route.id} value={route.id}>
        {`${route.branch?.name}  → ${route.destination?.name}`}
      </Option>
    )) ?? [];

  const handleSave = async (values: any) => {
    let result;
    if (initialData) {
      const params = { ...values, routeGroupId: initialData.id } as IUpdateRouteGroupParams;
      result = await updateRouteGroup({ params });
    } else {
      const params = { ...values, branchId: activeBranch?.id } as ICreateRouteGroupParams;
      result = await createRouteGroup({ params });
    }

    if (result) {
      form.resetFields();
      onRouteGroupActionCompleted(result);
    }
  };

  const onRouteSearch = (input: string, option?: { value: number }) => {
    return onRouteFilter(routes ?? [], input, option);
  };

  return (
    <Modal
      title="New Route Group"
      open={show}
      closable={!loading && !updateLoading}
      confirmLoading={updateLoading || loading}
      maskClosable={loading || updateLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form autoComplete="on" form={form} layout="vertical" onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Group Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a group name' }]}
            >
              <Input placeholder="Enter group name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="routeIds" rules={[{ required: true, message: 'Please select a route' }]}>
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select a route"
                loading={loadingRoutes}
                filterOption={onRouteSearch}
              >
                {routeOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export { RouteGroupModal };
