import { gql } from '@apollo/client';

export const PRICE_BOOKING_FRAGMENT = gql`
  fragment PriceBookingFragment on BookingPrice {
    passengers {
      fare
      fullName
      type
    }
    schedule {
      branchName
      destinationName
      date
      time
      vehicleClass
      vehicleNo
    }
    totalFare
  }
`;
