/* eslint-disable react/jsx-filename-extension */
import { PermifyProvider } from '@permify/react-role';

export const PermifyContextProvider = ({ children }) => {
  return <PermifyProvider>{children}</PermifyProvider>;
};

PermifyContextProvider.propTypes = {
  children: typeof any,
};
