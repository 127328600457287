import { FC } from 'react';

import logo from 'src/icons/Logo.png';
import moment from 'moment';

import 'src/components/waybill-document/styles/waybillDocument.styles.less';
import { MoneyText } from 'src/components/topography/MoneyText';
import { textOrNA } from 'src/utilities/string.utils';
import { Descriptions } from 'antd';
import { PaymentMethodTag } from 'src/components/payment-method-tag/PaymentMethodTag';
import { IWaybill } from 'src/types/waybill.type';

interface WaybillDocumentProps {
  waybill: IWaybill;
}

export const WaybillDocument: FC<WaybillDocumentProps> = ({ waybill }) => {
  const date = moment(`${waybill.date}`);

  return (
    <div className="waybill-document">
      <div className="logo">
        <img src={logo} alt="company logo" />
      </div>
      <div className="header">
        <h4>WAYBILL</h4>
        <span>DATE: {date.format('Do MMM, YYYY')}</span>
      </div>

      <div className="content">
        <div className="row">
          <div className="col">
            <h4>REFERENCE</h4>
            <p>{waybill.reference}</p>
          </div>
          <div className="col">
            <h4>DESTINATION</h4>
            <p>{waybill.destination?.name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h4>SENDER&apos;S NAME</h4>
            <p>{waybill.sendersName}</p>
          </div>
          <div className="col">
            <h4>SENDER&apos;S PHONE</h4>
            <p>{waybill.sendersPhone}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>RECIPIENT&apos;S NAME</h4>
            <p>{waybill.recipientName}</p>
          </div>
          <div className="col">
            <h4>RECIPIENT&apos;S PHONE</h4>
            <p>{waybill.recipientPhone}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>WEIGHT</h4>
            <p>{waybill.weight ? `${waybill.weight}kg` : 'N/A'}</p>
          </div>
          <div className="col">
            <h4>QUANTITY</h4>
            <p>{waybill.quantity}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>VALUE</h4>
            <p>
              <MoneyText value={waybill.value ?? 0} />
            </p>
          </div>
          <div className="col">
            <h4>AMOUNT PAID</h4>
            <p>
              <MoneyText value={waybill?.amount ?? 0} />
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>PAYMENT METHOD</h4>
            <p>
              {waybill.transaction?.paymentMethod && <PaymentMethodTag method={waybill.transaction?.paymentMethod} />}
            </p>
          </div>
          <div className="col">
            <h4>DESCRIPTION</h4>
            <p>{textOrNA(waybill.description)}</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>PACKAGE TYPE</h4>
            <p>{waybill.type}</p>
          </div>
        </div>

        <div className="row mt-100">
          <div className="col">
            <div className="signature-line" />
            <p>Sender&apos;s Signature</p>
          </div>
          <div className="col">
            <Descriptions title="Authorised By" column={1}>
              <Descriptions.Item label="Name" labelStyle={{ fontWeight: 'bold' }}>
                {`${waybill.ticketer?.firstName} ${waybill.ticketer?.lastName}`}
              </Descriptions.Item>
              <Descriptions.Item label="Phone" labelStyle={{ fontWeight: 'bold' }}>
                {waybill.ticketer?.phone}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    </div>
  );
};
