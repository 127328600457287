import { useCallback, useEffect, useMemo, useState } from 'react';

export interface UseGetType<P = any> {
  params: P;
}

export type CallOptions<P = any> = Pick<UseGetType<P>, 'params'>;

export const useGet = <T = any, P = any>(
  fn: (options?: CallOptions<P>) => Promise<T>,
  options?: UseGetType<P>,
): [
  { data: T | undefined; loading: boolean; error: any },
  (callOptions?: CallOptions<P>) => Promise<T | undefined> | T | undefined,
] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const stringifiedParams = options?.params ? JSON.stringify(options.params) : '{}';

  const memoizedFunc = useCallback((callOptions?: CallOptions<P>) => fn(callOptions), [fn]);
  const memoizedParams = useMemo(() => JSON.parse(stringifiedParams), [stringifiedParams]);

  const fetch = useCallback(
    async (callOptions?: CallOptions<P>) => {
      let result;
      setLoading(true);

      try {
        result = await memoizedFunc(callOptions);
        setData(result);
      } catch (err: any) {
        const message = err?.response?.data?.message;
        setError(message ?? err.message);
      }
      setLoading(false);
      return result;
    },
    [memoizedFunc],
  );

  useEffect(() => {
    let callOptions: CallOptions;
    if (memoizedParams) {
      callOptions = { params: memoizedParams };
      fetch(callOptions);
    } else {
      fetch();
    }
  }, [memoizedParams]);

  return [{ data, loading, error }, fetch];
};
