import { Routes, Route } from 'react-router-dom';

import { AuthRoutes } from 'src/routes/AuthRoutes';
import { Login } from 'src/screens/login/Login';

export const BaseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<AuthRoutes />} />
    </Routes>
  );
};
