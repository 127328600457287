import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IBranch } from 'src/types/branch.type';

export class UserService {
  public static async getUserBranches(): Promise<IBranch[]> {
    const { data } = await api.get(`${resources.userUrl}/branches`);
    return camelcaseKeys(data, { deep: true });
  }
}
