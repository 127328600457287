import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const TicketSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#FCECF3'} />
      <g clipPath="url(#clip0_161922_102069)">
        <path
          fill={pathFill ?? '#EC5691'}
          // eslint-disable-next-line max-len
          d="M29.442 30.757a.265.265 0 00-.218-.11h-1.48c-.217 0-.344.247-.217.425l2.47 3.418a.266.266 0 00.433 0l3.823-5.29a.269.269 0 00-.217-.425h-1.483a.265.265 0 00-.218.11l-2.126 2.94-.767-1.068zm1.45-12.16v-1.608a.269.269 0 00-.268-.268H17.767a.269.269 0 00-.268.268v1.607c0 .148.12.268.268.268h12.857c.147 0 .268-.12.268-.268zm-13.125 2.946a.269.269 0 00-.268.268v1.607c0 .147.12.268.268.268h6.16c.148 0 .268-.12.268-.268V21.81a.269.269 0 00-.268-.268h-6.16zm6.16 15.335H14.82V13.306h18.75v9.91c0 .148.12.269.268.269h1.875c.148 0 .268-.12.268-.268v-11.25a1.07 1.07 0 00-1.071-1.072H13.48a1.07 1.07 0 00-1.071 1.072v26.25a1.07 1.07 0 001.071 1.071h10.446c.148 0 .268-.12.268-.267v-1.876a.269.269 0 00-.268-.267zm13.48-10.741l-6.428-2.234a.264.264 0 00-.087-.013.25.25 0 00-.087.013l-6.429 2.234a.266.266 0 00-.18.25v8.408c0 .084.036.16.103.211l6.429 5.029a.271.271 0 00.164.057c.057 0 .117-.02.164-.057l6.428-5.029a.27.27 0 00.104-.21v-8.408a.267.267 0 00-.18-.251zm-1.895 7.784l-4.62 3.613-4.62-3.613v-6.25l4.62-1.608 4.62 1.607v6.251z"
        />
      </g>
      <defs>
        <clipPath id="clip0_161922_102069">
          <path fill="#fff" d="M0 0H30V30H0z" transform="translate(10 10.5)" />
        </clipPath>
      </defs>
    </BaseSVGIcons>
  );
};
