import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IFindPassengersQueryParams, IPassenger } from 'src/types/passanger.type';
import { PaginatedRespose } from 'src/types/type';

export class PassengerService {
  public static async findPassengers(params: IFindPassengersQueryParams): Promise<PaginatedRespose<IPassenger>> {
    const { data } = await api.get(resources.passengerUrl, { params });
    return camelcaseKeys(data, { deep: true });
  }
}
