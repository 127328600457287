import { useEffect, useState } from 'react';
import { Table, Card } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import 'src/screens/reservations/styles/reservations.styles.less';
import { reservationColumns } from 'src/screens/reservations/ReservationsUtils';
import { Link } from 'react-router-dom';
import { SearchBar } from 'src/components/search-bar/SearchBar';
import { UseGetReservationsParams, useGetReservations } from 'src/hooks/useGetReservations';
import { usePage } from 'src/components/page/Page';

export const Reservations = () => {
  const { setTitle } = usePage();
  const [reservationFilter, setReservationFilter] = useState<UseGetReservationsParams>({
    page: 1,
    offset: 100,
  });

  const [{ data, loading }] = useGetReservations({ params: reservationFilter });

  useEffect(() => {
    setTitle('Reservations');
  }, [setTitle]);

  const onchange = async (value: string) => {
    setReservationFilter((currentReservationFilter) => {
      return {
        ...currentReservationFilter,
        search: value,
      };
    });
  };

  const onPageChange = (page: number) => {
    setReservationFilter({ ...reservationFilter, page });
  };

  const columns = [...reservationColumns];
  columns.push({
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: (id) => (
      <Link to={`/reservations/${id}`}>
        <EyeOutlined />
      </Link>
    ),
    fixed: 'right',
    width: 100,
  });

  return (
    <div className="routes-page-container">
      <Card
        className="card"
        bordered={false}
        style={{ marginRight: '0 !important' }}
        extra={[
          <div key="search-container" className="search-container">
            <SearchBar
              className="reservation-search"
              width={400}
              placeHolder="Input reference number"
              loading={loading}
              onSearch={onchange}
            />
          </div>,
        ]}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data?.items}
          loading={loading}
          bordered
          pagination={{
            pageSize: data?.meta.offset,
            total: data?.meta.totalItems,
            onChange: onPageChange,
            current: data?.meta.page,
          }}
        />
      </Card>
    </div>
  );
};
