import { Route, Routes } from 'react-router-dom';
import { Page } from 'src/components/page/Page';
import { ReservationProvider } from 'src/providers/reservation-provider/ReservationContext';
import { PassengerInformation } from 'src/screens/passenger-information/PassengerInformation';
import { PaymentConfirmation } from 'src/screens/payment-confirmation/PaymentConfirmation';
import { SearchTrip } from 'src/screens/search-trip/SearchTrip';
import { PaymentSuccessPage } from 'src/screens/success-page/SuccessPage';

export const TicketingRoutes = () => {
  return (
    <ReservationProvider>
      <Routes>
        <Route path="/" element={<Page title="Sales" />}>
          <Route index element={<SearchTrip />} />
          <Route path="/waybill" element={<SearchTrip />} />
          <Route path="/preview" element={<PaymentSuccessPage />} />
          <Route path="/passenger-information" element={<PassengerInformation />} />
          <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
          <Route path="/reservation-confirmation" element={<PaymentSuccessPage />} />
        </Route>
      </Routes>
    </ReservationProvider>
  );
};
