import React, { ReactNode, useEffect, useState } from 'react';
// import { BusSVGIcon } from 'src/icons';
import 'src/screens/dashboard/styles/dashboard.styles.less';
import { Card, Empty } from 'antd';
import { TripService } from 'src/services/trip.service';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITripEvent } from 'src/types/trip.type';
import { BusSVGIcon } from 'src/icons';
import moment from 'moment';

export const ACTIVITIES_CARD_TEST_ID = 'ACTIVITIES_CARD_TEST_ID';

interface ActivitiesItemProps {
  icon?: ReactNode;
  vehicleDetail?: string;
  route?: string;
  status?: string;
}

export const Item = (props: ActivitiesItemProps) => {
  const { icon, vehicleDetail, route, status } = props;
  return (
    <div className="activitiescard-item">
      <div className="activitiescard-item-left">
        {icon}
        <div className="details">
          <p>{vehicleDetail}</p>
          <p>{route}</p>
        </div>
      </div>
      <div className="activitiescard-item-right">
        <p className={`${status === 'Arrived' ? 'green' : 'blue'}`}>{status}</p>
      </div>
    </div>
  );
};

export const ActivitiesCard = () => {
  const { activeBranch } = useBranch();

  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<ITripEvent[]>([]);

  useEffect(() => {
    const getRecentEvents = async () => {
      setLoading(true);
      const tripEvents = await TripService.getEvents({ branchId: activeBranch?.id!, limit: 4 });
      setEvents(tripEvents);
      setLoading(false);
    };

    getRecentEvents();
  }, [activeBranch]);

  return (
    <Card data-testid={ACTIVITIES_CARD_TEST_ID} className="activities-card" loading={loading}>
      <div className="title">
        <p>Recent Events</p>
        <p>Status</p>
      </div>
      {events.length < 1 && <Empty />}
      {events.map((ev) => (
        <Item
          key={ev.id}
          icon={<BusSVGIcon />}
          vehicleDetail={moment(ev.date).format('YYYY-MM-DD hh:mm a')}
          status={ev.type!.name}
          route={`${ev.trip?.branch?.name} → ${ev.trip?.destination?.name}`}
        />
      ))}
    </Card>
  );
};
