/* eslint-disable indent */
import { Button, Table, Card, Space, Popconfirm, message, Modal, Input } from 'antd';
import { CloseCircleOutlined, EyeOutlined, PlusOutlined, PrinterOutlined, SwapOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';

import 'src/screens/passengers/styles/passengers.styles.less';
import 'src/screens/waybill/waybill-page/styles/waybillPage.style.less';
import moment from 'moment';
import { usePage } from 'src/components/page/Page';
import { IWaybill, WAYBILL_STATUS } from 'src/types/waybill.type';
import { Link, useNavigate } from 'react-router-dom';
import { WaybillFilter } from 'src/screens/waybill/WaybillFilter';
import { waybillColumns } from 'src/screens/waybill/WaybillUtils';
import { UseGetWaybillParams, useGetWaybills } from 'src/hooks/useGetWaybills';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { RBACContainer } from 'src/components/RBAC/RBACContainer';
import { USER_ROLE } from 'src/types/user.type';
import { WaybillTranserModal } from 'src/screens/waybill/waybill-form/WaybillTransferModal';
import { useCancelWaybill } from 'src/hooks/useCancelWaybill';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

export const WAYBILL_SALES_PAGE_TEST_ID = 'WAYBILL_SALES_PAGE_TEST_ID';

export const WaybillScreen = () => {
  const { setSubTitle, setTitle } = usePage();
  const [cancelWaybill] = useCancelWaybill();
  const { user } = useAuth();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [filter, setFilter] = useState<UseGetWaybillParams>({
    status: WAYBILL_STATUS.ISSUED,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
  });
  const [transferWaybill, setTransferWaybill] = useState<IWaybill | null>(null);
  const [cancelWaybillId, setCancelWaybillId] = useState<number | null>(null);

  const [{ data, loading }, fetch] = useGetWaybills({ params: filter });

  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Waybills');
    setSubTitle('View waybill information');
  }, []);

  const onTransferActionCompleted = async () => {
    await fetch({ params: filter });
    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onConfirmTransferWaybill = async (id: number) => {
    const filteredWaybill = data?.filter((w) => w.id === id);
    if (filteredWaybill) {
      setShowModal(true);
      setTransferWaybill(filteredWaybill[0]);
    }
  };

  const onConfirmCancelWaybill = async () => {
    if (!cancelWaybillId) return;

    if (!cancelReason.trim()) {
      message.warning('Please provide a reason for cancellation', 2.5);
      return;
    }

    const res = await cancelWaybill({ params: { id: cancelWaybillId, cancelReason } });

    if (res) {
      message.success('Waybill successfully cancelled', 2.5);
      setShowCancelModal(false);
      await fetch({ params: filter });
    } else {
      message.error('Something went wrong', 2.5);
    }
    setCancelWaybillId(null);
    setCancelReason('');
  };

  const openCancelModal = (id: number) => {
    setCancelWaybillId(id);
    setShowCancelModal(true);
  };

  const columns = [...waybillColumns];
  columns.push({
    title: 'Action',
    fixed: 'right',
    width: 140,
    render: (waybill: IWaybill) => (
      <Space split="|">
        <Link to={`${waybill.id}`}>
          <EyeOutlined />
        </Link>

        <Link to={`/waybill/${waybill?.id}/print`} target="_">
          <PrinterOutlined />
        </Link>
        {waybill?.status !== WAYBILL_STATUS.CANCELLED &&
          (user.roles?.includes(USER_ROLE.Admin) || user.roles?.includes(USER_ROLE.Manager)) && (
            <Popconfirm
              title="This waybill will be transferred?"
              onConfirm={() => onConfirmTransferWaybill(waybill.id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <SwapOutlined style={{ color: '#74befe' }} />
            </Popconfirm>
          )}

        {waybill?.status !== WAYBILL_STATUS.CANCELLED && user.roles?.includes(USER_ROLE.Admin) && (
          <CloseCircleOutlined
            style={{ color: '#c0392b' }}
            onClick={() => openCancelModal(waybill.id)}
          />
        )}
      </Space>
    ),
  });

  return (
    <RBACContainer
      roles={[USER_ROLE.Admin, USER_ROLE.Manager, USER_ROLE.SystemAdminstrator, USER_ROLE.Operations]}
      data-testid={WAYBILL_SALES_PAGE_TEST_ID}
    >
      <WaybillFilter onApply={setFilter} />
      <Card
        bordered={false}
        extra={[
          <div className="waybill-extra-container">
            <Button
              key="add-new-bus"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/ticketing/waybill')}
            >
              Add New
            </Button>
          </div>,
        ]}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data ?? []}
          loading={loading}
          pagination={false}
          scroll={{ x: 1700 }}
        />
      </Card>

      <Modal
        title="Cancel Waybill"
        open={showCancelModal}
        onOk={onConfirmCancelWaybill}
        onCancel={() => setShowCancelModal(false)}
        okText="Yes"
        cancelText="No"
      >
        <Input.TextArea
          placeholder="Enter reason for cancellation"
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </Modal>

      {showModal && transferWaybill && (
        <WaybillTranserModal
          waybill={transferWaybill}
          show={showModal}
          onExpenseActionCompleted={onTransferActionCompleted}
          onClose={onCancel}
        />
      )}
    </RBACContainer>
  );
};
