import { FurnitureSVGIcon, GroceriesSVGIcon, ParcelSVGIcon } from 'src/icons';

export const getWayBillIcon = (type: string) => {
  switch (type) {
    case 'clothing parcel':
      return ParcelSVGIcon;
    case 'furniture parcel':
      return FurnitureSVGIcon;
    case 'food parcel':
      return GroceriesSVGIcon;
    default:
      return ParcelSVGIcon;
  }
};
