import React from 'react';
import { Button, Card, Tag } from 'antd';
import { CalendarOutlined, ClockCircleFilled, NodeIndexOutlined, TeamOutlined } from '@ant-design/icons';

import 'src/screens/search-trip/search-trip-item/styles/searchTripItem.styles.less';

import { useNavigate } from 'react-router-dom';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { ITravelSchedule } from 'src/types/travel.type';
import { MoneyText } from 'src/components/topography/MoneyText';

export interface ISearchItemProps {
  price: number;
  time: string;
  schedule: ITravelSchedule;
}

export const SearchTripItem: React.FC<ISearchItemProps> = (props) => {
  const navigate = useNavigate();

  const { setSchedule, getPassengerCount } = useReservation();
  const { price, time, schedule } = props;

  const onItemSelected = () => {
    setSchedule(schedule);
    navigate('passenger-information');
  };

  return (
    <div className="container">
      <Card className="card">
        <div className="result">
          <div className="details">
            <h1 className="vehicle-class">
              {schedule.route?.destination?.name} ({schedule.route?.destination?.code})
            </h1>
            <span>
              <NodeIndexOutlined /> Final Destination:{' '}
              <Tag color="blue">{schedule.trip?.destination?.name.toUpperCase()}</Tag>
            </span>
            <span>
              <CalendarOutlined /> {schedule.trip?.date} <ClockCircleFilled className="clock" /> {time}
            </span>
            <p className="travellers">
              <TeamOutlined /> Passenger(s): {getPassengerCount()}
            </p>
          </div>
          <div className="price">
            <h1>
              <MoneyText value={price} />
            </h1>
          </div>
        </div>
        <div className="btn-right">
          <Button type="primary" onClick={onItemSelected}>
            Book Trip
          </Button>
        </div>
      </Card>
    </div>
  );
};
