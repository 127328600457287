import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const PassengerSVGIcon = ({ rectFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <rect width="50" height="50" fill={rectFill ?? '#FCECF3'} rx="4" />
      <path
        fill={pathFill ?? '#EC5691'}
        // eslint-disable-next-line max-len
        d="M35.453 31.296a10.097 10.097 0 00-2.892-2.022 6.681 6.681 0 002.484-5.207c0-3.71-3.094-6.753-6.804-6.696a6.7 6.7 0 00-6.596 6.697c0 2.102.971 3.977 2.485 5.206a10.078 10.078 0 00-2.893 2.022 9.98 9.98 0 00-2.933 6.827.267.267 0 00.268.275h1.875a.267.267 0 00.268-.258A7.569 7.569 0 0122.948 33a7.587 7.587 0 015.4-2.236c2.04 0 3.958.793 5.401 2.237a7.598 7.598 0 012.233 5.139.267.267 0 00.268.258h1.875a.267.267 0 00.268-.275 9.956 9.956 0 00-2.94-6.827zm-7.104-2.943a4.25 4.25 0 01-3.03-1.255 4.248 4.248 0 01-1.256-3.074 4.291 4.291 0 011.215-2.947 4.268 4.268 0 013.027-1.295 4.31 4.31 0 013.047 1.225 4.253 4.253 0 011.286 3.06c0 1.146-.446 2.22-1.256 3.03a4.263 4.263 0 01-3.033 1.256zm-8.388-3.402a8.517 8.517 0 01.097-2.44.27.27 0 00-.15-.295 4.23 4.23 0 01-1.236-.84 4.27 4.27 0 01-1.296-3.195 4.263 4.263 0 011.216-2.866 4.263 4.263 0 013.12-1.295 4.28 4.28 0 013.563 1.968.267.267 0 00.311.107 8.307 8.307 0 011.852-.415.269.269 0 00.21-.388 6.708 6.708 0 00-5.882-3.68c-3.713-.057-6.807 2.987-6.807 6.693 0 2.103.968 3.978 2.484 5.207a10.066 10.066 0 00-2.896 2.022 9.959 9.959 0 00-2.94 6.83.269.269 0 00.268.275h1.879a.267.267 0 00.268-.258 7.568 7.568 0 012.233-5.14 7.56 7.56 0 013.505-1.998.268.268 0 00.201-.292z"
      />
    </BaseSVGIcons>
  );
};
