import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { ITransaction, PAYMENT_METHODS } from 'src/types/transaction.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { ITicket } from 'src/types/ticket.type';
import { IReservation } from 'src/hooks/useCreateReservation';

export interface UseCompleteReservationParams {
  reservationId: number;
  paymentMethod: PAYMENT_METHODS;
}

export const useCompleteReservation = () =>
  useUpdate<{ tickets: ITicket[]; transaction: ITransaction; reservation: IReservation }, UseCompleteReservationParams>(
    async (options) => {
      const payload = { ...options?.params, noTransform: true };
      const { data } = await api.post<{ tickets: ITicket[]; transaction: ITransaction; reservation: IReservation }>(
        resources.reservationCompleteUrl,
        payload,
      );
      return data;
    },
  );
