/* eslint-disable max-len */
import camelcaseKeys from 'camelcase-keys';
import { UseUpdateRouteParams } from 'src/hooks/useUpdateRoute';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { IBranch, IDestination } from 'src/types/branch.type';
import {
  GetRoutesQueryParam,
  IRoute,
  IRouteFare,
  IRouteFareDeprecated,
  IRoutesGroup,
  NewRoutePayload,
  GetRouteFaresQueryParams,
  GetRouteGroupQueryParam,
} from 'src/types/route.type';

export class RoutesService {
  public static async getRoutes(params: GetRoutesQueryParam): Promise<IRoute[]> {
    const { data } = await api.get(resources.routesUrl, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async getRouteGroup(params: GetRouteGroupQueryParam): Promise<IRoutesGroup[]> {
    const { data } = await api.get(`${resources.routeGroupUrl}`, { params });
    return camelcaseKeys(data, { deep: true });
  }

  public static async getRouteGroupRoutes(id: number): Promise<IRoute[]> {
    const { data } = await api.get(`${resources.routeGroupUrl}/${id}/routes`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async addNewRoute(payload?: NewRoutePayload): Promise<IRoute> {
    const { data } = await api.post(resources.routesUrl, { ...payload, noTransform: true });
    return camelcaseKeys(data, { deep: true });
  }

  public static async getBranches(): Promise<IBranch[]> {
    const { data } = await api.get<IBranch[]>(resources.branchesUrl);
    return data;
  }

  public static async getDestinations(): Promise<IDestination[]> {
    const { data } = await api.get<IDestination[]>(resources.destinationsUrl);
    return data;
  }

  public static async getRoutesFare(params: GetRouteFaresQueryParams): Promise<IRouteFare[]> {
    const { data } = await api.get<IRouteFareDeprecated[]>(resources.routeFaresUrl, { params });
    return camelcaseKeys<IRouteFareDeprecated[]>(data, { deep: true });
  }

  public static async getRouteById(id: number): Promise<IRoute> {
    const { data } = await api.get(`${resources.routesUrl}/${id}`);
    return camelcaseKeys(data, { deep: true });
  }

  public static async updateRoute(payload: UseUpdateRouteParams, routeId: number): Promise<IRoute> {
    const { data } = await api.patch(`${resources.routesUrl}/${routeId}`, payload);
    return camelcaseKeys(data, { deep: true });
  }
}
