import { Button, Modal, Form, Input, Row, Col, Alert } from 'antd';
import React, { useEffect } from 'react';
import { ICreateVehicleClassParams, useCreateVehicleClass } from 'src/hooks/useCreateVehicleClass';
import { IUpdateVehicleClassParams, useUpdateVehicleClass } from 'src/hooks/useUpdateVehicleClass';

import 'src/screens/vehicle-class/vehicle-class-modal/styles/vehicleClassModal.style.less';
import { IVehicleClass } from 'src/types/vehicle.type';

export interface VehicleClassProps {
  show: boolean;
  onClose: () => void;
  onActionComplete: (vehicleClass: IVehicleClass) => void;
  defaultVehicleClass?: IVehicleClass;
}

export const VehicleClassModal: React.FC<VehicleClassProps> = ({
  show,
  onClose,
  onActionComplete,
  defaultVehicleClass,
}) => {
  const [createVehicleClass, { loading: createVehicleClassLoading, error: createError }] = useCreateVehicleClass();
  const [updateVehicleClass, { loading: updateVehicleClassLoading, error: updateError }] = useUpdateVehicleClass();
  const [form] = Form.useForm();

  const loading = createVehicleClassLoading || updateVehicleClassLoading;
  const error = createError || updateError;

  useEffect(() => {
    if (!show) {
      form.resetFields();
      form.setFieldValue('currency', 'NGN');
    }
  }, [show]);

  useEffect(() => {
    if (defaultVehicleClass) {
      form.resetFields();
      form.setFieldsValue({ ...defaultVehicleClass, currency: 'NGN' });
    }
  }, [defaultVehicleClass]);

  const handleSave = async ({ name }: { name: string }) => {
    let result;
    if (defaultVehicleClass) {
      const params: IUpdateVehicleClassParams = {
        vehicleClassId: defaultVehicleClass.id,
        name,
      };
      result = await updateVehicleClass({ params });
    } else {
      const params: ICreateVehicleClassParams = { name };
      result = await createVehicleClass({ params });
    }

    if (result && onActionComplete) {
      onActionComplete(result);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={defaultVehicleClass ? 'Edit Vehicle Class' : 'Add Vehicle Class'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form autoComplete="on" layout="vertical" form={form} onFinish={handleSave}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Class name"
              rules={[{ required: true, message: 'Please input a class name' }]}
            >
              <Input placeholder="Enter class name" size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
