import { useCallback, useEffect, useState } from 'react';
import { AnalyticService } from 'src/services/analytics.service';
import { ITermialStatistics, ITerminalStatQueryFilter } from 'src/types/dashboardQuery.type';

export const useGetTerminalStatstics = (
  params: ITerminalStatQueryFilter,
): [
  { data: ITermialStatistics | undefined; loading: boolean; error: any },
  (queryParams: ITerminalStatQueryFilter) => Promise<void>,
] => {
  const [data, setData] = useState<ITermialStatistics>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (queryParams: ITerminalStatQueryFilter) => {
      setLoading(true);
      try {
        const result = await AnalyticService.getTerminalStatstics(queryParams);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError, setLoading],
  );

  useEffect(() => {
    const getStatistics = async () => {
      fetch(params);
    };
    getStatistics();
  }, [fetch, params]);

  return [{ data, loading, error }, fetch];
};
