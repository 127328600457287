export enum SEAT_STATUS {
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
  OCCUPIED = 'occupied',
  RESERVED = 'reserved',
}

export interface ISeatMap {
  id: number;
  width: number;
  length: number;
  seatsCount: number;
  startSeatRow: number;
  endSeatRow: number;
  name: null | string;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface ISeat {
  number: string;
  id: number;
  row: number;
  column: number;
  status: SEAT_STATUS;
}
