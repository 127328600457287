/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { FC, ReactNode } from 'react';
import { GiSteeringWheel } from 'react-icons/gi';
import { TripSeatMapSeat } from 'src/graphql/queries/get-trip-seatMap';

import 'src/components/seat/styles/seatSelector.styles.less';
import { SeatColorSheme } from 'src/components/seat/seatSelectorUtils';
import { SeatStatusEnum } from 'src/graphql/generated/types';

export interface SeatSelectorProps {
  seatMap: (TripSeatMapSeat | undefined)[][];
  onChange: (seatid: string) => void;
  selected: string;
}

export interface SeatRowProps {
  rowIndex: number;
  row: (TripSeatMapSeat | undefined)[];
  onChange: (seatid: string) => void;
  selected: string;
}

export interface SeatBoxProps {
  seat?: TripSeatMapSeat;
  icon?: ReactNode;
  status?: SeatStatusEnum | undefined;
  onChange: (seatid: string) => void;
  selected: string;
}

type ColorSchemeKey = keyof typeof SeatColorSheme;

export const SeatBox: FC<SeatBoxProps> = ({ seat, icon, status, onChange, selected }) => {
  const background = status ? SeatColorSheme[status] : undefined;
  const disabled =
    !seat ||
    status === SeatStatusEnum.Blocked ||
    status === SeatStatusEnum.Reserved ||
    status === SeatStatusEnum.Occupied;

  const handleClick = () => {
    if (seat?.number) {
      onChange(seat.id);
    }
  };

  return (
    <div>
      <button
        disabled={disabled}
        className="seat-box"
        type="button"
        onClick={handleClick}
        style={{
          backgroundColor: seat?.number ? (seat?.id === selected ? '#d35400' : background) : 'white',
          cursor: !seat?.number || disabled ? 'default' : 'pointer',
        }}
      >
        {icon ?? seat?.number ?? ''}
      </button>
    </div>
  );
};

const SeatRoW: FC<SeatRowProps> = ({ row, rowIndex, onChange, selected }) => {
  return (
    <div className={row.length === 5 ? 'seat-row-5' : 'seat-row'}>
      {row.map((r, i) => {
        let icon;
        if (rowIndex === 0 && i === 0) icon = <GiSteeringWheel className="steering" />;
        return (
          <SeatBox
            key={`${i}_box`}
            seat={r}
            icon={icon}
            selected={selected}
            status={r?.status}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

export const CheackInSeatSelector: FC<SeatSelectorProps> = ({ seatMap, onChange, selected }) => {
  return (
    <div className="seat-selector">
      <div className="seat-selector-color-display-container">
        {(Object.keys(SeatColorSheme) as ColorSchemeKey[]).map((key: ColorSchemeKey) => (
          <div key={key} className="seat-display-color-box-main">
            <div style={{ backgroundColor: SeatColorSheme[key] }} className="seat-display-color-box" />
            <p>{key}</p>
          </div>
        ))}
      </div>
      <div>
        {seatMap.map((row, i) => (
          <SeatRoW rowIndex={i} key={`${i}_seat_row`} row={row} onChange={onChange} selected={selected} />
        ))}
      </div>
    </div>
  );
};
