import { IPassenger } from 'src/types/passanger.type';
import { IRoute } from 'src/types/route.type';
import { ITravelSchedule } from 'src/types/travel.type';
import { IUser } from 'src/types/user.type';
import { ITrip } from 'src/types/trip.type';
import { IBranch, IDestination } from 'src/types/branch.type';
import { ITransaction } from 'src/types//transaction.type';
import { PaginatedInterface } from 'src/types/type';

export enum TICKET_STATUS {
  ON_HOLD = 'on_hold',
  ISSUED = 'issued',
  CANCELLED = 'cancelled',
  OPEN = 'open',
}

export enum RESERVATION_STATUS {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export interface ITicket {
  id: number;
  departureDate: Date;
  departureTime: string;
  passengerId: number;
  passenger?: IPassenger;
  travelScheduleId: number;
  travelSchedule?: ITravelSchedule;
  status: string;
  ticketerId: number;
  routeId: number;
  tripId?: number;
  trip?: ITrip;
  route?: IRoute;
  ticketer?: IUser;
  groupTag: string;
  charge: number;
  serviceCharge: number;
  cancelledAt: string;
  createdAt: Date;
  updatedAt: Date;
  reservationId: number;
}

export interface ITicketsQueryParams {
  id?: number;
  departureDate?: string;
  departureTime?: string;
  status?: TICKET_STATUS;
  branchId: number;
}

export interface IReservations {
  branchId: number;
  branch: IBranch;
  cancelledAt: any;
  cancelledBy: any;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  createdAt: string;
  destinationId: number;
  destination: IDestination;
  id: number;
  issuesById: number;
  issuesBy: IUser;
  reference: string;
  scheduleId: number;
  schedule: ITravelSchedule;
  transactionId: number;
  transaction: ITransaction;
  tickets: ITicket[];
  tripId: number;
  trip: ITrip;
  updatedAt: string;
}

export interface IReservationsDetails {
  branchId: number;
  branch: IBranch;
  cancelledAt: any;
  cancelledBy: any;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  createdAt: string;
  destinationId: number;
  destination: IDestination;
  id: number;
  issuesById: number;
  issuesBy: IUser;
  reference: string;
  scheduleId: number;
  schedule: ITravelSchedule;
  transactionId: number;
  transaction: ITransaction;
  tickets: ITicket[];
  tripId: number;
  trip: ITrip;
  updatedAt: string;
}

export interface IFindReservationsQueryParams extends PaginatedInterface {
  branchId: number;
  search?: string;
  limit?: number;
}
