import { useEffect, useState } from 'react';
import { Empty, message } from 'antd';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { TripService } from 'src/services/trip.service';
import { ITrip, ITripEvent, ITripEventType } from 'src/types/trip.type';
import { TripEventTimeLine } from 'src/screens/trips/components/TripEventTimeLine';
import { TripEventForm } from 'src/screens/trips/components/TripEventForm';
import { isClosed } from 'src/screens/trips/utils/trip-utils';

export const TripEvents = ({ trip }: { trip: ITrip }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<ITripEvent[]>([]);
  const [eventTypes, setEventTypes] = useState<ITripEventType[]>([]);

  useEffect(() => {
    const getEventTypes = async () => {
      setLoading(true);
      const [eventsData, eventTypeData] = await Promise.all([
        TripService.getTripEvents(trip.id),
        TripService.getTripEventTypes(),
      ]);

      setEventTypes(eventTypeData);
      setEvents(eventsData);
      setLoading(false);
    };

    getEventTypes();
  }, [setLoading, setEvents, trip]);

  const onEventCreated = (newEvent: ITripEvent) => {
    const updatedList = [...events];
    updatedList.unshift(newEvent);
    setEvents(updatedList);
    message.success('Event successfully logged');
  };

  const isClosedTrip = isClosed(trip);

  if (loading) {
    return <LoadingView />;
  }

  return (
    <>
      {!isClosedTrip && <TripEventForm trip={trip} eventTypes={eventTypes} onEventCreated={onEventCreated} />}
      <TripEventTimeLine tripEvents={events} />
      {!events.length && <Empty />}
    </>
  );
};
