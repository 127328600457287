import { useCallback, useEffect, useState } from 'react';
import { PassengerService } from 'src/services/passenger.service';
import { IFindPassengersQueryParams, IPassenger } from 'src/types/passanger.type';
import { PaginatedRespose } from 'src/types/type';

export const useGetPassengers = (
  params: IFindPassengersQueryParams,
): [
  { data: PaginatedRespose<IPassenger> | undefined; loading: boolean; error: any },
  (params: IFindPassengersQueryParams) => Promise<void>,
] => {
  const [data, setData] = useState<PaginatedRespose<IPassenger>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(
    async (payload: IFindPassengersQueryParams) => {
      setLoading(true);
      try {
        const result = await PassengerService.findPassengers(payload);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
    },
    [setData, setError],
  );

  useEffect(() => {
    const getPassengers = async () => {
      fetch(params);
    };
    getPassengers();
  }, [fetch, params]);

  return [{ data, loading, error }, fetch];
};
