import camelcaseKeys from 'camelcase-keys';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { ITermialStatistics, ITerminalStatQueryFilter } from 'src/types/dashboardQuery.type';

export class AnalyticService {
  public static async getTerminalStatstics(params: ITerminalStatQueryFilter): Promise<ITermialStatistics> {
    const { data } = await api.get(resources.analyticsUrl, { params });
    return camelcaseKeys(data, { deep: true });
  }
}
