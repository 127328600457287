import { CloseCircleOutlined } from '@ant-design/icons';
import { message, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { useDeleteExpense } from 'src/hooks/useDeleteExpense';

export interface IDeleteExpenseProps {
  id: number;
  onDelete: () => void;
}

export const DeleteExpenseAction = ({ id, onDelete }: IDeleteExpenseProps) => {
  const [deleteExpense, { error }] = useDeleteExpense();

  useEffect(() => {
    if (error) message.error(error?.response?.data?.message);
  }, [error]);

  const onConfirm = async () => {
    const deleted = await deleteExpense(id);

    if (deleted) {
      message.success('Expense successfully deleted');
      onDelete();
    }
  };

  return (
    <Popconfirm title="Are you sure to delete this expense?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <CloseCircleOutlined style={{ color: '#c0392b' }} />
    </Popconfirm>
  );
};
