import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import { useLazyGet } from 'src/hooks/shared/useLazyGet';
import { IDestination } from 'src/types/branch.type';

export type UseGetDestinationParams = {
  id: number;
};

export const useLazyGetDestinationById = () =>
  useLazyGet<IDestination, UseGetDestinationParams>(async (options) => {
    const payload = { ...options?.params };
    const { data } = await api.get<IDestination>(resources.destinationByIdUrl(payload.id as number));
    return data;
  });
