import { BaseSVGIcons, SVGIconProps } from 'src/icons/BaseSVGIcons';

export const RevenueSVGIcon = ({ circleFill, pathFill }: SVGIconProps) => {
  return (
    <BaseSVGIcons>
      <circle cx="25" cy="25.5" r="25" fill={circleFill ?? '#EEECFD'} />
      <path
        fill={pathFill ?? '#7F77F7'}
        // eslint-disable-next-line max-len
        d="M37.59 34.876H14.553V13.983a.269.269 0 00-.268-.268H12.41a.269.269 0 00-.268.268v23.035c0 .148.12.268.268.268H37.59c.147 0 .267-.12.267-.268v-1.875a.269.269 0 00-.267-.267zm-20.626-2.143h17.947c.147 0 .268-.12.268-.268V17.867a.27.27 0 00-.459-.191l-7.041 7.041-4.199-4.152a.269.269 0 00-.378 0l-6.328 6.348a.264.264 0 00-.077.188v5.364c0 .147.12.268.268.268z"
      />
    </BaseSVGIcons>
  );
};
