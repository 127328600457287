import React, { useEffect } from 'react';
import { Button, Form, InputNumber, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip } from 'src/types/trip.type';
import { IIncome, INewIncomeParams, IUpdateIncomeParams } from 'src/types/income.type';
import { usePostCreateIncome } from 'src/hooks/usePostCreateIncome';
import { useUpdateIncome } from 'src/hooks/useUpdateIncome';

export interface IncomeModalProps {
  show?: boolean;
  trip?: ITrip;
  onClose?: () => void;
  onIncomeActionCompleted?: (expense: IIncome) => void;
  initialIncome?: IIncome;
}

export const IncomeModal: React.FC<IncomeModalProps> = ({
  show,
  onClose,
  onIncomeActionCompleted,
  initialIncome,
  trip,
}) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();

  const [createIncome, { loading: creatingIncomeLoading }] = usePostCreateIncome();
  const [updateIncome, { loading: updateIncomeLoading }] = useUpdateIncome();

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show]);

  const handleCancel = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  const handleIncomeRequest = async () => {
    let incomeResponse;

    const values = await form.validateFields();
    if (!values) return;

    const params = { ...values, branchId: activeBranch?.id!, tripId: trip?.id };

    if (initialIncome) {
      incomeResponse = await updateIncome(initialIncome.id, params as IUpdateIncomeParams);
    } else {
      incomeResponse = await createIncome(params as INewIncomeParams);
    }

    if (onIncomeActionCompleted && incomeResponse) {
      message.success(`Income successfully ${initialIncome ? 'updated' : 'created'}`);
      onIncomeActionCompleted(incomeResponse);
    }
  };

  const loading = creatingIncomeLoading || updateIncomeLoading;

  return (
    <Modal
      title={initialIncome ? 'Update Income' : 'Add Income'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={handleIncomeRequest}>
          Save
        </Button>,
      ]}
    >
      <Form autoComplete="on" form={form} layout="vertical" initialValues={initialIncome}>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: 'Please provide the amount of the income' }]}
        >
          <InputNumber className="fw" />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
