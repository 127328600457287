import { Space, Typography } from 'antd';
import moment from 'moment';

import { ITripEvent } from 'src/types/trip.type';

export const EventTimeLineItem = ({ event }: { event: ITripEvent }) => {
  return (
    <Space direction="vertical">
      <Typography.Title level={5}>{event.type?.name}</Typography.Title>
      <Typography.Text strong>{moment(event.date).format('hh:mm A MMMM Do YYYY')}</Typography.Text>
      <Typography.Paragraph>{event.description}</Typography.Paragraph>
    </Space>
  );
};
