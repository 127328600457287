import { useCallback, useEffect, useState } from 'react';
import { TripService } from 'src/services/trip.service';
import { ITripSeatMap } from 'src/types/trip.type';

export const useGetTripSeatMap = (
  tripId: number,
): [{ data: ITripSeatMap | undefined; loading: boolean; error: any }, () => Promise<void>] => {
  const [data, setData] = useState<ITripSeatMap>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState();

  const fetch = useCallback(async () => {
    setLoading(true);

    try {
      const seats = await TripService.getTripSeatmap(tripId);
      setData(seats);
    } catch (err: any) {
      setError(err);
    }

    setLoading(false);
  }, [tripId]);

  useEffect(() => {
    const getTripSeats = async () => {
      fetch();
    };
    getTripSeats();
  }, [tripId, fetch]);

  return [{ data, loading, error }, fetch];
};
