export const Transfer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
      <path
        fill="#E46F77"
        d="M0 4.562v-.625C0 3.42.42 3 .938 3H15V1.125c0-.835 1.012-1.252 1.6-.663l3.125
         3.125a.938.938 0 010 1.326L16.6 8.038c-.586.586-1.6.175-1.6-.663V5.5H.937A.937.937
          0 010 4.562zM19.063 10.5H5V8.625c0-.833-1.01-1.253-1.6-.663L.275 11.087a.938.938
           0 000 1.326L3.4 15.538c.587.587 1.6.174 1.6-.663V13h14.063c.517
           0 .937-.42.937-.938v-.624a.937.937 0 00-.938-.938z"
      />
    </svg>
  );
};

export const Cash = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" fill="none" viewBox="0 0 20 12">
      <path
        fill="#17D97C"
        d="M19 0H1a1 1 0 00-1 1v10a1 1 0 001 1h18a1 1 0 001-1V1a1 1
         0 00-1-1zM1.5 10.5v-2a2 2 0 012 2h-2zm0-7v-2h2a2 2 0 01-2
          2zM10 9C8.62 9 7.5 7.657 7.5 6S8.62 3 10 3s2.5 1.343 2.5
           3-1.12 3-2.5 3zm8.5 1.5h-2a2 2 0 012-2v2zm0-7a2 2 0 01-2-2h2v2z"
      />
    </svg>
  );
};

export const Pos = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
      <path
        fill="#184AAB"
        d="M0 14.111c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747
         1.667-1.667V8H0v6.111zm6.667-2.361c0-.23.187-.417.416-.417h4.723c.229
          0 .416.188.416.417v1.389c0 .229-.187.417-.416.417H7.083a.418.418
          0 01-.416-.417v-1.39zm-4.445 0c0-.23.188-.417.417-.417h2.5c.23
          0 .417.188.417.417v1.389c0 .229-.188.417-.417.417h-2.5a.418.418
           0 01-.417-.417v-1.39zM20 1.889v1.666H0V1.89C0 .969.747.222
           1.667.222h16.666C19.253.222 20 .97 20 1.89z"
      />
    </svg>
  );
};
