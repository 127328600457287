import { useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { ISeatMap } from 'src/types/seat-map.type';

export const useGetSeatMaps = () =>
  useGet<ISeatMap[]>(async () => {
    const { data } = await api.get<ISeatMap[]>(resources.seatMapUrl);
    return camelcaseKeys(data, { deep: true });
  });
