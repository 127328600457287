import { Result } from 'antd';
import { Link } from 'react-router-dom';

export const UnauthorizedPage = () => {
  return (
    <Result
      status="403"
      title="Unauthorised Access"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link replace to="/">
          Back Home
        </Link>
      }
    />
  );
};
