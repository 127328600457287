import { gql, QueryResult } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  TripSeatMapQuery,
  TripSeatMapQueryVariables,
} from 'src/graphql/queries/__generated__/get-trip-seatMap.generated';

export type TripSeatMapSeat = TripSeatMapQuery['tripSeatMap']['seats'][number];

const GET_TRIP_SEATMAP_QUERY = gql`
  query TripSeatMap($tripId: String!) {
    tripSeatMap(tripId: $tripId) {
      endSeatRow
      organizationId
      length
      startSeatRow
      width
      id
      seats {
        id
        seatMapId
        number
        column
        row
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const useGetTripSeatmapQuery = (tripId: string): QueryResult<TripSeatMapQuery, TripSeatMapQueryVariables> =>
  useQueryErrorNotification<TripSeatMapQuery, TripSeatMapQueryVariables>(GET_TRIP_SEATMAP_QUERY, {
    variables: { tripId },
  });
