import { ReactNode } from 'react';
import { Card, Collapse, Typography } from 'antd';

import { MoneyText } from 'src/components/topography/MoneyText';

interface SummaryCardProps {
  icon?: ReactNode;
  description?: ReactNode;
  descriptionTitle?: ReactNode;
  title?: string;
  figure?: number;
  isMoney?: boolean;
  loading?: boolean;
}

export const SummaryCard = (props: SummaryCardProps) => {
  const { icon, title, figure, isMoney = false, loading, description, descriptionTitle } = props;

  return (
    <Card title={title} loading={loading}>
      <div className="flex space-between">
        <Typography.Text strong style={{ fontSize: 18 }}>
          <MoneyText value={figure ?? ''} showCurrency={isMoney} />
        </Typography.Text>
        {icon}
      </div>
      <div style={{ marginTop: 10 }}>
        <Collapse ghost>
          <Collapse.Panel header={descriptionTitle} key="1">
            <p>{description}</p>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Card>
  );
};
