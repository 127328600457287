import { useCallback, useState } from 'react';
import { useMessage } from 'src/providers/message-provider/MessageProvider';

import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';

export const useDeleteTripHandOver = (): [
  (id: number, handoverId: number) => Promise<boolean | undefined>,
  {
    data: boolean | undefined;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const { errorMessage } = useMessage();

  const deleteTripHandover = useCallback(
    async (id: number, handoverId: number) => {
      let result = false;
      setLoading(true);
      try {
        await api.post(`${resources.trip.getTripsUrl}/${id}/remove-handover`, {
          handoverId,
          noTransform: true,
        });
        setData(true);
        result = true;
      } catch (err: any) {
        setError(err);
        errorMessage({ content: 'Error deleting record.' });
      }
      setLoading(false);
      return result;
    },
    [setData, setError, setLoading],
  );

  return [deleteTripHandover, { data, loading, error }];
};
