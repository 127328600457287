import { useCallback, useState } from 'react';

export interface UseGetType<P = any> {
  params: P;
}

export type CallOptions<P = any> = Pick<UseGetType<P>, 'params'>;

export const useLazyGet = <T = any, P = any>(
  fn: (options?: CallOptions<P>) => Promise<T>,
): [
  (callOptions?: CallOptions<P>) => Promise<T | undefined> | T | undefined,
  { data: T | undefined; loading: boolean; error: any },
] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const memoizedFunc = useCallback((callOptions?: CallOptions<P>) => fn(callOptions), [fn]);

  const fetch = useCallback(
    async (callOptions?: CallOptions<P>) => {
      let result;
      setLoading(true);

      try {
        result = await memoizedFunc(callOptions);
        setData(result);
      } catch (err: any) {
        const message = err?.response?.data?.message;
        setError(message ?? err.message);
      }
      setLoading(false);
      return result;
    },
    [memoizedFunc],
  );

  return [fetch, { data, loading, error }];
};
