import { useGet } from 'src/hooks/shared/useGet';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';

export const useGetFilterTransactionStatus = () =>
  useGet<string[]>(async () => {
    const { data } = await api.get<string[]>(resources.transactionStatusFilterUrl);
    return camelcaseKeys(data, { deep: true });
  });
