import { Card, Col, Row, Typography, Descriptions, message, Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { WaybillService } from 'src/services/waybill.service';
import { IWaybill } from 'src/types/waybill.type';
import moment from 'moment';
import { usePage } from 'src/components/page/Page';
import { MoneyText } from 'src/components/topography/MoneyText';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { PrinterOutlined } from '@ant-design/icons';
import { PaymentMethodTag } from 'src/components/payment-method-tag/PaymentMethodTag';

export const WaybillDetails = () => {
  const { id } = useParams();
  const { setSubTitle, setTitle } = usePage();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IWaybill>();

  useEffect(() => {
    setTitle('Waybills');
    setSubTitle('Waybill information');
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        if (id && !Number.isNaN(id)) {
          setLoading(true);
          const result = await WaybillService.getWaybillById(parseInt(id, 10));
          setData(result);
        }
      } catch (error) {
        message.error('There was an error fetching data', 2.5);
      }
      setLoading(false);
    };

    getData();
  }, [id]);

  const dateTime = data?.trip ? moment(`${data?.trip?.date} ${data?.trip?.time}`).format('DD-MM-YYYY hh:mm A') : '';

  if (loading) return <LoadingView />;

  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <Card bordered={false} title={<Typography.Title level={5}>Waybill Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px', fontWeight: 'bold' }}>
              <Descriptions.Item label="Reference">{data?.reference}</Descriptions.Item>
              <Descriptions.Item label="Package type">{data?.type}</Descriptions.Item>
              <Descriptions.Item label="Departure Date">
                {moment(data?.date).format('ddd Do MMM, YYYY')}
              </Descriptions.Item>
              <Descriptions.Item label="Destination">{data?.destination?.name}</Descriptions.Item>
              <Descriptions.Item label="Trip">
                {data?.trip ? (
                  <Link to={`/trips/${data.trip.id}`}>{`${data?.destination?.name} | ${dateTime}`}</Link>
                ) : (
                  'N/A'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Weight">{data?.weight}</Descriptions.Item>
              <Descriptions.Item label="Quantity">{data?.quantity}</Descriptions.Item>
              <Descriptions.Item label="Description">{data?.description}</Descriptions.Item>
              <Descriptions.Item label="Value">
                <MoneyText value={data?.value ?? 0} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <Card bordered={false} title={<Typography.Title level={5}>Contact Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '150px', fontWeight: 'bold' }}>
              <Descriptions.Item label="Sender's name">{data?.sendersName}</Descriptions.Item>
              <Descriptions.Item label="Sender's phone">{data?.sendersPhone}</Descriptions.Item>
              <Descriptions.Item label="Recipient's name">{data?.recipientName}</Descriptions.Item>
              <Descriptions.Item label="Recipient's Phone">{data?.recipientPhone}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }} lg={{ span: 12 }}>
          <Card bordered={false} title={<Typography.Title level={5}>Payment Information</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '150px', fontWeight: 'bold' }}>
              <Descriptions.Item label="Payment method">
                {data?.transaction?.paymentMethod && <PaymentMethodTag method={data?.transaction?.paymentMethod} />}
              </Descriptions.Item>
              <Descriptions.Item label="Transaction status">{data?.status}</Descriptions.Item>
              <Descriptions.Item label="Total amount">
                <MoneyText value={data?.amount ?? 0} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={20}>
          <Button href={`/waybill/${data?.id}/print`} target="blank" block type="primary" icon={<PrinterOutlined />}>
            Print Waybill
          </Button>
          <Divider />
        </Col>
      </Row>
    </>
  );
};
