import { IBranch, IDestination } from 'src/types/branch.type';
import { IUser } from 'src/types/user.type';
import { ITrip } from 'src/types/trip.type';
import { ITransaction } from 'src/types/transaction.type';

export enum WAYBILL_STATUS {
  ON_HOLD = 'on_hold',
  ISSUED = 'authorised',
  CANCELLED = 'cancelled',
}

export interface IWaybill {
  id: number;
  reference: string;
  type: string;
  sendersName: string;
  sendersPhone: string;
  sendersEmail: string;
  recipientName: string;
  recipientPhone: string;
  date: Date;
  destinationId: number;
  destination?: IDestination;
  transaction?: ITransaction;
  branchId: number;
  branch?: IBranch;
  tripId: number;
  trip?: ITrip;
  ticketerId: number;
  ticketer?: IUser;
  organizationId: number;
  quantity: number;
  serviceCharge: number;
  weight: string;
  amount: number;
  description?: string;
  status?: string;
  paymentMethod?: string;
  createdAt: string;
  updatedAt: string;
  value?: number;
}
export interface IWaybillDeprecated {
  id?: number;
  type: string;
  senders_name: string;
  senders_phone: string;
  senders_email: string;
  recipient_name: string;
  recipient_phone: string;
  recipient_email: string;
  date: Date;
  destination_id: number;
  destination?: IDestination;
  ticketer_id: number;
  ticketer?: IUser;
  organization_id: number;
  quantity: number;
  weight: string;
  amount: number;
  description?: string;
  status?: string;
  payment_method?: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ICreateWayBillParams {
  type: string;
  sendersName: string;
  sendersPhone: string;
  sendersEmail: string;
  recipientName: string;
  recipientPhone: string;
  date: string;
  destinationId: number;
  branchId: number;
  quantity: number;
  weight: string;
  amount: number;
  description: string;
  paymentMethod?: string;
}
