import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  UpdateUserBranchesMutation,
  UpdateUserBranchesMutationVariables,
} from 'src/graphql/mutations/__generated__/update-user-branches.generated';

const UPDATE_USER_BRANCHES = gql`
  mutation UpdateUserBranches($input: UpdateUserBranchInput!, $userId: ID!) {
    updateUserBranches(input: $input, userId: $userId) {
      name
      id
    }
  }
`;

export const useUpdateUserBranches = (): MutationTuple<
  UpdateUserBranchesMutation,
  UpdateUserBranchesMutationVariables
> =>
  useMutationErrorNotification<UpdateUserBranchesMutation, UpdateUserBranchesMutationVariables>(UPDATE_USER_BRANCHES);
