import { Button, Modal, Form, Input, Row, Col, Select, Alert } from 'antd';
import React, { useState } from 'react';

import 'src/screens/users/user-modal/styles/createUserModal.style.less';
import { IUser, RoleNameMapping, USER_ROLE } from 'src/types/user.type';
import { useCreateUser, UseCreateUserParams } from 'src/hooks/useCreateUser';
import { useUpdateUser, UseUpdateUserParams } from 'src/hooks/useUpdateUser';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';

const { Option } = Select;

export interface UserModalProps {
  show: boolean;
  onClose: () => void;
  onComplete: (user: IUser) => void;
  defaultUser?: IUser;
}

export const UserModal: React.FC<UserModalProps> = ({ show, onClose, onComplete, defaultUser }) => {
  const [createUser, { loading: createLoading, error: createError }] = useCreateUser();
  const [updateUser, { loading: updateLoading, error: updateError }] = useUpdateUser();
  const { successMessage } = useMessage();
  const { user: authUser } = useAuth();
  const { allBranches } = useBranch();

  const [showDriverFields, setShowDriverFields] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleSave = async (values: UseCreateUserParams | UseUpdateUserParams) => {
    let user: IUser | null | undefined;
    if (defaultUser) {
      const params = { ...values, userId: defaultUser.id } as UseUpdateUserParams;
      user = await updateUser({ params });
    } else {
      const params = { ...values } as UseUpdateUserParams;
      user = await createUser({ params });
    }

    if (onComplete && user) {
      successMessage({ content: `User successfully ${defaultUser ? 'updated' : 'created'}`, duration: 2.3 });
      onComplete(user);
    }
  };

  const onFormSubmit = async () => {
    const values = await form.validateFields();
    if (values) {
      handleSave(values);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const checkDriverRole = (role: USER_ROLE) => {
    setShowDriverFields(role === USER_ROLE.FleetManagement);
  };

  const isLoading = createLoading || updateLoading;
  const error = createError ?? updateError;

  const isRoleDisabled = (role: string) => {
    if (authUser?.roles?.includes(USER_ROLE.Manager) && !authUser.roles.includes(USER_ROLE.Admin)) {
      return role === USER_ROLE.Admin.toString();
    }
    return false;
  };

  return (
    <Modal
      title={defaultUser ? 'Edit User' : 'Add User'}
      open={show}
      closable={!isLoading}
      maskClosable={isLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onFormSubmit} loading={isLoading}>
          Save
        </Button>,
      ]}
    >
      {error && <Alert message={error} type="error" className="error-alert" />}

      <Form layout="vertical" autoComplete="on" form={form} initialValues={defaultUser}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[{ required: true, message: 'Enter your First Name' }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Last name" name="lastName" rules={[{ required: true, message: 'Enter your Last Name' }]}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="email"
              name="email"
              rules={[{ required: true, message: 'Enter your email', type: 'email' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Phone number"
              name="phone"
              rules={[{ required: true, message: 'Enter your mobile number' }]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
        </Row>
        {!defaultUser && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                label="Branches"
                name="branchIds"
                rules={[{ required: true, message: 'Select branch that apply to user' }]}
              >
                <Select placeholder="Select branch" mode="multiple">
                  {allBranches?.getTerminals.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item
              label="User roles"
              name="roles"
              rules={[{ required: true, message: 'Select roles that apply to user' }]}
            >
              <Select placeholder="Select role" onSelect={checkDriverRole} mode="multiple">
                {Object.entries(RoleNameMapping).map(([role, roleName]) => (
                  <Option key={role} value={role} disabled={isRoleDisabled(role)}>
                    {roleName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* drivers license and password are not editable */}
          {!defaultUser && (
            <>
              {showDriverFields && (
                <Col span={12}>
                  <Form.Item label="Drivers License" name="driversLicenseNumber">
                    <Input placeholder="Drivers license" />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Enter your Password' }]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
