import { useCallback, useState } from 'react';

export interface UseUpdateType<P = any> {
  params?: P;
}

export type UpdateCallOptions<P = any> = Pick<UseUpdateType<P>, 'params'>;

type UseUpdate<T = any, P = any> = [
  (callOptions?: UpdateCallOptions<P>) => Promise<T | undefined> | T | undefined,
  { data: T | undefined; loading: boolean; error: any },
];

type UseUpdateArgs<T = any, P = any> = {
  fn: (options?: UpdateCallOptions<P>) => Promise<T>;
};

export const useUpdate = <T = any, P = any>(fn: UseUpdateArgs<T, P>['fn']): UseUpdate<T, P> => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const memoizedFunc = useCallback((callOptions?: UpdateCallOptions<P>) => fn(callOptions), [fn]);

  const fetch = useCallback(
    async (callOptions?: UpdateCallOptions<P>) => {
      let result;
      setLoading(true);

      try {
        result = await memoizedFunc(callOptions);
        setData(result);
      } catch (err: any) {
        const message = err?.response?.data?.message;
        setError(message ?? err.message);
      }
      setLoading(false);
      return result;
    },
    [memoizedFunc],
  );

  return [fetch, { data, loading, error }];
};
