import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CreateTripMutation,
  CreateTripMutationVariables,
} from 'src/graphql/mutations/__generated__/create-trip.generated';

export type ICreateTrip = CreateTripMutation['createTrip'];

const CREATE_TRIP_MUTATION = gql`
  mutation CreateTrip($input: CreateTripInput!) {
    createTrip(input: $input) {
      id
      organizationId
      branchId
      destinationId
      routeGroupId
      date
      time
      maximumCapacity
      vehicleClassId
      isCheckinAvailable
      expectedCheckinTime
      closedAt
      createdAt
      updatedAt
    }
  }
`;

export const useCreateTripMutation = (): MutationTuple<CreateTripMutation, CreateTripMutationVariables> =>
  useMutationErrorNotification<CreateTripMutation, CreateTripMutationVariables>(CREATE_TRIP_MUTATION);
