import { useCallback, useState } from 'react';
import { TravelService } from 'src/services/travel.service';
import { INewTravelScheduleParams, ITravelSchedule } from 'src/types/travel.type';

export const usePostUpdateTravelSchedule = (): [
  (id: number, params: INewTravelScheduleParams) => Promise<ITravelSchedule | null>,
  {
    data: ITravelSchedule | null;
    loading: boolean;
    error: any;
  },
] => {
  const [error, setError] = useState();
  const [data, setData] = useState<ITravelSchedule | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const createTravelSchedule = useCallback(
    async (id: number, params: INewTravelScheduleParams) => {
      let result = null;
      setLoading(true);
      try {
        result = await TravelService.updateTravelSchedule(id, params);
        setData(result);
      } catch (err: any) {
        setError(err);
      }
      setLoading(false);
      return result;
    },
    [setData, setError, setLoading],
  );

  return [createTravelSchedule, { data, loading, error }];
};
