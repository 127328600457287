import { IExpense } from 'src/types/expense.type';
import { api } from 'src/services/api';
import { resources } from 'src/services/api/resources';
import camelcaseKeys from 'camelcase-keys';
import { useUpdate } from 'src/hooks/shared/useUpdate';

export interface UseCreateExpenseParams {
  name: string;
  description?: string;
  amount: number;
  branchId: number;
  tripId?: number;
  paymentMethod?: string;
}

export const usePostCreateExpense = () =>
  useUpdate<IExpense[], UseCreateExpenseParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IExpense[]>(resources.expensesUrl, payload);
    return camelcaseKeys(data, { deep: true });
  });
