import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Card, Table } from 'antd';

import 'src/screens/dashboard/styles/dashboard.styles.less';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITransaction } from 'src/types/transaction.type';
import { useGetTransactions } from 'src/hooks/useGetTransactions';

export const TABLE_CARD_TEST_ID = 'TABLE_CARD_TEST_ID';

const columns: ColumnsType<ITransaction> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, _, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (charge) => <span>{charge}</span>,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'created_at',
    render: (date) => <span>{moment(date).format('ddd, Do MMM YYYY')}</span>,
  },
  {
    title: 'Transaction type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Payment method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Processed by',
    dataIndex: 'ticketer',
    key: 'ticketer',
    render: (ticketer) => <span>{`${ticketer.firstName} ${ticketer.lastName}`}</span>,
  },
];

export const RecentTransactions = () => {
  const { activeBranch } = useBranch();

  const [{ data: transactionData, loading }] = useGetTransactions({ params: { branchId: activeBranch?.id! } });

  const today = moment().startOf('day');
  const currentTime = moment.now();

  const filteredDates = transactionData?.filter((transaction) => {
    const currentDate = moment(transaction.createdAt);
    return currentDate.isBetween(today, currentTime, null, '[]');
  });

  const navigate = useNavigate();

  const gotoTransactionsPage = () => {
    navigate('/transactions');
  };

  return (
    <Card
      data-testid={TABLE_CARD_TEST_ID}
      title="Recent Transactions"
      extra={
        <button type="button" className="show-transactions" onClick={() => gotoTransactionsPage()}>
          Show all
        </button>
      }
      className="table"
    >
      <Table
        rowKey={(transactions: ITransaction) => transactions.id}
        columns={columns}
        dataSource={filteredDates}
        loading={loading}
        pagination={false}
        scroll={{ x: 1000 }}
      />
    </Card>
  );
};
